import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  authLoading: false,
  user: {},
  userList: [],
  isLoggedIn: false,
  userDetails: {},
  organization: {},
  activeUser: {},
  selectedActive: false,
  permissions: [],
  resentOtp: {},
  resetUser: {},
  disability: {},
};

export const createUserDetails = createAsyncThunk(
  "authSlice/createUserDetails",
  async (data) => {
    const res = await axios.post(`${url}/nea/saveUser.action`, data);
    return res.data.jsonData;
  }
);

export const resendSignupOtp = createAsyncThunk(
  "authSlice/resendOtp",
  async ({ email, phoneNumber }) => {
    const res = await axios.get(
      `${url}/nea/resend_otp.action?email=${email}&phoneNumber=${phoneNumber}`
    );
    return res.data.jsonData;
  }
);

export const verifySignupOtp = createAsyncThunk(
  "authSlice/verifySignupOtp",
  async (data) => {
    const res = await axios.post(`${url}/nea/verify_code.action`, data);
    return res.data.jsonData;
  }
);

export const login = createAsyncThunk("authSlice/login", async (data) => {
  const res = await axios.post(
    `${url}/usr/post_authenticate_user.action`,
    data
  );
  return res.data;
});

export const resetPassword = createAsyncThunk(
  "authSlice/resetPassword",
  async (data) => {
    const res = await axios.get(
      `${url}/nea/reset_password.action?nationalId=${data?.nationalId}`
    );
    return res.data?.jsonData;
  }
);

export const updateUserPassword = createAsyncThunk(
  "authSlice/updateUserPassword",
  async (data) => {
    const res = await axios.post(`${url}/nea/updateUserPassword.action`, data);
    return res.data;
  }
);
export const fetchNeaOffices = createAsyncThunk(
  "authSlice/fetchNeaOffices",
  async (neaObj) => {
    const res = await axios.get(
      `${url}/nea/fetchInstitutions.action?instType=NEA_BRANCH&instNearNeaOffice=`
    );
    return res.data.data.result;
  }
);

export const fetchUsersAdmin = createAsyncThunk(
  "authSlice/fetchUsersAdmin",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchUserAdmin.action?usrId=${usrObj?.usrId}`
    );
    return res.data;
  }
);

export const fetchUsers = createAsyncThunk(
  "authSlice/fetchUsers",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchUser.action?usrId=${usrObj?.usrId}`
    );
    return res.data;
  }
);

export const createOtherUser = createAsyncThunk(
  "authSlice/createOtherUser",
  async (data) => {
    const res = await axios.post(`${url}/nea/create_other_users.action`, data);
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "authSlice/updateUser",
  async (data) => {
    const res = await axios.post(`${url}/nea/saveUser.action`, data);
    return res.data;
  }
);

export const fetchUserPermissions = createAsyncThunk(
  "authSlice/fetchUserPermissions",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/user/fetchAssignedRoles.action?userId=${usrObj?.usrId}&node=app_${usrObj?.app}`
    );
    return res.data;
  }
);

export const updateUserPermissions = createAsyncThunk(
  "authSlice/updateUserPermissions",
  async (data) => {
    const res = await axios.get(
      `${url}/user/assignRole.action?rolesId=${data?.rolesId}&userId=${data?.userId}&nodeValue=${data?.nodeValue}`
    );
    return res.data;
  }
);

export const verifyTransOtp = createAsyncThunk(
  "authSlice/verifyTransOtp",
  async (data) => {
    const res = await axios.post(`${url}/nea/verify_code.action`, data);
    return res.data;
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "authSlice/fetchCurrentUser",
  async () => {
    const res = await axios.get(`${url}/nea/fetchCurrentUser.action`);
    return res.data;
  }
);

export const fetchUserDisabilitys = createAsyncThunk(
  "authSlice/fetchUserDisabilitys",
  async (data) => {
    const res = await axios.get(
      `${url}/nea/fetchUserDisabilitys.action?disUsrId=${data?.disUsrId}`
    );
    return res.data;
  }
);

export const saveUserDisability = createAsyncThunk(
  "authSlice/saveUserDisability",
  async (data) => {
    const res = await axios.post(`${url}/nea/saveUserDisability.action`, data);
    return res.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.isLoggedIn = false;
      state.userList = [];
      state.userDetails = {};
      state.organization = {};
      state.activeUser = {};
      state.selectedActive = false;
      state.permissions = [];
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
    },
    setSelectedActive: (state) => {
      state.selectedActive = true;
    },
    closeSelectedActive: (state) => {
      state.selectedActive = false;
      state.activeUser = {};
    },
    handleLoginSession: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(login.pending, (state) => {
        state.authLoading = true;
      })

      .addCase(login.fulfilled, (state, action) => {
        state.authLoading = false;
        state.user = action?.payload;
        if (action?.payload?.success) {
          state.user = action.payload?.jsonData;
        } else {
          state.user = {};
        }
      })

      .addCase(login.rejected, (state) => {
        state.authLoading = false;
        state.user = {};
        state.isLoggedIn = false;
        // message.error('Invalid email or password');
      })

      .addCase(resetPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.authLoading = false;
        state.resetUser = action.payload;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(updateUserPassword.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(updateUserPassword.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(fetchNeaOffices.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchNeaOffices.fulfilled, (state, action) => {
        state.authLoading = false;
        state.neaOffices = action.payload;
      })
      .addCase(fetchNeaOffices.rejected, (state) => {
        state.authLoading = false;
        state.neaOffices = {};
      })

      .addCase(createUserDetails.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(createUserDetails.fulfilled, (state, action) => {
        state.authLoading = false;
        state.userDetails = action.payload;
      })
      .addCase(createUserDetails.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(resendSignupOtp.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(resendSignupOtp.fulfilled, (state, action) => {
        state.authLoading = false;
        // state.userDetails = action.payload
      })
      .addCase(resendSignupOtp.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(verifySignupOtp.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(verifySignupOtp.fulfilled, (state, action) => {
        state.authLoading = false;
      })
      .addCase(verifySignupOtp.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(createOtherUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(createOtherUser.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(createOtherUser.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(fetchUsers.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.authLoading = false;
        state.userList = action?.payload?.data?.result;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(fetchUsersAdmin.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchUsersAdmin.fulfilled, (state, action) => {
        state.authLoading = false;
        state.userList = action?.payload?.data?.result;
      })
      .addCase(fetchUsersAdmin.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(updateUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.authLoading = false;
        if (action?.payload?.success) {
          state.user = action?.payload?.jsonData;
        }
      })
      .addCase(updateUser.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(fetchUserPermissions.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.authLoading = false;
        state.permissions = action?.payload;
      })
      .addCase(fetchUserPermissions.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(updateUserPermissions.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(updateUserPermissions.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(updateUserPermissions.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(verifyTransOtp.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(verifyTransOtp.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(verifyTransOtp.rejected, (state) => {
        state.authLoading = false;
      })

      .addCase(fetchCurrentUser.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.authLoading = false;
        if (action?.payload?.success) {
          state.user = action?.payload?.jsonData;
          state.isLoggedIn = true;
        } else {
          state.user = [];
          state.isLoggedIn = false;
        }
      })
      .addCase(fetchCurrentUser.rejected, (state) => {
        state.authLoading = true;
        state.user = [];
        state.isLoggedIn = false;
      })

      .addCase(fetchUserDisabilitys.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(fetchUserDisabilitys.fulfilled, (state, action) => {
        state.authLoading = false;
        if (action?.payload?.success) {
          state.disability = action?.payload?.data?.result[0];
        } else {
          state.disability = {};
        }
      })
      .addCase(fetchUserDisabilitys.rejected, (state) => {
        state.authLoading = true;
      })

      .addCase(saveUserDisability.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(saveUserDisability.fulfilled, (state) => {
        state.authLoading = false;
      })
      .addCase(saveUserDisability.rejected, (state) => {
        state.authLoading = false;
      });
  },
});

export default authSlice.reducer;
export const {
  logout,
  setActiveUser,
  setSelectedActive,
  closeSelectedActive,
  handleLoginSession,
} = authSlice.actions;
