import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchJobs } from '../../features/job/jobSlice';

export default function JobFilter() {
    const dispatch = useDispatch()

    const [recommended, setrecommended] = useState('')
    const [location, setlocation] = useState('')
    const [category, setcategory] = useState('')
    const [expLevel, setexpLevel] = useState('')
    const [type, settype] = useState('')
    const [style, setstyle] = useState('')

    const filterObj = {
        jobRecommendation: recommended,
        jobCountry: location,
        jobCategory: category,
        jobExperienceLevel: expLevel,
        jobType: type,
        jobWorkStyle: style,
        jobName: '',
        jobOccupation: '',
    }

    async function handleFetchJobs(){
        await dispatch(fetchJobs(filterObj))
    }

    const onStyleChange = async (e) => {
		await setstyle(e.target.value);
	};

    const ontypeChange = async (e) => {
		await settype(e.target.value);
	};

    const onexpLevelChange = async (e) => {
		await setexpLevel(e.target.value);
	};

    const onCategoryChange = async (e) => {
		await setcategory(e.target.value);
	};
    
	const onLocationChange = async (e) => {
		await setlocation(e.target.value);
	};

    useEffect(() => {
        handleFetchJobs()
    }, [recommended, location, category, expLevel, type, style])

	return (
		<>
			<div className='flex flex-col my-5'>
				<Radio.Group className='flex flex-col' onChange={onLocationChange} value={recommended}>
					<Radio className='mb-2' value={'RECOMMENDED'}>Recommendations</Radio>
					<Radio className='mb-2' value={'REFERRAL'}>Referral</Radio>
				</Radio.Group>

                <span className='radio-head my-3'>Location</span>

                <Radio.Group className='flex flex-col' onChange={onLocationChange} value={location}>
					<Radio className='mb-2' value={''}>Any</Radio>
					<Radio className='mb-2' value={'KENYA'}>Domestic Jobs</Radio>
					<Radio className='mb-2' value={'INTERNATIONAL'}>International Jobs</Radio>
				</Radio.Group>

                <span className='radio-head my-3'>Category</span>

                <Radio.Group className='flex flex-col' onChange={onCategoryChange} value={category}>
					<Radio className='mb-2' value={''}>Any</Radio>
					<Radio className='mb-2' value={'Unskilled'}>Unskilled Jobs</Radio>
					<Radio className='mb-2' value={'Professional'}>Professional Jobs</Radio>
				</Radio.Group>

                <span className='radio-head my-3'>Experience level</span>

                <Radio.Group className='flex flex-col' onChange={onexpLevelChange} value={expLevel}>
					<Radio className='mb-2' value={''}>Any</Radio>
					<Radio className='mb-2' value={'Student/Graduate'}>Student/Recent graduate</Radio>
					<Radio className='mb-2' value={'Junior'}>Junior Level</Radio>
					<Radio className='mb-2' value={'Senior'}>Senior Level</Radio>
					<Radio className='mb-2' value={'Middle_Management'}>Middle Management</Radio>
					<Radio className='mb-2' value={'Executive_Management'}>Executive Management</Radio>
				</Radio.Group>

                <span className='radio-head my-3'>Job Type</span>

                <Radio.Group className='flex flex-col' onChange={ontypeChange} value={type}>
					<Radio className='mb-2' value={''}>Any</Radio>
					<Radio className='mb-2' value={'Internship'}>Internship</Radio>
					<Radio className='mb-2' value={'Casual'}>Casual</Radio>
					<Radio className='mb-2' value={'full-time'}>Full time</Radio>
					<Radio className='mb-2' value={'Contract'}>Contract</Radio>
					<Radio className='mb-2' value={'Volunteer'}>Volunteer</Radio>
				</Radio.Group>

                <span className='radio-head my-3'>Work Style</span>

                <Radio.Group className='flex flex-col' onChange={onStyleChange} value={style}>
					<Radio className='mb-2' value={''}>Any</Radio>
					<Radio className='mb-2' value={'On-Site'}>On-site</Radio>
					<Radio className='mb-2' value={'Hybrid'}>Hybrid</Radio>
					<Radio className='mb-2' value={'Remote'}>Remote</Radio>
					<Radio className='mb-2' value={'Foreign'}>Foreign</Radio>
				</Radio.Group>
			</div>
		</>
	);
}
