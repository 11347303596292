export default function InactiveDash() {

	return (
		<>
			<div className='w-full h-full'>
				<div className='w-full h-[230px] bg-darkBlue'></div>
				<div className='absolute flex items-center top-[20%] left-[35%] justify-center bg-[#EDF8FF] p-20 flex-col z-[1000]'>
					<h2 className='dash-welcome mb-5'>Welcome</h2>
					<p className='dash-welcome-text mb-7'>
						We are happy to have you on board
					</p>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='214'
						height='176'
						viewBox='0 0 214 176'
						fill='none'
					>
						<path
							d='M176.698 2.82879C148.192 3.27774 30.7446 3.52192 1.36793 3.14853C-0.376407 3.12663 -0.508902 0.1537 1.29675 0.1537C31.579 0.19093 136.262 -0.386136 176.641 0.482199C178.585 0.521619 178.057 2.80689 176.698 2.82879Z'
							fill='#434A54'
						/>
						<path
							d='M141.719 88.206C139.752 89.055 137.663 89.5847 135.529 89.7751C135.734 92.2827 135.707 94.8039 135.448 97.3065C135.42 97.4416 135.345 97.5623 135.236 97.6474C135.128 97.7325 134.992 97.7764 134.854 97.7715C134.717 97.7665 134.585 97.7129 134.483 97.6202C134.381 97.5275 134.314 97.4017 134.296 97.2649C134.091 95.5534 134.57 93.6996 134.225 89.8594C131.664 89.9284 130.464 89.1345 128.213 88.829C122.618 88.0649 117.239 86.1564 112.414 83.2226C112.27 83.1128 112.176 82.9508 112.151 82.7719C112.126 82.5929 112.173 82.4114 112.281 82.2667C117.232 76.2202 121.04 69.3217 123.517 61.9095C123.809 61.0719 124.778 59.9462 124.783 61.3883C124.795 65.0851 124.363 68.7796 124.363 72.4818C124.363 79.2007 124.542 86.3883 131.088 88.0768C135.102 89.1093 138.838 87.7997 141.323 87.1362C141.913 86.973 142.351 87.9005 141.719 88.206Z'
							fill='#434A54'
						/>
						<path
							d='M132.241 73.8336C131.668 74.9451 133.623 74.8137 134.796 74.8695C134.944 74.8819 135.083 74.9462 135.188 75.0512C135.293 75.1562 135.358 75.2951 135.37 75.4431C135.383 75.5912 135.342 75.7388 135.256 75.8599C135.17 75.981 135.044 76.0677 134.9 76.1047C134.125 76.209 133.336 76.1644 132.578 75.9733C131.222 75.6918 130.187 75.1925 130.925 73.6738C131.402 72.6486 131.944 71.6549 132.548 70.6986C132.622 70.5828 132.736 70.4979 132.868 70.4598C133 70.4216 133.141 70.4328 133.266 70.4912C133.39 70.5497 133.489 70.6514 133.544 70.7774C133.599 70.9034 133.606 71.0451 133.564 71.1761C133.191 72.0941 132.749 72.9825 132.241 73.8336Z'
							fill='#7E93AE'
						/>
						<path
							d='M139.671 69.5536C139.684 70.2566 139.737 71.322 138.701 71.3494C137.867 71.3724 137.632 70.6344 137.637 69.6478C137.641 67.8706 139.629 67.3757 139.671 69.5536Z'
							fill='#7E93AE'
						/>
						<path
							d='M129.904 69.5073C129.887 70.1796 129.601 70.8136 128.825 70.7819C128.177 70.7556 127.943 69.8796 127.962 69.3025C127.989 68.4506 128.258 68.0159 128.796 67.8976C129.623 67.7158 129.927 68.6641 129.904 69.5073Z'
							fill='#7E93AE'
						/>
						<path
							d='M129.152 64.4894C128.719 64.4393 128.279 64.5114 127.884 64.6975C127.489 64.8836 127.154 65.1764 126.916 65.5428C126.316 66.4297 125.675 65.7016 125.903 65.176C126.195 64.5369 126.685 64.0085 127.299 63.6683C127.914 63.3282 128.622 63.1942 129.319 63.286C129.475 63.3125 129.614 63.3985 129.707 63.5259C129.8 63.6533 129.841 63.8119 129.819 63.9684C129.797 64.1248 129.716 64.2666 129.591 64.3639C129.467 64.4611 129.309 64.5061 129.152 64.4894Z'
							fill='#7E93AE'
						/>
						<path
							d='M138.072 64.3428C137.936 64.3419 137.804 64.2924 137.7 64.2032C137.596 64.1139 137.528 63.9907 137.507 63.8556C137.486 63.7204 137.514 63.5822 137.585 63.4656C137.657 63.3491 137.767 63.2618 137.897 63.2193C138.733 63.0401 139.605 63.1693 140.353 63.5831C141.101 63.9969 141.674 64.6674 141.966 65.4706C142.147 66.0981 141.309 66.6127 140.992 65.9086C140.74 65.367 140.319 64.9217 139.793 64.6394C139.267 64.357 138.663 64.253 138.072 64.3428Z'
							fill='#7E93AE'
						/>
						<path
							d='M132.856 79.7247C133.326 80.7851 134.162 81.6413 135.211 82.1378C136.259 82.6343 137.451 82.7382 138.569 82.4305C139.723 82.0582 140.692 81.262 141.282 80.2027C141.871 79.1434 142.037 77.8999 141.745 76.7233C141.611 76.1758 141.121 75.9853 140.532 76.4496C138.067 78.3921 136.243 78.6954 133.33 78.7436C132.857 78.7513 132.58 79.175 132.856 79.7247Z'
							fill='#7E93AE'
						/>
						<path
							d='M53.2038 100.813C53.4644 101.307 52.7351 101.837 52.3278 101.47C49.9703 99.3358 45.6538 96.3235 44.4361 93.4765C42.7936 89.644 43.6904 84.8446 40.4405 81.7436C40.3233 81.6433 40.2492 81.5019 40.2336 81.3485C40.2179 81.1951 40.2619 81.0416 40.3564 80.9198C40.4509 80.7979 40.5887 80.7172 40.7411 80.6942C40.8936 80.6712 41.049 80.7078 41.1752 80.7964C44.7668 83.3225 44.0857 88.2687 45.749 91.8559C47.4353 95.488 51.3554 97.3188 53.2038 100.813Z'
							fill='#3B92C7'
						/>
						<path
							d='M29.1828 95.2134C25.8325 94.5489 22.6988 93.0652 20.0615 90.8947C19.215 90.149 20.229 89.5478 20.8466 89.965C23.4695 91.8182 26.4713 93.0655 29.6351 93.6169C32.723 94.0737 35.8668 93.9929 38.9272 93.3781C39.6817 93.2774 39.6653 94.3812 39.1462 94.6921C36.7854 96.1102 31.8327 95.797 29.1828 95.2134Z'
							fill='#3B92C7'
						/>
						<path
							d='M15.2604 93.5082C14.7917 92.9946 15.5801 92.3125 16.0948 92.6848C19.6089 95.3811 23.6438 97.3199 27.9448 98.3788C31.3043 99.1453 30.3725 100.718 27.3568 100.096C22.7363 99.2153 18.5067 96.912 15.2604 93.5082Z'
							fill='#3B92C7'
						/>
						<path
							d='M28.3114 105.51C24.3201 105.279 18.7926 103.439 15.7967 100.813C15.6913 100.717 15.6237 100.587 15.6061 100.445C15.5885 100.304 15.6221 100.161 15.7007 100.042C15.7793 99.9227 15.8979 99.8358 16.0349 99.7965C16.172 99.7573 16.3185 99.7683 16.4482 99.8276C21.0023 102.059 23.431 103.193 28.5206 103.824C31.4749 104.192 31.3884 105.688 28.3114 105.51Z'
							fill='#3B92C7'
						/>
						<path
							d='M45.9547 110.616C46.7574 111.164 45.8452 112.512 45.0273 112.028C40.7108 109.486 35.7953 111.042 31.1065 111.034C26.5347 111.112 22.056 109.738 18.3137 107.111C17.7738 106.707 18.3684 105.899 18.9389 106.16C23.2237 108.324 27.983 109.379 32.7808 109.227C37.2123 108.958 42.0149 107.933 45.9547 110.616Z'
							fill='#3B92C7'
						/>
						<path
							d='M212.073 175.365C168.245 175.898 43.458 174.8 16.8035 174.489C15.0865 174.469 13.7243 172.531 16.7542 172.518C51.0277 172.342 161.032 172.383 212.009 172.853C214.016 172.866 214.022 175.341 212.073 175.365Z'
							fill='#83B3E0'
						/>
						<path
							d='M127.621 96.1406C117.985 97.6736 99.5276 107.976 89.4766 121.269C99.0031 119.827 127.101 119.58 135.175 120.527C132.137 116.828 128.773 113.409 125.123 110.311C126.525 108.705 127.843 107.455 128.996 106.356C126.689 104.932 122.896 102.661 121.222 101.88C123.118 99.7182 125.267 97.7915 127.621 96.1406Z'
							fill='#83B3E0'
						/>
						<path
							d='M148.691 106.847C149.927 108.078 150.981 109.479 151.821 111.008C148.443 112.943 141.892 116.569 137.938 120.771C141.713 121.265 144.179 123.097 145.493 128.655C146.058 131.038 146.838 134.863 147.611 139.157C154.764 137.593 162.037 136.632 169.351 136.282C168.068 132.996 166.352 129.895 164.249 127.062C163.798 126.466 164.635 125.708 165.133 126.287C167.672 129.202 169.638 132.571 170.924 136.217C174.201 136.023 177.487 136.07 180.756 136.356C181.501 136.44 181.51 137.554 180.739 137.535C169.697 137.175 158.654 138.216 147.874 140.632C148.974 146.908 150.01 153.922 150.335 158.833C167.308 158.667 194.842 167.054 199.11 156.937C208.667 134.281 179.641 98.5212 158.988 95.3906C160.731 97.8036 161.952 100.554 162.572 103.465C160.309 104.25 151.659 106.31 148.691 106.847Z'
							fill='#83B3E0'
						/>
						<path
							d='M135.985 113.274C132.514 104.611 132.608 100 131.618 95.7535C131.595 95.607 131.517 95.4749 131.4 95.3844C131.282 95.2939 131.135 95.2519 130.987 95.2673C130.84 95.2826 130.704 95.354 130.607 95.4667C130.511 95.5794 130.462 95.7248 130.47 95.8729C130.435 100.999 133.046 109.536 135.167 114.445C135.234 114.564 135.329 114.665 135.442 114.741C135.556 114.817 135.686 114.864 135.821 114.88C135.957 114.896 136.094 114.88 136.222 114.833C136.351 114.786 136.466 114.709 136.559 114.609C143.428 105.071 146.86 101.625 154.736 95.5072C154.858 95.4204 154.943 95.2896 154.971 95.1421C154.999 94.9946 154.969 94.842 154.887 94.7163C154.804 94.5905 154.677 94.5015 154.531 94.4679C154.384 94.4342 154.231 94.4586 154.102 94.5359C146.792 99.4142 140.614 105.804 135.985 113.274Z'
							fill='#83B3E0'
						/>
						<path
							d='M80.1386 122.259C73.3824 118.139 61.9298 108.005 57.2345 101.594C52.5159 106.407 48.3181 111.704 44.7109 117.398C53.2585 130.004 66.7949 143.602 75.2779 147.553C74.2924 143.35 74.0416 131.734 74.2924 128.026C74.5431 124.318 77.3376 122.836 80.1386 122.259Z'
							fill='#83B3E0'
						/>
						<path
							d='M41.4263 122.065C43.5221 121.978 42.4446 120.508 41.5511 120.491C29.6156 120.256 16.8238 119.965 7.8251 120.105C8.52042 68.1069 8.10432 35.2974 8.38902 5.99849C8.39669 5.22214 7.2327 5.34149 7.19766 6.08062C6.06105 29.9002 6.75966 94.0452 5.88366 119.928C5.853 120.815 5.91432 121.381 7.83276 121.466C13.2059 121.707 18.4422 121.717 23.846 121.81C28.8119 121.897 36.6137 122.267 41.4263 122.065Z'
							fill='#434A54'
						/>
						<path
							d='M172.19 70.4565C172.716 59.7802 172.552 48.9627 172.6 38.2635C172.629 31.9387 172.916 14.9575 172.845 6.62343C172.832 5.18022 171.344 6.00256 171.329 6.73293C171.041 20.8836 170.295 56.2171 170.836 70.4718C170.882 71.6719 172.136 71.6544 172.19 70.4565Z'
							fill='#434A54'
						/>
						<path
							d='M25.126 37.8218C25.126 34.3507 25.1556 30.8817 25.126 27.4105C40.7604 27.3207 56.4047 27.2321 72.0369 26.9495C72.05 29.9871 72.0369 33.0246 72.0369 36.0621C57.1044 36.4443 41.8346 37.0071 26.8572 37.2754C25.8925 37.2929 26.0491 38.7339 26.982 38.746C42.1051 38.9288 57.6924 37.9642 72.8625 37.571C73.0632 37.5502 73.2481 37.4525 73.3784 37.2984C73.5086 37.1443 73.5741 36.9457 73.5611 36.7443C73.5611 33.1976 73.5491 29.6498 73.5732 26.1031C73.5518 25.9009 73.453 25.7147 73.2975 25.5836C73.142 25.4526 72.9417 25.3868 72.7388 25.4001C56.5865 25.41 40.4232 25.6301 24.2708 25.8458C24.0643 25.8672 23.8741 25.9677 23.7401 26.1264C23.6062 26.285 23.5389 26.4894 23.5525 26.6966C23.6423 30.4415 23.8328 34.1776 23.962 37.9204C23.9806 38.6814 25.1249 38.5741 25.126 37.8218Z'
							fill='#57A863'
						/>
						<path
							d='M24.2134 62.8502C40.542 62.2129 57.3536 62.1385 73.5826 61.0205C73.7014 61.0232 73.8193 60.9995 73.9278 60.951C74.0363 60.9024 74.1326 60.8304 74.2098 60.74C74.2869 60.6496 74.343 60.5432 74.3739 60.4284C74.4048 60.3137 74.4098 60.1935 74.3885 60.0766C74.1016 57.7617 73.8136 55.4469 73.5125 53.1343C73.3866 52.174 71.9455 52.5025 72.0507 53.4453C72.2697 55.4546 72.5127 57.4628 72.7515 59.4678C56.898 60.1368 40.9045 60.589 25.119 61.0949C25.119 57.9556 25.1025 54.8118 25.1912 51.6779C41.1509 51.5126 56.8367 51.2542 72.9715 51.3856C73.1272 51.3882 73.2777 51.3302 73.3914 51.2238C73.5051 51.1175 73.573 50.9712 73.5808 50.8157C73.5887 50.6603 73.5358 50.5078 73.4333 50.3906C73.3309 50.2735 73.1869 50.2007 73.0318 50.1876C56.8345 49.4419 40.5694 49.7704 24.3043 49.8033C24.1782 49.7965 24.052 49.8147 23.933 49.8569C23.8141 49.8991 23.7046 49.9644 23.6109 50.0491C23.5173 50.1338 23.4413 50.2362 23.3874 50.3503C23.3335 50.4645 23.3028 50.5882 23.2969 50.7143C23.4316 54.4516 23.3812 58.2019 23.3943 61.9436C23.3859 62.1716 23.4667 62.3939 23.6197 62.5633C23.7727 62.7326 23.9857 62.8355 24.2134 62.8502Z'
							fill='#ED5565'
						/>
						<path
							d='M72.2419 84.7802C64.4017 84.8842 54.4273 84.7287 46.3846 85.1087C45.7111 85.1404 45.7418 86.5136 46.4021 86.5245C53.6433 86.6482 64.6021 86.2902 72.9734 86.4665C73.8986 86.4862 73.9413 85.5905 73.8329 84.9247C73.2924 81.6568 73.2632 78.3247 73.7464 75.0478C73.7687 74.9296 73.7643 74.808 73.7334 74.6918C73.7025 74.5755 73.6459 74.4677 73.5679 74.3763C73.4898 74.2848 73.3923 74.2119 73.2824 74.1631C73.1725 74.1143 73.053 74.0907 72.9328 74.0941C57.0028 74.4708 41.0683 75.0599 25.1394 75.315C24.9252 75.3296 24.7251 75.4266 24.5809 75.5857C24.4368 75.7447 24.3599 75.9534 24.3663 76.168C24.3378 79.6151 24.3444 83.0665 24.3203 86.5092C24.3352 86.7359 24.4377 86.948 24.6061 87.1006C24.7744 87.2532 24.9955 87.3345 25.2226 87.3271C29.8216 87.1585 33.4428 87.1837 38.0429 86.9001C38.8094 86.853 38.7447 85.47 37.9848 85.4678C34.0016 85.4585 30.0184 85.5001 26.0362 85.5927C26.0198 82.7008 26.0165 79.8089 25.9968 76.917C41.3508 76.3695 56.717 76.1286 72.0744 75.6993C71.7151 78.7197 71.7714 81.7751 72.2419 84.7802Z'
							fill='#434A54'
						/>
						<path
							d='M109.466 94.9897C110.191 95.1617 110.388 93.9977 109.685 93.7918C102.017 91.3488 95.5849 86.0446 91.7266 78.9819C82.8232 63.0804 88.3321 42.2414 102.797 31.6703C115.609 22.3069 143.13 22.4975 152.004 37.8023C152.541 38.7276 153.935 38.2217 153.488 37.2373C146.55 21.9456 118.391 19.7556 104.582 28.1871C88.3223 38.1187 80.781 59.5983 88.8358 77.2026C92.8873 86.0589 100.412 92.8457 109.466 94.9897Z'
							fill='#434A54'
						/>
						<path
							d='M172.059 98.1923C172.338 95.8315 172.09 89.5418 172.1 85.8615C172.1 84.5409 170.86 85.026 170.816 85.579C170.552 89.7797 170.541 93.9924 170.783 98.1945C170.832 98.8219 171.987 98.812 172.059 98.1923Z'
							fill='#434A54'
						/>
						<path
							d='M175.986 162.96C169.45 162.193 155.896 161.353 147.094 161.208C147.094 156.154 142.261 127.263 139.185 124.846C136.24 122.531 80.3597 122.1 78.0526 126.489C75.0194 132.266 80.0323 168.057 82.8005 169.002C87.3042 170.535 172.907 170.28 176.642 169.988C178.594 169.84 178.872 163.296 175.986 162.96ZM95.3021 128.293C92.0565 128.633 88.9511 129.082 85.7143 129.556C82.9122 129.966 82.714 130.553 82.4096 133.243C82.0237 136.436 81.8387 139.65 81.8555 142.867C81.8555 143.551 80.8601 143.731 80.7693 143.01C80.3503 139.319 80.3782 135.591 80.8525 131.907C81.1656 129.489 82.057 128.403 84.466 127.911C88.0279 127.192 91.6639 126.906 95.2944 127.061C95.4418 127.083 95.5767 127.157 95.6745 127.269C95.7723 127.381 95.8265 127.525 95.8275 127.674C95.8284 127.823 95.7759 127.967 95.6795 128.081C95.5831 128.195 95.4492 128.27 95.3021 128.293ZM109.609 152.744C107.293 153.182 104.785 151.929 103.881 148.396C103.096 145.33 104.538 143.017 107.085 142.365C113.185 140.806 115.413 151.634 109.609 152.744Z'
							fill='#434A54'
						/>
						<path
							d='M172.921 79.6363C162.508 69.3181 168.715 54.8521 162.055 47.8518C160.796 46.4941 159.095 45.6278 157.256 45.4072C155.418 45.1866 153.56 45.626 152.015 46.6473C146.885 38.3045 136.422 36.2853 127.513 39.0611C117.803 42.0866 114.324 53.94 122.119 57.7549C126.203 59.7544 140.542 59.9241 145.025 59.0558C145.384 62.7678 146.772 66.3058 149.034 69.2711C149.266 69.5503 149.59 69.9368 149.975 69.5996C150.392 69.224 150.882 68.939 151.415 68.7628C151.948 68.5866 152.511 68.5229 153.07 68.5758C153.628 68.6287 154.17 68.797 154.66 69.0702C155.15 69.3433 155.578 69.7152 155.917 70.1624C156.228 70.6228 156.446 71.1403 156.557 71.6851C156.668 72.2298 156.67 72.7912 156.564 73.3368C156.457 73.8825 156.244 74.4017 155.936 74.8648C155.629 75.3278 155.232 75.7254 154.77 76.0349C153.431 76.9207 152.476 76.7915 150.976 76.716C150.506 76.693 150.419 77.0861 150.387 77.4452C149.924 82.6618 149.41 88.6142 150.206 93.8023C150.227 93.9464 150.303 94.0767 150.418 94.1656C150.533 94.2546 150.678 94.2951 150.823 94.2785C150.967 94.262 151.1 94.1897 151.192 94.077C151.284 93.9644 151.328 93.8202 151.316 93.6753C151.327 92.2693 151.274 90.8634 151.228 89.4563C158.559 90.2764 167.841 85.9884 172.943 81.0861C173.751 80.3185 173.173 79.886 172.921 79.6363ZM133.479 43.9437C131.692 43.9116 129.923 44.3132 128.325 45.1143C126.55 45.9662 123.537 48.0839 123.739 50.3046C123.799 50.9703 122.842 51.3777 122.594 50.6911C121.395 47.382 124.732 44.7967 126.957 43.5824C128.939 42.5008 131.16 41.9306 133.418 41.9234C136.307 41.9267 135.114 44.0127 133.479 43.9437Z'
							fill='#434A54'
						/>
					</svg>
					<p className='dash-welcome-verify-text my-7'>
						Let’s begin by verifying your account.
					</p>
					<p className='dash-welcome-text w-[55%] text-center'>
						We have sent a verification link to your email. Click on the link
						sent to verify your account.
					</p>
					<p className='dash-welcome-text w-[55%] text-center my-7'>
						If you did not receive the verification email, click the button
						below to have it resent to you.
					</p>

					<button
						className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 my-7'
						type='submit'
					>
						Resend verification email
					</button>
				</div>
			</div>
		</>
	);
}
