import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DatePicker, Form, Input, Modal, TimePicker } from 'antd';
import toast from 'react-hot-toast';
import { fetchForeignClearances } from '../../features/job/jobSlice';
import plane from '../../assets/plane.png';

export default function ForeignJobsApplciation() {
	const dispatch = useDispatch();

	const { foreignJobs } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const [showSearch, setshowSearch] = useState(true);
	const [jobList, setjobList] = useState(foreignJobs);

	const usrObj = {
		focUsrId: user?.usrId,
	};

	async function handleFetchJobs() {
		await dispatch(fetchForeignClearances(usrObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		setjobList(foreignJobs);
	}, [foreignJobs]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	return (
		<>
			<div className='bg-white p-6 w-full h-fit pb-16 '>
				{jobList?.map((item) => {
					return (
						<>
							<div
								style={{
									border: '1px solid rgba(20, 20, 20, 0.05)',
								}}
								className='flex rounded-[8px] p-[24px] space-x-4 mb-4'
							>
								<div className='flex-shrink-0'>
									<img
										className='w-[70px] h-[75px] rounded-[4px]'
										src={plane}
										alt='N'
									/>
								</div>
								<div className='flex-1 min-w-0'>
									<div className='flex justify-between items-center'>
										<div className=''>
											<p className='text-black14 font-bold text-[18px]'>
												{item?.focJobTitle}
											</p>
											<a
												href={item?.focEmployerWebsite}
												target='_blank'
												rel='noreferrer'
												className='text-black14 font-[500] text-[15px] my-2'
											>
												{item?.focEmployerName}
											</a>
										</div>

										<div className='-mb-7'>
											<div className='flex w-fit rounded-[5px] py-1 px-3 items-center bg-[#FFD06D]'>
												<svg
													className='-mb-2'
													xmlns='http://www.w3.org/2000/svg'
													width='16'
													height='16'
													viewBox='0 0 16 16'
													fill='none'
												>
													<g filter='url(#filter0_d_3171_8692)'>
														<circle cx='8' cy='4' r='4' fill='#321B7C' />
													</g>
													<defs>
														<filter
															id='filter0_d_3171_8692'
															x='0'
															y='0'
															width='16'
															height='16'
															filterUnits='userSpaceOnUse'
															color-interpolation-filters='sRGB'
														>
															<feFlood
																floodOpacity='0'
																result='BackgroundImageFix'
															/>
															<feColorMatrix
																in='SourceAlpha'
																type='matrix'
																values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
																result='hardAlpha'
															/>
															<feOffset dy='4' />
															<feGaussianBlur stdDeviation='2' />
															<feComposite in2='hardAlpha' operator='out' />
															<feColorMatrix
																type='matrix'
																values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
															/>
															<feBlend
																mode='normal'
																in2='BackgroundImageFix'
																result='effect1_dropShadow_3171_8692'
															/>
															<feBlend
																mode='normal'
																in='SourceGraphic'
																in2='effect1_dropShadow_3171_8692'
																result='shape'
															/>
														</filter>
													</defs>
												</svg>
												<span className='text-black font-normal text-[14px] ml-1'>
													In progress
												</span>
											</div>
										</div>
									</div>

									<div className='flex flex-col'>
										<div className='flex items-center flex-wrap text-blackrgb mt-3'>
											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={21}
													color='rgba(20, 20, 20, 0.70)'
													icon='pin_drop'
												/>
												<span className='font-normal text-[14px] ml-1'>
													{item?.focEmployerTown}, {item?.focEmployerCountry}
												</span>
											</div>

											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={21}
													color='rgba(20, 20, 20, 0.70)'
													icon='schedule'
												/>
												<span className='font-normal text-[14px] ml-1'>
													{item?.focContractPeriod} Years
												</span>
											</div>

											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={20}
													color='rgba(20, 20, 20, 0.70)'
													icon='calendar_today'
												/>
												<span className='font-normal text-[14px] ml-1'>
													Application date:{' '}
													{moment(item?.focCreatedDate).format('Do MMM YYYY')}
												</span>
											</div>
										</div>

										<div className='mb-10 mt-5'>
											<div
												style={{
													borderTop: '1px solid #D8D8D88A',
													width: '100%',
												}}
												className='grid grid-cols-6 gap-5 py-4'
											>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H22.75C23.7165 3.5 24.5 4.2835 24.5 5.25V22.75C24.5 23.7165 23.7165 24.5 22.75 24.5H5.25C4.2835 24.5 3.5 23.7165 3.5 22.75V5.25ZM22.75 5.25H5.25V22.75H22.75V5.25Z'
															fill='#888888'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M19.25 1.75C19.7332 1.75 20.125 2.14175 20.125 2.625V6.125C20.125 6.60825 19.7332 7 19.25 7C18.7668 7 18.375 6.60825 18.375 6.125V2.625C18.375 2.14175 18.7668 1.75 19.25 1.75Z'
															fill='#888888'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M8.75 1.75C9.23325 1.75 9.625 2.14175 9.625 2.625V6.125C9.625 6.60825 9.23325 7 8.75 7C8.26675 7 7.875 6.60825 7.875 6.125V2.625C7.875 2.14175 8.26675 1.75 8.75 1.75Z'
															fill='#888888'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.5 9.625C3.5 9.14175 3.89175 8.75 4.375 8.75H23.625C24.1082 8.75 24.5 9.14175 24.5 9.625C24.5 10.1082 24.1082 10.5 23.625 10.5H4.375C3.89175 10.5 3.5 10.1082 3.5 9.625Z'
															fill='#888888'
														/>
													</svg>
													<span className='app-intv-gray ml-1'>
														Departure date
													</span>
												</div>

												<span className='app-intv-black ml-1 col-span-3'>
													{moment(item?.focDepartureDate).format(
														'Do MMMM YYYY',
													)}
												</span>
											</div>

											<div
												style={{
													borderTop: '1px solid #D8D8D88A',
													width: '100%',
												}}
												className='grid grid-cols-6 gap-5 py-4'
											>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M5.25 25.375C5.25 24.8918 5.64175 24.5 6.125 24.5H21.875C22.3582 24.5 22.75 24.8918 22.75 25.375C22.75 25.8582 22.3582 26.25 21.875 26.25H6.125C5.64175 26.25 5.25 25.8582 5.25 25.375Z'
															fill='#888888'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14 8.75C12.5503 8.75 11.375 9.92525 11.375 11.375C11.375 12.8247 12.5503 14 14 14C15.4497 14 16.625 12.8247 16.625 11.375C16.625 9.92525 15.4497 8.75 14 8.75ZM9.625 11.375C9.625 8.95875 11.5838 7 14 7C16.4162 7 18.375 8.95875 18.375 11.375C18.375 13.7912 16.4162 15.75 14 15.75C11.5838 15.75 9.625 13.7912 9.625 11.375Z'
															fill='#888888'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14 3.5C11.9114 3.5 9.90838 4.32968 8.43153 5.80653C6.95468 7.28338 6.125 9.28642 6.125 11.375C6.125 14.9975 8.14787 18.2959 10.2853 20.754C11.3433 21.9707 12.4036 22.9517 13.1995 23.6282C13.5143 23.8958 13.7867 24.1149 14 24.2812C14.2133 24.1149 14.4857 23.8958 14.8005 23.6282C15.5964 22.9517 16.6567 21.9707 17.7147 20.754C19.8521 18.2959 21.875 14.9975 21.875 11.375C21.875 9.28642 21.0453 7.28338 19.5685 5.80653C18.0916 4.32968 16.0886 3.5 14 3.5ZM14 25.375C13.4982 26.0918 13.498 26.0917 13.4977 26.0915L13.497 26.091L13.4949 26.0895L13.4885 26.085L13.4662 26.0691C13.4473 26.0556 13.4203 26.0362 13.3858 26.011C13.3168 25.9607 13.2177 25.8872 13.0927 25.7916C12.8428 25.6006 12.4891 25.3211 12.0661 24.9616C11.2214 24.2436 10.0942 23.2012 8.96472 21.9023C6.72713 19.3291 4.375 15.6275 4.375 11.375C4.375 8.82229 5.38906 6.37414 7.1941 4.5691C8.99914 2.76406 11.4473 1.75 14 1.75C16.5527 1.75 19.0009 2.76406 20.8059 4.5691C22.6109 6.37414 23.625 8.82229 23.625 11.375C23.625 15.6275 21.2729 19.3291 19.0353 21.9023C17.9058 23.2012 16.7786 24.2436 15.9339 24.9616C15.5109 25.3211 15.1572 25.6006 14.9073 25.7916C14.7823 25.8872 14.6832 25.9607 14.6142 26.011C14.5797 26.0362 14.5527 26.0556 14.5338 26.0691L14.5115 26.085L14.5051 26.0895L14.503 26.091L14.5023 26.0915C14.502 26.0917 14.5018 26.0918 14 25.375ZM14 25.375L14.5018 26.0918C14.2005 26.3027 13.7995 26.3027 13.4982 26.0918L14 25.375Z'
															fill='#888888'
														/>
													</svg>
													<span className='app-intv-gray ml-1'>
														Employer address
													</span>
												</div>

												<span className='app-intv-black ml-1 col-span-3'>
													{item?.focEmployerAddress}
												</span>
											</div>

											<div
												style={{
													borderTop: '1px solid #D8D8D88A',
													width: '100%',
												}}
												className='grid grid-cols-6 gap-5 py-4'
											>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
													>
														<path
															d='M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z'
															fill='#888888'
														/>
													</svg>
													<span className='app-intv-gray ml-1'>
														Employer info
													</span>
												</div>

												<span className='app-intv-black ml-1 col-span-3'>
													Email: {item?.focEmployerEmail}, Tel:{' '}
													{item?.focOfficeMobile}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				})}
			</div>
		</>
	);
}
