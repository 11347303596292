import React from "react";
import { Drawer, Button } from "antd";

function TermsOfService({ onClose, open, setChecked }) {
  const closeDrawer = () => {
    setChecked(true);
    onClose();
  };
  return (
    <Drawer width={800} title='Terms of Service' onClose={onClose} open={open}>
      <div className='flex flex-col w-[80%] ml-10'>
        <p>
          I hereby declare that the information provided in this form is true to
          the best of my knowledge, and I understand that any false information
          given could render me liable to immediate disqualification.
        </p>
        <br />
        <br />
        <ul>
          <li>
            <strong>ACCURACY OF CONTENT:</strong> The content of this
            application is accurate and contains no false information.
          </li>
          <li>
            <strong>EDUCATION INFORMATION:</strong> you give your full consent
            and authorize Rujwasco to contact each of your education
            institutions listed in this application for the purpose of
            conducting required reference checks with regard to your educational
            background, and confirm the diploma or degree you have received from
            each education institution. You also authorize the mentioned
            educational institutions to provide requested information directly
            to Rujwasco. Any information received will be treated with due
            regard to confidentiality
          </li>
          <li>
            <strong>WORK EXPERIENCE:</strong> You are aware Rujwasco will
            contact former and current employers, if applicable, regarding work
            experience as well as check your three professional references
            Finally you understand that submission of false information or
            misrepresentation and/or submission of falsified documentation
            constitutes serious misconduct for which sever disciplinary
            sanctions can be imposed I consent to all of the foregoing as part
            of the process of evaluation of my application
          </li>
          <h2>
            Kindly Confirm you have read the above information before submitting
            your application
          </h2>
        </ul>
        <br />
        <br />
        <Button
          onClick={() => {
            setChecked(true);
            onClose();
          }}
        >
          Accept
        </Button>
      </div>
    </Drawer>
  );
}

export default TermsOfService;
