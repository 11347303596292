import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  eduLoading: false,
  educations: [],
  professional: [],
};

export const fetchEducations = createAsyncThunk(
  "eduSlice/fetchEducations",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchEducations.action?eduUsrId=${usrObj?.usrId}&eduType=ACADEMIC`
    );
    return res.data;
  }
);

export const fetchProfessional = createAsyncThunk(
  "eduSlice/fetchProfessional",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchEducations.action?eduUsrId=${usrObj?.usrId}&eduType=PROFESSIONAL`
    );
    return res.data;
  }
);

export const saveEducation = createAsyncThunk(
  "eduSlice/saveEducation",
  async (eduObj) => {
    const res = await axios.post(`${url}/nea/saveEducation.action`, eduObj);
    return res.data;
  }
);

export const eduSlice = createSlice({
  name: "edu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(saveEducation.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(saveEducation.fulfilled, (state) => {
        state.eduLoading = false;
      })
      .addCase(saveEducation.rejected, (state) => {
        state.eduLoading = false;
      })

      .addCase(fetchEducations.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchEducations.fulfilled, (state, action) => {
        state.eduLoading = false;
        if (action?.payload?.success) {
          state.educations = action?.payload?.data?.result;
        } else {
          state.educations = [];
        }
      })
      .addCase(fetchEducations.rejected, (state) => {
        state.eduLoading = false;
      })

      .addCase(fetchProfessional.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchProfessional.fulfilled, (state, action) => {
        state.eduLoading = false;
        if (action?.payload?.success) {
          state.professional = action?.payload?.data?.result;
        } else {
          state.professional = [];
        }
      })
      .addCase(fetchProfessional.rejected, (state) => {
        state.eduLoading = false;
      });
  },
});

export default eduSlice.reducer;
export const {} = eduSlice.actions;
