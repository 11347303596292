import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function NeaToast() {
	function showToastSuccess() {
		const success = toast.custom(
			<>
				<div
					style={{
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					}}
					className='max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#02A548] rounded-[14px] p-8 bg-white mt-10'
				>
					<div className='flex flex-col'>
						<div className='flex justify-between items-center'>
							<span className='text-black14 text-[16px] font-bold'>
								Success toast created successfully
							</span>
							<button
								onClick={() => toast.dismiss(success)}
								type='button'
								className='bg-transparent flex items-center'
							>
								<MaterialIcon color='#141414' icon='close' />
							</button>
						</div>
						<div className='mt-7 flex items-center justify-center'>
							<button
								onClick={() => toast.dismiss(success)}
								className='bg-white rounded-[35px] text-darkBlue hover:border-2 hover:border-darkBlue w-[170px] p-2 my-3 m l-5'
								type='submit'
							>
								Done
							</button>
						</div>
					</div>
				</div>
			</>,
			{
				id: 'success',
			},
		);
	}

	function showToastInfo() {
		const info = toast.custom(
			<>
				<div
					style={{
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					}}
					className='max-w-full w-[800px] max-h-auto h-auto border-l-8 border-darkBlue rounded-[14px] p-8 bg-white mt-10'
				>
					<div className='flex flex-col'>
						<div className='flex justify-between items-center'>
							<span className='text-black14 text-[16px] font-bold'>
								Info toast created successfully
							</span>
							<button
								onClick={() => toast.dismiss(info)}
								type='button'
								className='bg-transparent flex items-center'
							>
								<MaterialIcon color='#141414' icon='close' />
							</button>
						</div>
						<div className='mt-7 flex items-center justify-center'>
							<button
								onClick={() => toast.dismiss(info)}
								className='bg-white rounded-[35px] text-darkBlue hover:border-2 hover:border-darkBlue w-[170px] p-2 my-3 m l-5'
								type='submit'
							>
								Okay
							</button>
						</div>
					</div>
				</div>
			</>,
			{
				id: 'info',
			},
		);
	}

	function showToastError() {
		const error = toast.custom(
			<>
				<div
					style={{
						boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
					}}
					className='max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#C8001B] rounded-[14px] p-8 bg-white mt-10'
				>
					<div className='flex flex-col'>
						<div className='flex justify-between items-center'>
							<span className='text-black14 text-[16px] font-bold'>
								Error toast created successfully
							</span>
							<button
								onClick={() => toast.dismiss(error)}
								type='button'
								className='bg-transparent flex items-center'
							>
								<MaterialIcon color='#141414' icon='close' />
							</button>
						</div>
						<div className='mt-7 flex items-center justify-center'>
							<button
								onClick={() => toast.dismiss(error)}
								type='button'
								className='bg-darkBlue rounded-[35px] text-white w-[200px] p-2'
							>
								Upload Document
							</button>
						</div>
					</div>
				</div>
			</>,
			{
				id: 'error',
			},
		);
	}

	return (
		<>
			<div className='mb-10'>
				<h1 className='text-darkBlue font-bold text-5xl underline mb-3'>
					Toast
				</h1>
				<button
					className='bg-[#02A548] text-white w-[120px] p-2 rounded-md'
					onClick={showToastSuccess}
					type='button'
				>
					Toast Success
				</button>

				<button
					className='bg-darkBlue text-white w-[120px] p-2 rounded-md ml-5'
					onClick={showToastInfo}
					type='button'
				>
					Toast Info
				</button>

				<button
					className='bg-[#C8001B] text-white w-[120px] p-2 rounded-md ml-5'
					onClick={showToastError}
					type='button'
				>
					Toast Error
				</button>
			</div>
		</>
	);
}
