import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	sessLoading: false,
	sessions: [],
};

export const saveCounselor = createAsyncThunk(
	'jobSlice/saveCounselor',
	async (sessObj) => {
		const res = await axios.post(`${url}/nea/saveCounselor.action`, sessObj);
		return res.data;
	},
);

export const fetchCounselors = createAsyncThunk(
	'sessSlice/fetchNeaOffices',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchCounselors.action?cnsUsrId=${usrObj?.cnsUsrId}`,
		);
		return res.data;
	},
);

export const sessSlice = createSlice({
	name: 'sess',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(saveCounselor.pending, (state) => {
				state.sessLoading = true;
			})
			.addCase(saveCounselor.fulfilled, (state) => {
				state.sessLoading = false;
			})
			.addCase(saveCounselor.rejected, (state) => {
				state.sessLoading = false;
			})

			.addCase(fetchCounselors.pending, (state) => {
				state.sessLoading = true;
			})
			.addCase(fetchCounselors.fulfilled, (state, action) => {
				state.sessLoading = false;
				if (action?.payload?.success) {
					state.sessions = action.payload?.data?.result;
				} else {
					state.nea = [];
				}
			})
			.addCase(fetchCounselors.rejected, (state) => {
				state.sessLoading = false;
			});
	},
});

export default sessSlice.reducer;
export const {} = sessSlice.actions;
