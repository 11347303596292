import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Card, Form, Input, Select, InputNumber, Radio, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/Back.svg";
import Header from "../components/signup/Header";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import {
  resendSignupOtp,
  verifySignupOtp,
} from "../../features/auth/authSlice";
import axios from "axios";
function Step2() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const { authLoading, userDetails } = useSelector((state) => state.auth);

  // const onFinish = async (data) => {
  //   console.log(data);
  //   form.resetFields();
  // };
  const handleNext = () => {
    navigate("/step3");
  };
  const handleBack = () => {
    navigate("/step1");
  };
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = value;
    setVerificationCode(updatedVerificationCode);
  };
  const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
  const [activeResend, setActiveResend] = useState(false);
  const resendOTP = async () => {
    const res = await dispatch(
      resendSignupOtp({
        email: userDetails?.usrEmail,
        phoneNumber: userDetails?.usrMobileNumber,
      })
    );
    if (res?.type === "authSlice/resendOtp/fulfilled") {
      setActiveResend(true);
      setCounter({ minutes: 0, seconds: 59 });
    } else {
    }
  };


  const cancatenatedString = verificationCode?.join("");
  function closeModal() {
    toast.dismiss();
  }

  const handleOtp = () => {
    if (cancatenatedString !== userDetails?.usrSalt) {
      showToastError("OTP not matched, please try again", closeModal);
    } else {
      showToastSuccess("OTP matched successfully", closeModal);
      setVerificationCode(["", "", "", ""]);
      navigate("/step3");
    }
  };
  const handleVerifyOtp = async () => {
    const res = await dispatch(
      verifySignupOtp({
        usrId: userDetails?.usrId,
        usrSalt: cancatenatedString,
      })
    );
    if (res?.payload) {
      showToastSuccess("OTP matched successfully", closeModal);
      setVerificationCode(["", "", "", ""]);
      navigate("/step3");
    } else {
      showToastError("OTP not matched, please try again", closeModal);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter.minutes === 0 && counter.seconds === 0) {
        // Timer has reached 0:00, do something when the countdown is over
        clearInterval(interval);
      } else {
        // Update the timer
        setCounter((prevCounter) => {
          if (prevCounter.seconds === 0) {
            // When seconds reach 0, decrement minutes and set seconds to 59
            return { minutes: prevCounter.minutes - 1, seconds: 59 };
          } else {
            // Decrement seconds
            return { ...prevCounter, seconds: prevCounter.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <>
    <div className="sticky bg-white top-0 w-full z-[1000]">
      <Header />
        <div className="lg:w-[1143px] w-auto h-[15px] bg-darkBlue"></div>

          <div
            className="cursor-pointer mt-[30px] lg:pl-[190px] pl-12 text-neutral-800 text-[22px] font-bold font-dmSans leading-relaxed 
        flex items-center"
          >
            <div
              onClick={handleBack}
              style={{
                height: "40px",
                width: "40px",
                background: "#fff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={backArrow} style={{ height: "40px", width: "40px" }} />
            </div>
            <span className="ml-3">Previous</span>
          </div>
          </div>

          <div className="text-center text-black1 text-4xl font-bold font-dmSans">
            Let’s verify that it’s you
          </div>

          <div className="text-center text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed">
            Enter the four digit code we have sent to you.
          </div>
        
    
      <Form onFinish={activeResend ? handleVerifyOtp : handleOtp}
        layout="vertical"
        ref={formRef}
        name="control-ref"
        form={form}>
        {/* start point otp */}
        <div className="mt-[40px] mb-[60px]">
          <div className="flex flex-col space-y-16">
            <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
              {inputRefs?.map((ref, index) => (
                <div key={index} className="w-16 h-16">
                  <input
                    ref={ref}
                    className="w-[78.14px] h-[96px] text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type="text"
                    maxLength="1"
                    value={verificationCode[index]}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center text-darkBlue text-[22px] font-normal font-dmSans leading-relaxed">
          {counter.minutes === 0 && counter.seconds === 0 ? (
            <Button
              className="bg-darkBlue text-white"
              onClick={resendOTP}
              disabled={counter.minutes > 0 && counter.seconds > 0}
              type="primary"
            >
              Resend
            </Button>
          ) : (
            <span className="">Resend code?  {counter.minutes.toString().padStart(2, '0')}:
              {counter.seconds.toString().padStart(2, '0')}</span>
          )}
        </div>
        {/* end point otp */}

        <div className="text-center mt-[40px]">
            <button className="text-white text-[18px] font-bold font-dmSans 
            w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
            justify-center items-center gap-2.5 inline-flex" type="submit">
              Next
            </button>
        </div>

      </Form>

      <div className="text-center mt-[20px]">
        <div
          className="w-[299px] h-[53px] pl-[18px] pr-[17.20px] pt-2 pb-[7.50px] bg-white rounded-[35px] 
            shadow border-2 border-darkBlue justify-center items-center inline-flex"
        >
          <div className="text-darkBlue text-xl font-normal font-dmSans leading-normal">
            Back to login
          </div>
        </div>
      </div>
    </>
  );
}

export default Step2;
