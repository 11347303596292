import { Button, Input, Space, Steps } from "antd";
import Header from "../../layouts/Header";
import { useState } from "react";
import MaterialIcon from "material-icons-react";
import flag from "../../assets/img/flag.png";
import lines from "../../assets/lines.png";
import speaker from "../../assets/speaker.svg";
import edit from "../../assets/edit.svg";
import award from "../../assets/award.svg";
import chat from "../../assets/ChatCircle.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JobVacancy from "../job_vacancy/JobVacancy";

export default function Home() {
  const [showSearch, setshowSearch] = useState(true);
  const description = "This is a description.";

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "LOCAL JOBS",
      children: (
        <>
          <div className='flex items-center mt-5 mb-8 p-4 text-white'>
            <div className='flex items-center'>
              <img src={speaker} alt='speaker' />
              <span className='font-normal text-[17px] ml-2'>
                Let's find your <span className='font-bold'>Dream</span> job:{" "}
              </span>
            </div>

            <div className='flex items-center ml-10'>
              <img src={edit} alt='speaker' />
              <span className='ml-1 text-[15px]'>Easy Apply</span>
            </div>

            <div className='flex items-center mx-5'>
              <img src={award} alt='speaker' />
              <span className='ml-1 text-[15px]'>Verified Employers</span>
            </div>

            <div className='flex items-center'>
              <img src={chat} alt='speaker' />
              <span className='ml-1 text-[15px]'>Reliable Communication</span>
            </div>
          </div>

          <div className='p-4 bg-[#F0F0F0] w-full mt-10'>
            <div className='px-10 py-5 bg-white flex flex-col'>
              <div className='flex items-center justify-center'>
                <div className='flex items-center w-full'>
                  <Input
                    suffix={
                      <div className='flex flex-col'>
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_up'
                        />
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_down'
                        />
                      </div>
                    }
                    className='!placeholder-[#9B9B9E] h-[44px]'
                    placeholder='Jobs by Company'
                    bordered={false}
                    onFocus={() => setshowSearch(!showSearch)}
                    onBlur={() => setshowSearch(true)}
                  />
                </div>

                <div className='flex items-center w-full'>
                  <Input
                    suffix={
                      <div className='flex flex-col'>
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_up'
                        />
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_down'
                        />
                      </div>
                    }
                    className='!placeholder-[#9B9B9E] h-[44px] mx-5'
                    placeholder='Jobs by Type'
                    bordered={false}
                    onFocus={() => setshowSearch(!showSearch)}
                    onBlur={() => setshowSearch(true)}
                  />
                </div>

                <div className='flex items-center w-full'>
                  <Input
                    suffix={
                      <div className='flex flex-col'>
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_up'
                        />
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_down'
                        />
                      </div>
                    }
                    className='!placeholder-[#9B9B9E] h-[44px]'
                    placeholder='Jobs by Sector'
                    bordered={false}
                    onFocus={() => setshowSearch(!showSearch)}
                    onBlur={() => setshowSearch(true)}
                  />
                </div>

                <div className='flex items-center w-full'>
                  <Input
                    type='text'
                    suffix={
                      <div className='flex flex-col'>
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_up'
                        />
                        <MaterialIcon
                          color='#C4C9D3'
                          size={20}
                          icon='arrow_drop_down'
                        />
                      </div>
                    }
                    className='!placeholder-[#9B9B9E] h-[44px] ml-5'
                    placeholder='Jobs by Location'
                    bordered={false}
                    onFocus={() => setshowSearch(!showSearch)}
                    onBlur={() => setshowSearch(true)}
                  />
                </div>

                <Button
                  style={{
                    boxShadow:
                      "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                  }}
                  className='bg-darkBlue rounded-[35px] text-white w-[120px] ml-5 mr-[24px]'
                  type='button'
                >
                  <Space>Search Jobs</Space>
                </Button>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "INTERNATIONAL JOBS",
      children: "Content of Tab Pane 2",
    },
    {
      key: "3",
      label: "GO TO NEA JOB CENTER",
      children: "GO TO NEA JOB CENTER",
    },
  ];

  return (
    <>
      <Header />
      <div className='!h-[100vh]'>
        <JobVacancy />
      </div>
    </>
  );
}
