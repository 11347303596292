import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Input } from "antd";
import { fetchAppliedJobs, setActiveJob } from "../../../features/job/jobSlice";
import jobImg from "../../../assets/NEA-logo.png";

export default function AppStatusCard({ props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appliedJobs, reFetch } = useSelector((state) => state.job);
  const { user } = useSelector((state) => state.auth);

  const [showSearch, setshowSearch] = useState(true);
  const [jobList, setjobList] = useState(appliedJobs);

  const usrObj = {
    japUsrId: user?.usrId,
    japProgressStatus: "",
  };

  async function handleFetchJobs() {
    await dispatch(fetchAppliedJobs(usrObj));
  }

  async function handleActiveJob(item) {
    await dispatch(setActiveJob(item));
    await navigate(
      `/job-info/${item?.jobId}/${item?.jobPosition}`.replace(/\s+/g, "-")
    );
  }

  useEffect(() => {}, [jobList]);

  useEffect(() => {
    setjobList(appliedJobs);
  }, [appliedJobs]);

  useEffect(() => {
    handleFetchJobs();
  }, []);

  useEffect(() => {
    handleFetchJobs();
  }, [reFetch]);

  return (
    <>
      <div
        style={{
          borderRadius: "2px",
          background: "#FBFBFB",
          boxShadow: "0px 0px 8px 0px rgba(167, 167, 167, 0.12)",
        }}
        className='p-3 my-5'
      >
        <div className='p-3 bg-white flex items-center justify-between w-full'>
          <div className='flex items-center w-[70%]'>
            {showSearch ? (
              <MaterialIcon color='#333' size={20} icon='search' />
            ) : null}
            <Input
              className='!placeholder-black333 h-[44px]'
              placeholder={"Search among the jobs you have applied for  "}
              bordered={false}
              onFocus={() => setshowSearch(!showSearch)}
              onBlur={() => setshowSearch(true)}
            />
          </div>

          <button
            className='bg-darkBlue w-[226.503px] p-2 text-white'
            type='button'
          >
            {"Search application"}
          </button>
        </div>
      </div>

      <div className='bg-white p-6 w-full h-fit pb-16 '>
        <p className='app-status-info mb-7 mt-3'>Jobs you’ve applied for</p>

        {jobList?.map((item) => {
          return (
            <>
              <div
                style={{
                  border: "1px solid rgba(20, 20, 20, 0.05)",
                }}
                className='flex rounded-[8px] p-[24px] space-x-4 mb-4'
              >
                <div className='flex-shrink-0'>
                  <img
                    className='w-[70px] h-[75px] rounded-[4px]'
                    src={jobImg}
                    alt='N'
                  />
                </div>
                <div className='flex-1 min-w-0'>
                  <p className='text-black14 font-bold text-[18px]'>
                    {item?.jobTitle}
                  </p>
                  <p className='text-black14 font-[500] text-[15px] my-2'>
                    {item?.jobTitle}
                  </p>

                  <div className='flex items-center'>
                    <span className='p-1 bg-lightPurple text-darkPurple w-fit rounded-[5px] text-[14px] font-medium'>
                      Availability: Immediate
                    </span>

                    <span className='p-1 bg-lightPurple text-darkPurple w-fit rounded-[5px] ml-4 text-[14px] font-medium'>
                      Intermediate
                    </span>
                  </div>

                  <div className='flex justify-between items-center'>
                    <div className='flex items-center flex-wrap text-blackrgb mt-3'>
                      <div className='flex items-center mr-7'>
                        <MaterialIcon
                          size={21}
                          color='rgba(20, 20, 20, 0.70)'
                          icon='pin_drop'
                        />
                        <span className='font-normal text-[14px] ml-1'>
                          {item?.jobCity}, {item?.jobCountry}
                        </span>
                      </div>

                      <div className='flex items-center mr-7'>
                        <MaterialIcon
                          size={21}
                          color='rgba(20, 20, 20, 0.70)'
                          icon='schedule'
                        />
                        <span className='font-normal text-[14px] ml-1'>
                          Full Time, {item?.jobWorkStyle}
                        </span>
                      </div>

                      <div className='flex items-center mr-7'>
                        <span className='font-normal text-[15px] ml-1'>
                          {item?.jobGrossPay
                            ? "KES " + item?.jobGrossPay
                            : "Confidential"}
                        </span>
                      </div>

                      <div className='flex items-center mr-7'>
                        <MaterialIcon
                          size={20}
                          color='rgba(20, 20, 20, 0.70)'
                          icon='calendar_today'
                        />
                        <span className='font-normal text-[14px] ml-1'>
                          Ends{" "}
                          {moment(item?.jobCloseDate).startOf("day").fromNow()}
                        </span>
                      </div>
                    </div>

                    <div className='flex items-center'></div>
                  </div>
                </div>
                <div className='inline-flex items-center'>
                  {item?.japProgressStatus === "SHORTLIST" ? (
                    <div className='flex flex-col'>
                      <span className='p-2 bg-[#EDFFA5] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
                        Shortlisted
                      </span>
                    </div>
                  ) : item?.japProgressStatus === "INTERVIEW" ? (
                    <div className='flex flex-col'>
                      <span className='p-1 bg-[#FFD06D] text-black333 font-bold w-fit rounded-[5px] text-[14px]'>
                        Interview scheduled
                      </span>

                      <span className='text-black333 ml-4 text-[13px] mt-2'>
                        {moment(item?.japInterviewDate).format("Do MMMM YYYY")}
                      </span>
                    </div>
                  ) : item?.japProgressStatus === "OFFER" ? (
                    <div className='flex flex-col'>
                      <span className='p-2 bg-[#CBFFB2] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
                        Hired
                      </span>
                    </div>
                  ) : item?.japProgressStatus === "UNSUCCESSFUL" ? (
                    <div className='flex flex-col'>
                      <span className='p-2 bg-[#FBC099] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
                        Not successful
                      </span>
                    </div>
                  ) : item?.japProgressStatus === "VIEW" ? (
                    <div className='flex flex-col'>
                      <span className='p-2 bg-[#DAFFFD] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
                        Viewed
                      </span>
                    </div>
                  ) : item?.japProgressStatus === "INCOMPLETE" ? (
                    <div className='flex flex-col'>
                      <span className='p-1 bg-[#e5b652] text-black333 font-bold w-fit rounded-[5px] text-[14px]'>
                        Incomplete
                      </span>

                      <div className='flex !items-center mb-7'>
                        <button
                          type='button'
                          onClick={() => handleActiveJob(item)}
                          className='text-[#147CBC] text-[14px]'
                        >
                          Complete application
                        </button>

                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M9 18L15 12L9 6'
                            stroke='#147CBC'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div className='flex flex-col'>
                      <span className='p-2 bg-[#FFDFCD] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
                        Received
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
