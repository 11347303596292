import React, { useEffect, useRef, useState } from 'react';
import {
	Form,
	Input,
	Select,
	Modal,
	Steps,
	theme,
	Button,
	message,
	InputNumber,
	Spin,
	Checkbox,
	Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

export default function FormInputs() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();

	const { authLoading } = useSelector((state) => state.auth);

	const [data, setdata] = useState({});
	const [checkValue, setcheckValue] = useState(false);

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	function handleSelectChange(value) {
		setdata((prevData) => ({
			...prevData,
			objectName: value,
		}));
	}

	const handleNumberChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			objectNumber: '254' + String(e),
		}));
	};

	const onCheck = (e) => {
		setcheckValue(e.target.checked);
	};

	const onFinish = async (data) => {
		console.log(data);
		form.resetFields();
	};

	const options = [
		{
			label: 'Select 1',
			value: 1,
		},
		{
			label: 'Select 2',
			value: 2,
		},
		{
			label: 'Select 3',
			value: 3,
		},
	];

	return (
		<>
			<div className='mb-10'>
				<h1 className='text-darkBlue font-bold text-5xl underline mb-3'>
					FORM & INPUTS
				</h1>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}
				>
					<Form.Item
						name='objSelect'
						label='Select label'
						rules={[
							{
								required: true,
								message: 'Please select something',
							},
						]}
					>
						<Select
							style={{
								width: '100%',
								height: '44px',
							}}
							options={options}
							onChange={(value) => {
								handleSelectChange(value);
							}}
							onDropdownVisibleChange={() => {
								//trigger something
							}}
						/>
					</Form.Item>

					<Form.Item
						name='objNumber'
						label='Phone Number'
						rules={[
							{
								required: true,
								message: 'Please add number',
							},
						]}
					>
						<InputNumber
							maxLength={10}
							minLength={9}
							onChange={handleNumberChange}
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>

					<Form.Item
						name='objEmail'
						label='Email'
						rules={[
							{
								required: true,
								message: 'Please add email',
							},
						]}
					>
						<Input
							type='email'
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>

					<Form.Item
						name='objName'
						label='Name'
						rules={[
							{
								required: true,
								message: 'Please add name',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='objPassword'
						label='Password'
						rules={[
							{
								required: true,
								message: 'Please add password',
							},
						]}
					>
						<Input.Password className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<div className='flex flex-col'>
						<Radio className='pws-radio'>
							At least one upper case letter (A-Z)
						</Radio>
					</div>

					<div className='flex flex-col items-center justify-center mt-5'>
						<button
							className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2'
							type='submit'
						>
							Next
						</button>
						<button
							className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
							type='submit'
						>
							Back to login
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}
