import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Tabs } from 'antd';
import { updateUser } from '../../../features/auth/authSlice';
import moment from 'moment/moment';

export default function InfoTabs({handleCurrentTab}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [middleName, setmiddleName] = useState('');

	async function handleMiddleNameChange(e) {
		setmiddleName(e.target.value);
	}

	async function handleIpdateMiddleName() {
		let usrObj = { ...user, usrMiddleName: middleName };
		await dispatch(updateUser(usrObj));
	}

    function handleNext(){
        handleCurrentTab("2")
    }

	return (
		<>
			<div className='mt-5'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<div className='flex flex-col'>
						<label>Registration No.</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrCode}
							readOnly
						/>
					</div>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mt-7'>
					<div className='flex flex-col'>
						<label>Kenyan ID No. / Alien ID</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrNationalId}
							readOnly
						/>
					</div>

					<div className='flex flex-col'>
						<label>Passport No.</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrNationalId}
							readOnly
						/>
					</div>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-7'>
					<div className='flex flex-col'>
						<label>First Name</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrFullNames?.split(' ').slice(0, -1).join(' ')}
							readOnly
						/>
					</div>

					<div className='flex flex-col'>
						<label>Middle Name</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrMiddleName ? user?.usrMiddleName : middleName}
							onChange={(e) => handleMiddleNameChange(e)}
							onBlur={handleIpdateMiddleName}
							// readOnly={user?.usrMiddleName}
						/>
					</div>

					<div className='flex flex-col'>
						<label>Last Name</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrFullNames?.split(' ').slice(1, 2).join(' ')}
							readOnly
						/>
					</div>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mt-7'>
					<div className='flex flex-col'>
						<label>Gender</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrGender}
							readOnly
						/>
					</div>

					<div className='flex flex-col'>
						<label>Date of birth</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={moment(user?.usrDob).format('Do MMMM YYYY')}
							readOnly
						/>
					</div>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mt-7'>
					<div className='flex flex-col'>
						<label>Email</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrEmail}
							readOnly
						/>
					</div>

					<div className='flex flex-col'>
						<label>Mobile</label>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
							type='text'
							value={user?.usrMobileNumber}
							readOnly
						/>
					</div>
				</div>

				<div className='mt-10 flex items-center justify-center'>
					<div className='flex items-center justify-center mt-5'>
						<button
                            onClick={handleNext}
							className='bg-darkBlue rounded-[35px] text-white w-[196px] p-2'
							type='button'
						>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
