import FormInputs from "../../standards/FormInputs";
import NeaCard from "../../standards/NeaCard";
import NeaModals from "../../standards/NeaModals";
import NeaTables from "../../standards/NeaTables";
import NeaTabs from "../../standards/NeaTabs";
import NeaToast from "../../standards/NeaToast";
import SearchFilter from "../../standards/SearchFilter";

export default function Components() {
	return (
		<>
			<div className="bg-white w-full p-4">
				<NeaTabs />
				<NeaToast />
				<NeaModals />
				<FormInputs />
				<NeaTables />
				<SearchFilter />
				<NeaCard />
			</div>
		</>
	);
}
