import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	profileLoading: false,
	stepCurrent: 0,
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		handleStepCurrent: (state, action) => {
			state.stepCurrent = action.payload;
		},
		handleNextStep: (state) => {
			state.stepCurrent ++;
		},
		handlePrevStep: (state) => {
			state.stepCurrent --;
		},
	},
	extraReducers: (builder) => {},
});

export default profileSlice.reducer;
export const { handleStepCurrent, handleNextStep, handlePrevStep } = profileSlice.actions;
