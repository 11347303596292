import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	docLoading: false,
	docs: [],
};

export const fetchAttachments = createAsyncThunk(
	'docSlice/fetchAttachments',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchAttachments.action?attInstId=${usrObj?.usrId}`,
		);
		return res.data;
	},
);

export const saveAttachment = createAsyncThunk(
	'docSlice/saveAttachment',
	async (docObj) => {
		const res = await axios.post(`${url}/nea/saveAttachment.action`, docObj);
		return res.data;
	},
);

export const docSlice = createSlice({
	name: 'doc',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(saveAttachment.pending, (state) => {
				state.docLoading = true;
			})
			.addCase(saveAttachment.fulfilled, (state) => {
				state.docLoading = false;
			})
			.addCase(saveAttachment.rejected, (state) => {
				state.docLoading = false;
			})

			.addCase(fetchAttachments.pending, (state) => {
				state.docLoading = true;
			})
			.addCase(fetchAttachments.fulfilled, (state, action) => {
				state.docLoading = false;
				if (action?.payload?.success) {
					state.docs = action?.payload?.data?.result;
				} else {
					state.docs = [];
				}
			})
			.addCase(fetchAttachments.rejected, (state) => {
				state.docLoading = false;
			});
	},
});

export default docSlice.reducer;
export const {} = docSlice.actions;
