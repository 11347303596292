import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import con from '../../assets/con2.png';
import { Button, Form, Input, Modal, Space } from 'antd';
import { fetchNeaOffices } from '../../features/global/globalSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { fetchCounselors, saveCounselor } from '../../features/sess/sessSlice';
import moment from 'moment/moment';
import toast from 'react-hot-toast';

const { TextArea } = Input;

export default function Counselor() {
	// const navigate = useNavigate()
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { nea } = useSelector((state) => state.global);
	const { sessions } = useSelector((state) => state.sess);

	const [showSearch, setshowSearch] = useState(true);
	const [options, setOptions] = useState([]);
	const [neaOffice, setneaOffice] = useState('');
	const [selected, setselected] = useState({});
	const [sessList, setsessList] = useState(sessions);
	const [open, setopen] = useState(false);
	const [activeSchedule, setactiveSchedule] = useState({});
	const [open2, setopen2] = useState(false);

	const [modal, contextHolder] = Modal.useModal();

	const usrObj = {
		cnsUsrId: user?.usrId,
	};

	async function handleConfirmAvailability(item) {
		let jobObj = {
			...item,
			cnsAvailability: 'ACCEPT',
		};
		const res = await dispatch(saveCounselor(jobObj));
		if (res?.payload?.success) {
			toast('NEA has been updated on your decision');
			await handleFetchsessions();
		} else {
			toast('Could not process your request at the moment');
		}
	}

	async function handleRejectInterview(values) {
		let jobObj = {
			...activeSchedule,
			cnsAvailability: 'REJECT',
			cnsDesc: values.cnsDesc,
		};
		const res = await dispatch(saveCounselor(jobObj));
		if (res?.payload?.success) {
			toast('Interviewer has been updated on your decision');
			await handleClose();
			await handleFetchsessions();
		}
	}

	async function handleRejectModal(item) {
		await setactiveSchedule(item);
		await setopen2(true);
	}

	async function handleReschedule(item) {
		await setactiveSchedule(item);
		await setopen(true);
	}

	function handleClose() {
		setactiveSchedule({});
		setopen(false);
		setopen2(false);
	}

	const onFinish = async () => {
		let jobObj = {
			...activeSchedule,
			cnsAvailability: 'RESCHEDULE',
		};

		const res = await dispatch(saveCounselor(jobObj));

		if (res?.payload?.success) {
			toast('Your reschedule request has been sent to NEA.');
			await handleFetchsessions();
			handleClose();
		} else {
			toast.error(
				'We could not process your request at the moment. Please try again later',
			);
		}
	};

	async function handleSaveSession() {
		const sessObj = {
			cnsInstId: selected?.instId,
			cnsUsrId: user?.usrId,
			cnsAvailability: 'SCHEDULE',
			cnsCreatedBy: user?.usrId,
		};

		const res = await dispatch(saveCounselor(sessObj));
		console.log(res?.payload);
		if (res?.payload?.success) {
			await handleFetchsessions();
			await toast.success(res?.payload?.messages?.message);
			await setselected({});
		}
	}

	const confirm = () => {
		modal.confirm({
			onOk: handleSaveSession,
			title: 'Confirm',
			width: 'fit-content',
			icon: <ExclamationCircleOutlined />,
			content: (
				<>
					<p className='sure my-7'>
						{' '}
						Confirm session request at the following NEA office?
					</p>
					<div className='flex items-center pb-7'>
						<div className='flex items-center'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='28'
								viewBox='0 0 28 28'
								fill='none'
							>
								<path
									fillRule='evenodd'
									clip-rule='evenodd'
									d='M5.25 25.375C5.25 24.8918 5.64175 24.5 6.125 24.5H21.875C22.3582 24.5 22.75 24.8918 22.75 25.375C22.75 25.8582 22.3582 26.25 21.875 26.25H6.125C5.64175 26.25 5.25 25.8582 5.25 25.375Z'
									fill='#888888'
								/>
								<path
									fillRule='evenodd'
									clip-rule='evenodd'
									d='M14 8.75C12.5503 8.75 11.375 9.92525 11.375 11.375C11.375 12.8247 12.5503 14 14 14C15.4497 14 16.625 12.8247 16.625 11.375C16.625 9.92525 15.4497 8.75 14 8.75ZM9.625 11.375C9.625 8.95875 11.5838 7 14 7C16.4162 7 18.375 8.95875 18.375 11.375C18.375 13.7912 16.4162 15.75 14 15.75C11.5838 15.75 9.625 13.7912 9.625 11.375Z'
									fill='#888888'
								/>
								<path
									fillRule='evenodd'
									clip-rule='evenodd'
									d='M14 3.5C11.9114 3.5 9.90838 4.32968 8.43153 5.80653C6.95468 7.28338 6.125 9.28642 6.125 11.375C6.125 14.9975 8.14787 18.2959 10.2853 20.754C11.3433 21.9707 12.4036 22.9517 13.1995 23.6282C13.5143 23.8958 13.7867 24.1149 14 24.2812C14.2133 24.1149 14.4857 23.8958 14.8005 23.6282C15.5964 22.9517 16.6567 21.9707 17.7147 20.754C19.8521 18.2959 21.875 14.9975 21.875 11.375C21.875 9.28642 21.0453 7.28338 19.5685 5.80653C18.0916 4.32968 16.0886 3.5 14 3.5ZM14 25.375C13.4982 26.0918 13.498 26.0917 13.4977 26.0915L13.497 26.091L13.4949 26.0895L13.4885 26.085L13.4662 26.0691C13.4473 26.0556 13.4203 26.0362 13.3858 26.011C13.3168 25.9607 13.2177 25.8872 13.0927 25.7916C12.8428 25.6006 12.4891 25.3211 12.0661 24.9616C11.2214 24.2436 10.0942 23.2012 8.96472 21.9023C6.72713 19.3291 4.375 15.6275 4.375 11.375C4.375 8.82229 5.38906 6.37414 7.1941 4.5691C8.99914 2.76406 11.4473 1.75 14 1.75C16.5527 1.75 19.0009 2.76406 20.8059 4.5691C22.6109 6.37414 23.625 8.82229 23.625 11.375C23.625 15.6275 21.2729 19.3291 19.0353 21.9023C17.9058 23.2012 16.7786 24.2436 15.9339 24.9616C15.5109 25.3211 15.1572 25.6006 14.9073 25.7916C14.7823 25.8872 14.6832 25.9607 14.6142 26.011C14.5797 26.0362 14.5527 26.0556 14.5338 26.0691L14.5115 26.085L14.5051 26.0895L14.503 26.091L14.5023 26.0915C14.502 26.0917 14.5018 26.0918 14 25.375ZM14 25.375L14.5018 26.0918C14.2005 26.3027 13.7995 26.3027 13.4982 26.0918L14 25.375Z'
									fill='#888888'
								/>
							</svg>
							<span className='app-intv-gray ml-1'>NEA Office</span>
						</div>

						<span className='app-intv-black ml-3'>{selected?.instName}</span>
					</div>
				</>
			),
			okText: 'Confirm',
			cancelText: 'Cancel',
			okButtonProps: {
				style: {
					background: '#147cbc',
					color: '#fff',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
			cancelButtonProps: {
				style: {
					background: '#fff',
					border: '1px solid #147cbc',
					color: '#000',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
		});
	};

	async function handleFetchNeaOffices(data) {
		setneaOffice(data);
		const res = await dispatch(fetchNeaOffices(data));
		if (res?.payload?.length) {
			setOptions(res?.payload);
		}
	}

	async function handleFetchsessions() {
		const res = await dispatch(fetchCounselors(usrObj));
		if (res?.payload?.success) {
			setsessList(res?.payload?.data?.result);
		}
	}

	function handleSelected(item) {
		setselected(item);
	}

	useEffect(() => {}, [neaOffice, options, sessList]);

	useEffect(() => {
		setOptions(nea);
	}, [nea]);

	useEffect(() => {
		setsessList(sessions);
	}, [sessions]);

	useEffect(() => {
		handleFetchNeaOffices(neaOffice);
		handleFetchsessions();
	}, []);

	return (
		<>
			<div className='mr-[103px] ml-[42px]'>
				<div className=''>
					<h2 className='prof-name'>
						Need <span className='text-[#147CBC]'>guidance</span>? Let’s find a
						counselor
					</h2>
					<p className='prof-desc mt-2'>
						Book an appointment and get the best advice on the way forward.
					</p>
				</div>

				<div className='mt-7 relative'>
					<img
						className='w-[670px] h-full object-contain'
						src={con}
						alt='counsellor'
					/>

					<div className='absolute top-[3%] left-[30%]'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='677'
							height='409'
							viewBox='0 0 677 409'
							fill='none'
						>
							<path
								d='M360.723 0C186.159 0 44.4462 91.6293 44.4462 204.5C44.4462 242.818 61.1941 279.053 89.5366 309.874C82.451 335.28 60.5499 375.264 0 391.507C0 391.507 115.303 399.421 190.024 376.513C239.623 396.922 298.241 409 360.723 409C535.287 409 677 317.371 677 204.5C677 91.6293 535.287 0 360.723 0Z'
								fill='#F5F5F5'
							/>
						</svg>
					</div>

					<div className='flex-flex-col absolute top-[17%] left-[45%]'>
						<p className='book'>Book a counseling session</p>
						<span className='book-text mt-5 w-[50%] flex'>
							NEA Provides guidance counseling to help you find the job that
							best suits your skills. You can book a counseling session at a NEA
							Center near you.
						</span>
					</div>

					<div className='absolute right-[0%] mb-24 mt-[-17%]'>
						<p className='con-title mb-4'>1. Choose location</p>

						<div className='p-6 bg-darkBlue'>
							<div className='px-4 py-3 bg-white flex items-center justify-between w-full'>
								<div className='flex items-center w-[70%]'>
									{showSearch ? (
										<MaterialIcon color='#333' size={20} icon='search' />
									) : null}
									<Input
										className='!placeholder-black333 h-[44px]'
										placeholder={'Where are you located?'}
										bordered={false}
										onFocus={() => setshowSearch(!showSearch)}
										onBlur={() => setshowSearch(true)}
										onChange={(e) => handleFetchNeaOffices(e.target.value)}
									/>
								</div>

								<button
									className='bg-darkBlue w-[226.503px] p-3 text-white'
									type='button'
								>
									Search NEA Offices
								</button>
							</div>

							<div
								style={{
									alignItems: 'flex-start',
								}}
								className='bg-white text-black333 font-normal text-[16px] p-6 flex flex-col w-full h-[294px] overflow-hidden mt-5 !text-left'
							>
								{options?.map((item) => {
									return (
										<>
											<button
												onClick={() => handleSelected(item)}
												className={
													selected?.instId === item?.instId
														? 'w-[500px] text-left mb-5 p-2 bg-[#EAEAEA] text-black'
														: 'w-[500px] text-left mb-5'
												}
												type='button'
											>
												{item?.instName}
											</button>
										</>
									);
								})}
							</div>
						</div>

						{selected?.instId ? (
							<Space className='mt-5 right-0 absolute'>
								<Button className='req-btn' onClick={confirm}>
									Request appointment
								</Button>
							</Space>
						) : null}
						{contextHolder}
					</div>
				</div>

				<div className='mt-[31%] ml-[15%] '>
					<p className='con-title mb-4'>2. Upcoming sessions</p>

					<div className='bg-darkBlue w-[95%] px-5 py-7'>
						<span className='guide'>Guidance & Counseling session</span>
					</div>

					{sessList?.length ? (
						sessList?.map((item) => {
							return (
								<>
									{item?.cnsAvailability === 'SCHEDULE' ? (
										<div
											style={{
												border: '1px solid rgba(20, 20, 20, 0.05)',
											}}
											className='mt-7 p-5 w-[95%]'
										>
											<div className='flex justify-end items-center mb-7'>
												<div className='flex w-fit rounded-[3px] py-2 px-3 items-center bg-[#DAFFFD]'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
													>
														<path
															d='M21.9235 11.6C19.9035 6.91 16.1035 4 12.0035 4C7.90348 4 4.10348 6.91 2.08348 11.6C2.02842 11.7262 2 11.8623 2 12C2 12.1377 2.02842 12.2738 2.08348 12.4C4.10348 17.09 7.90348 20 12.0035 20C16.1035 20 19.9035 17.09 21.9235 12.4C21.9785 12.2738 22.007 12.1377 22.007 12C22.007 11.8623 21.9785 11.7262 21.9235 11.6ZM12.0035 18C8.83348 18 5.83348 15.71 4.10348 12C5.83348 8.29 8.83348 6 12.0035 6C15.1735 6 18.1735 8.29 19.9035 12C18.1735 15.71 15.1735 18 12.0035 18ZM12.0035 8C11.2124 8 10.439 8.2346 9.7812 8.67412C9.12341 9.11365 8.61072 9.73836 8.30797 10.4693C8.00522 11.2002 7.926 12.0044 8.08034 12.7804C8.23468 13.5563 8.61565 14.269 9.17506 14.8284C9.73447 15.3878 10.4472 15.7688 11.2231 15.9231C11.999 16.0775 12.8033 15.9983 13.5342 15.6955C14.2651 15.3928 14.8898 14.8801 15.3294 14.2223C15.7689 13.5645 16.0035 12.7911 16.0035 12C16.0035 10.9391 15.5821 9.92172 14.8319 9.17157C14.0818 8.42143 13.0644 8 12.0035 8ZM12.0035 14C11.6079 14 11.2212 13.8827 10.8923 13.6629C10.5634 13.4432 10.3071 13.1308 10.1557 12.7654C10.0044 12.3999 9.96474 11.9978 10.0419 11.6098C10.1191 11.2219 10.3096 10.8655 10.5893 10.5858C10.869 10.3061 11.2253 10.1156 11.6133 10.0384C12.0013 9.96126 12.4034 10.0009 12.7689 10.1522C13.1343 10.3036 13.4467 10.56 13.6664 10.8889C13.8862 11.2178 14.0035 11.6044 14.0035 12C14.0035 12.5304 13.7928 13.0391 13.4177 13.4142C13.0426 13.7893 12.5339 14 12.0035 14Z'
															fill='black'
														/>
													</svg>
													<span className=' text-[#333] font-bold text-[15px] ml-1'>
														Waiting for feedback from NEA
													</span>
												</div>
											</div>

											<div className='grid grid-cols-12 gap-6 items-center pb-7'>
												<div className='flex items-center col-span-3'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.50391 5.25C3.50391 4.2835 4.28741 3.5 5.25391 3.5H22.7539C23.7204 3.5 24.5039 4.2835 24.5039 5.25V22.75C24.5039 23.7165 23.7204 24.5 22.7539 24.5H5.25391C4.28741 24.5 3.50391 23.7165 3.50391 22.75V5.25ZM22.7539 5.25H5.25391V22.75H22.7539V5.25Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M19.2539 1.75C19.7372 1.75 20.1289 2.14175 20.1289 2.625V6.125C20.1289 6.60825 19.7372 7 19.2539 7C18.7707 7 18.3789 6.60825 18.3789 6.125V2.625C18.3789 2.14175 18.7707 1.75 19.2539 1.75Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M8.75391 1.75C9.23716 1.75 9.62891 2.14175 9.62891 2.625V6.125C9.62891 6.60825 9.23716 7 8.75391 7C8.27066 7 7.87891 6.60825 7.87891 6.125V2.625C7.87891 2.14175 8.27066 1.75 8.75391 1.75Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.50391 9.625C3.50391 9.14175 3.89566 8.75 4.37891 8.75H23.6289C24.1122 8.75 24.5039 9.14175 24.5039 9.625C24.5039 10.1082 24.1122 10.5 23.6289 10.5H4.37891C3.89566 10.5 3.50391 10.1082 3.50391 9.625Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Application Date
													</span>
												</div>
												<span className='app-intv-black ml-3 col-span-9'>
													{moment(item?.cnsCreatedDate).format('Do MMMM YYYY')}
												</span>
											</div>

											<div className='grid grid-cols-12 gap-6 items-center'>
												<div className='flex items-center col-span-3'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M5.25391 25.375C5.25391 24.8918 5.64566 24.5 6.12891 24.5H21.8789C22.3622 24.5 22.7539 24.8918 22.7539 25.375C22.7539 25.8582 22.3622 26.25 21.8789 26.25H6.12891C5.64566 26.25 5.25391 25.8582 5.25391 25.375Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 8.75C12.5542 8.75 11.3789 9.92525 11.3789 11.375C11.3789 12.8247 12.5542 14 14.0039 14C15.4537 14 16.6289 12.8247 16.6289 11.375C16.6289 9.92525 15.4537 8.75 14.0039 8.75ZM9.62891 11.375C9.62891 8.95875 11.5877 7 14.0039 7C16.4202 7 18.3789 8.95875 18.3789 11.375C18.3789 13.7912 16.4202 15.75 14.0039 15.75C11.5877 15.75 9.62891 13.7912 9.62891 11.375Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 3.5C11.9153 3.5 9.91229 4.32968 8.43544 5.80653C6.95859 7.28338 6.12891 9.28642 6.12891 11.375C6.12891 14.9975 8.15177 18.2959 10.2892 20.754C11.3472 21.9707 12.4075 22.9517 13.2034 23.6282C13.5182 23.8958 13.7906 24.1149 14.0039 24.2812C14.2172 24.1149 14.4896 23.8958 14.8044 23.6282C15.6003 22.9517 16.6606 21.9707 17.7186 20.754C19.856 18.2959 21.8789 14.9975 21.8789 11.375C21.8789 9.28642 21.0492 7.28338 19.5724 5.80653C18.0955 4.32968 16.0925 3.5 14.0039 3.5ZM14.0039 25.375C13.5021 26.0918 13.5019 26.0917 13.5016 26.0915L13.5009 26.091L13.4988 26.0895L13.4924 26.085L13.4701 26.0691C13.4512 26.0556 13.4242 26.0362 13.3897 26.011C13.3207 25.9607 13.2216 25.8872 13.0966 25.7916C12.8467 25.6006 12.493 25.3211 12.07 24.9616C11.2253 24.2436 10.0981 23.2012 8.96863 21.9023C6.73104 19.3291 4.37891 15.6275 4.37891 11.375C4.37891 8.82229 5.39297 6.37414 7.198 4.5691C9.00304 2.76406 11.4512 1.75 14.0039 1.75C16.5566 1.75 19.0048 2.76406 20.8098 4.5691C22.6148 6.37414 23.6289 8.82229 23.6289 11.375C23.6289 15.6275 21.2768 19.3291 19.0392 21.9023C17.9097 23.2012 16.7825 24.2436 15.9378 24.9616C15.5148 25.3211 15.1611 25.6006 14.9112 25.7916C14.7862 25.8872 14.6871 25.9607 14.6181 26.011C14.5836 26.0362 14.5566 26.0556 14.5377 26.0691L14.5154 26.085L14.509 26.0895L14.5069 26.091L14.5062 26.0915C14.5059 26.0917 14.5057 26.0918 14.0039 25.375ZM14.0039 25.375L14.5057 26.0918C14.2044 26.3027 13.8034 26.3027 13.5021 26.0918L14.0039 25.375Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Venue
													</span>
												</div>

												<span className='app-intv-black ml-3 col-span-9'>
													{item?.neaOffice}
												</span>
											</div>
										</div>
									) : (
										<div
											style={{
												border: '1px solid rgba(20, 20, 20, 0.05)',
											}}
											className='mt-7 p-5 w-[95%]'
										>
											<div className='grid grid-cols-12 gap-6 items-center pb-7'>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.50391 5.25C3.50391 4.2835 4.28741 3.5 5.25391 3.5H22.7539C23.7204 3.5 24.5039 4.2835 24.5039 5.25V22.75C24.5039 23.7165 23.7204 24.5 22.7539 24.5H5.25391C4.28741 24.5 3.50391 23.7165 3.50391 22.75V5.25ZM22.7539 5.25H5.25391V22.75H22.7539V5.25Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M19.2539 1.75C19.7372 1.75 20.1289 2.14175 20.1289 2.625V6.125C20.1289 6.60825 19.7372 7 19.2539 7C18.7707 7 18.3789 6.60825 18.3789 6.125V2.625C18.3789 2.14175 18.7707 1.75 19.2539 1.75Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M8.75391 1.75C9.23716 1.75 9.62891 2.14175 9.62891 2.625V6.125C9.62891 6.60825 9.23716 7 8.75391 7C8.27066 7 7.87891 6.60825 7.87891 6.125V2.625C7.87891 2.14175 8.27066 1.75 8.75391 1.75Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M3.50391 9.625C3.50391 9.14175 3.89566 8.75 4.37891 8.75H23.6289C24.1122 8.75 24.5039 9.14175 24.5039 9.625C24.5039 10.1082 24.1122 10.5 23.6289 10.5H4.37891C3.89566 10.5 3.50391 10.1082 3.50391 9.625Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Date
													</span>
												</div>
												<span className='app-intv-black ml-3 col-span-3'>
													{moment(item?.cnsDate).format('Do MMMM YYYY')}
												</span>

												{item?.cnsAvailability === 'RESCHEDULE' ? (
													<div className='col-end-13 col-span-3 w-full'>
														<div className='flex w-fit rounded-[3px] py-2 px-3 items-center bg-[#FFC597]'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
															>
																<path
																	d='M21.9235 11.6C19.9035 6.91 16.1035 4 12.0035 4C7.90348 4 4.10348 6.91 2.08348 11.6C2.02842 11.7262 2 11.8623 2 12C2 12.1377 2.02842 12.2738 2.08348 12.4C4.10348 17.09 7.90348 20 12.0035 20C16.1035 20 19.9035 17.09 21.9235 12.4C21.9785 12.2738 22.007 12.1377 22.007 12C22.007 11.8623 21.9785 11.7262 21.9235 11.6ZM12.0035 18C8.83348 18 5.83348 15.71 4.10348 12C5.83348 8.29 8.83348 6 12.0035 6C15.1735 6 18.1735 8.29 19.9035 12C18.1735 15.71 15.1735 18 12.0035 18ZM12.0035 8C11.2124 8 10.439 8.2346 9.7812 8.67412C9.12341 9.11365 8.61072 9.73836 8.30797 10.4693C8.00522 11.2002 7.926 12.0044 8.08034 12.7804C8.23468 13.5563 8.61565 14.269 9.17506 14.8284C9.73447 15.3878 10.4472 15.7688 11.2231 15.9231C11.999 16.0775 12.8033 15.9983 13.5342 15.6955C14.2651 15.3928 14.8898 14.8801 15.3294 14.2223C15.7689 13.5645 16.0035 12.7911 16.0035 12C16.0035 10.9391 15.5821 9.92172 14.8319 9.17157C14.0818 8.42143 13.0644 8 12.0035 8ZM12.0035 14C11.6079 14 11.2212 13.8827 10.8923 13.6629C10.5634 13.4432 10.3071 13.1308 10.1557 12.7654C10.0044 12.3999 9.96474 11.9978 10.0419 11.6098C10.1191 11.2219 10.3096 10.8655 10.5893 10.5858C10.869 10.3061 11.2253 10.1156 11.6133 10.0384C12.0013 9.96126 12.4034 10.0009 12.7689 10.1522C13.1343 10.3036 13.4467 10.56 13.6664 10.8889C13.8862 11.2178 14.0035 11.6044 14.0035 12C14.0035 12.5304 13.7928 13.0391 13.4177 13.4142C13.0426 13.7893 12.5339 14 12.0035 14Z'
																	fill='black'
																/>
															</svg>
															<span className=' text-[#333] font-bold text-[15px] ml-1'>
																Awaiting reschedule
															</span>
														</div>
													</div>
												) : null}

												{item?.cnsAvailability === 'REJECT' ? (
													<div className='col-end-13 col-span-3 w-full'>
														<div className='flex w-fit rounded-[3px] py-2 px-3 items-center bg-[#FFC0B2]'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
															>
																<path
																	d='M21.9235 11.6C19.9035 6.91 16.1035 4 12.0035 4C7.90348 4 4.10348 6.91 2.08348 11.6C2.02842 11.7262 2 11.8623 2 12C2 12.1377 2.02842 12.2738 2.08348 12.4C4.10348 17.09 7.90348 20 12.0035 20C16.1035 20 19.9035 17.09 21.9235 12.4C21.9785 12.2738 22.007 12.1377 22.007 12C22.007 11.8623 21.9785 11.7262 21.9235 11.6ZM12.0035 18C8.83348 18 5.83348 15.71 4.10348 12C5.83348 8.29 8.83348 6 12.0035 6C15.1735 6 18.1735 8.29 19.9035 12C18.1735 15.71 15.1735 18 12.0035 18ZM12.0035 8C11.2124 8 10.439 8.2346 9.7812 8.67412C9.12341 9.11365 8.61072 9.73836 8.30797 10.4693C8.00522 11.2002 7.926 12.0044 8.08034 12.7804C8.23468 13.5563 8.61565 14.269 9.17506 14.8284C9.73447 15.3878 10.4472 15.7688 11.2231 15.9231C11.999 16.0775 12.8033 15.9983 13.5342 15.6955C14.2651 15.3928 14.8898 14.8801 15.3294 14.2223C15.7689 13.5645 16.0035 12.7911 16.0035 12C16.0035 10.9391 15.5821 9.92172 14.8319 9.17157C14.0818 8.42143 13.0644 8 12.0035 8ZM12.0035 14C11.6079 14 11.2212 13.8827 10.8923 13.6629C10.5634 13.4432 10.3071 13.1308 10.1557 12.7654C10.0044 12.3999 9.96474 11.9978 10.0419 11.6098C10.1191 11.2219 10.3096 10.8655 10.5893 10.5858C10.869 10.3061 11.2253 10.1156 11.6133 10.0384C12.0013 9.96126 12.4034 10.0009 12.7689 10.1522C13.1343 10.3036 13.4467 10.56 13.6664 10.8889C13.8862 11.2178 14.0035 11.6044 14.0035 12C14.0035 12.5304 13.7928 13.0391 13.4177 13.4142C13.0426 13.7893 12.5339 14 12.0035 14Z'
																	fill='black'
																/>
															</svg>
															<span className=' text-[#333] font-bold text-[15px] ml-1'>
																Session Cancelled
															</span>
														</div>
													</div>
												) : null}

												{item?.cnsAvailability === 'ACCEPT' ? (
													<div className='col-end-13 col-span-3 w-full'>
														<div className='flex w-fit rounded-[3px] py-2 px-3 items-center bg-[#E3E1FF]'>
															<svg
																className='-mb-2'
																xmlns='http://www.w3.org/2000/svg'
																width='16'
																height='16'
																viewBox='0 0 16 16'
																fill='none'
															>
																<g filter='url(#filter0_d_3171_8692)'>
																	<circle cx='8' cy='4' r='4' fill='#321B7C' />
																</g>
																<defs>
																	<filter
																		id='filter0_d_3171_8692'
																		x='0'
																		y='0'
																		width='16'
																		height='16'
																		filterUnits='userSpaceOnUse'
																		color-interpolation-filters='sRGB'
																	>
																		<feFlood
																			floodOpacity='0'
																			result='BackgroundImageFix'
																		/>
																		<feColorMatrix
																			in='SourceAlpha'
																			type='matrix'
																			values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
																			result='hardAlpha'
																		/>
																		<feOffset dy='4' />
																		<feGaussianBlur stdDeviation='2' />
																		<feComposite
																			in2='hardAlpha'
																			operator='out'
																		/>
																		<feColorMatrix
																			type='matrix'
																			values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
																		/>
																		<feBlend
																			mode='normal'
																			in2='BackgroundImageFix'
																			result='effect1_dropShadow_3171_8692'
																		/>
																		<feBlend
																			mode='normal'
																			in='SourceGraphic'
																			in2='effect1_dropShadow_3171_8692'
																			result='shape'
																		/>
																	</filter>
																</defs>
															</svg>
															<span className=' text-[#321B7C] font-bold text-[15px] ml-1'>
																Upcoming session
															</span>
														</div>
													</div>
												) : null}

												{item?.cnsAvailability === 'COMPLETE' ? (
													<div className='col-end-13 col-span-3 w-full'>
														<div className='flex w-fit rounded-[3px] py-2 px-3 items-center bg-[#CBFFB2]'>
															<svg
																className='-mb-2'
																xmlns='http://www.w3.org/2000/svg'
																width='16'
																height='16'
																viewBox='0 0 16 16'
																fill='none'
															>
																<g filter='url(#filter0_d_3171_8692)'>
																	<circle cx='8' cy='4' r='4' fill='#321B7C' />
																</g>
																<defs>
																	<filter
																		id='filter0_d_3171_8692'
																		x='0'
																		y='0'
																		width='16'
																		height='16'
																		filterUnits='userSpaceOnUse'
																		color-interpolation-filters='sRGB'
																	>
																		<feFlood
																			floodOpacity='0'
																			result='BackgroundImageFix'
																		/>
																		<feColorMatrix
																			in='SourceAlpha'
																			type='matrix'
																			values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
																			result='hardAlpha'
																		/>
																		<feOffset dy='4' />
																		<feGaussianBlur stdDeviation='2' />
																		<feComposite
																			in2='hardAlpha'
																			operator='out'
																		/>
																		<feColorMatrix
																			type='matrix'
																			values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
																		/>
																		<feBlend
																			mode='normal'
																			in2='BackgroundImageFix'
																			result='effect1_dropShadow_3171_8692'
																		/>
																		<feBlend
																			mode='normal'
																			in='SourceGraphic'
																			in2='effect1_dropShadow_3171_8692'
																			result='shape'
																		/>
																	</filter>
																</defs>
															</svg>
															<span className=' text-[#321B7C] font-bold text-[15px] ml-1'>
																Session complete
															</span>
														</div>
													</div>
												) : null}
											</div>

											<div className='grid grid-cols-12 gap-6 pb-7'>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M5.25391 25.375C5.25391 24.8918 5.64566 24.5 6.12891 24.5H21.8789C22.3622 24.5 22.7539 24.8918 22.7539 25.375C22.7539 25.8582 22.3622 26.25 21.8789 26.25H6.12891C5.64566 26.25 5.25391 25.8582 5.25391 25.375Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 8.75C12.5542 8.75 11.3789 9.92525 11.3789 11.375C11.3789 12.8247 12.5542 14 14.0039 14C15.4537 14 16.6289 12.8247 16.6289 11.375C16.6289 9.92525 15.4537 8.75 14.0039 8.75ZM9.62891 11.375C9.62891 8.95875 11.5877 7 14.0039 7C16.4202 7 18.3789 8.95875 18.3789 11.375C18.3789 13.7912 16.4202 15.75 14.0039 15.75C11.5877 15.75 9.62891 13.7912 9.62891 11.375Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 3.5C11.9153 3.5 9.91229 4.32968 8.43544 5.80653C6.95859 7.28338 6.12891 9.28642 6.12891 11.375C6.12891 14.9975 8.15177 18.2959 10.2892 20.754C11.3472 21.9707 12.4075 22.9517 13.2034 23.6282C13.5182 23.8958 13.7906 24.1149 14.0039 24.2812C14.2172 24.1149 14.4896 23.8958 14.8044 23.6282C15.6003 22.9517 16.6606 21.9707 17.7186 20.754C19.856 18.2959 21.8789 14.9975 21.8789 11.375C21.8789 9.28642 21.0492 7.28338 19.5724 5.80653C18.0955 4.32968 16.0925 3.5 14.0039 3.5ZM14.0039 25.375C13.5021 26.0918 13.5019 26.0917 13.5016 26.0915L13.5009 26.091L13.4988 26.0895L13.4924 26.085L13.4701 26.0691C13.4512 26.0556 13.4242 26.0362 13.3897 26.011C13.3207 25.9607 13.2216 25.8872 13.0966 25.7916C12.8467 25.6006 12.493 25.3211 12.07 24.9616C11.2253 24.2436 10.0981 23.2012 8.96863 21.9023C6.73104 19.3291 4.37891 15.6275 4.37891 11.375C4.37891 8.82229 5.39297 6.37414 7.198 4.5691C9.00304 2.76406 11.4512 1.75 14.0039 1.75C16.5566 1.75 19.0048 2.76406 20.8098 4.5691C22.6148 6.37414 23.6289 8.82229 23.6289 11.375C23.6289 15.6275 21.2768 19.3291 19.0392 21.9023C17.9097 23.2012 16.7825 24.2436 15.9378 24.9616C15.5148 25.3211 15.1611 25.6006 14.9112 25.7916C14.7862 25.8872 14.6871 25.9607 14.6181 26.011C14.5836 26.0362 14.5566 26.0556 14.5377 26.0691L14.5154 26.085L14.509 26.0895L14.5069 26.091L14.5062 26.0915C14.5059 26.0917 14.5057 26.0918 14.0039 25.375ZM14.0039 25.375L14.5057 26.0918C14.2044 26.3027 13.8034 26.3027 13.5021 26.0918L14.0039 25.375Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Venue
													</span>
												</div>
												<span className='app-intv-black ml-3 col-span-10'>
													{item?.neaOffice}
												</span>
											</div>

											<div className='grid grid-cols-12 gap-6 pb-7'>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 4.375C8.68817 4.375 4.37891 8.68426 4.37891 14C4.37891 19.3157 8.68817 23.625 14.0039 23.625C19.3196 23.625 23.6289 19.3157 23.6289 14C23.6289 8.68426 19.3196 4.375 14.0039 4.375ZM2.62891 14C2.62891 7.71776 7.72167 2.625 14.0039 2.625C20.2861 2.625 25.3789 7.71776 25.3789 14C25.3789 20.2822 20.2861 25.375 14.0039 25.375C7.72167 25.375 2.62891 20.2822 2.62891 14Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 7C14.4872 7 14.8789 7.39175 14.8789 7.875V13.125H20.1289C20.6122 13.125 21.0039 13.5168 21.0039 14C21.0039 14.4832 20.6122 14.875 20.1289 14.875H14.0039C13.5207 14.875 13.1289 14.4832 13.1289 14V7.875C13.1289 7.39175 13.5207 7 14.0039 7Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Room
													</span>
												</div>
												<span className='app-intv-black ml-3 col-span-10'>
													{item?.cnsVenue}
												</span>
											</div>

											<div className='grid grid-cols-12 gap-6 pb-7'>
												<div className='flex items-center col-span-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 28 28'
														fill='none'
													>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 4.375C8.68817 4.375 4.37891 8.68426 4.37891 14C4.37891 19.3157 8.68817 23.625 14.0039 23.625C19.3196 23.625 23.6289 19.3157 23.6289 14C23.6289 8.68426 19.3196 4.375 14.0039 4.375ZM2.62891 14C2.62891 7.71776 7.72167 2.625 14.0039 2.625C20.2861 2.625 25.3789 7.71776 25.3789 14C25.3789 20.2822 20.2861 25.375 14.0039 25.375C7.72167 25.375 2.62891 20.2822 2.62891 14Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clip-rule='evenodd'
															d='M14.0039 7C14.4872 7 14.8789 7.39175 14.8789 7.875V13.125H20.1289C20.6122 13.125 21.0039 13.5168 21.0039 14C21.0039 14.4832 20.6122 14.875 20.1289 14.875H14.0039C13.5207 14.875 13.1289 14.4832 13.1289 14V7.875C13.1289 7.39175 13.5207 7 14.0039 7Z'
															fill='#147CBC'
														/>
													</svg>
													<span className='app-intv-gray !text-[#147CBC] ml-1'>
														Time
													</span>
												</div>
												<span className='app-intv-black ml-3 col-span-10'>
													{moment(item?.cnsTime).format('HH : MM A')}
												</span>
											</div>

											{item?.cnsRequirements &&
											item?.cnsAvailability !== 'COMPLETE' ? (
												<>
													<div className='mt-10'>
														<p className='int-reqs mb-3'>
															What to bring with you
														</p>

														<ol className='ml-5 num-list'>
															{item?.cnsRequirements.split(',').map((item) => {
																return (
																	<>
																		<li className='int-reqs mb-2'>{item}</li>
																	</>
																);
															})}
														</ol>
													</div>
												</>
											) : null}

											<div
												style={{
													display:
														item?.cnsAvailability === 'COMPLETE'
															? 'none'
															: 'flex',
												}}
												className='flex items-center justify-between mt-10'
											>
												<button
													disabled={
														item?.cnsAvailability === 'REJECT' ||
														item?.cnsAvailability === 'ACCEPT'
															? true
															: false
													}
													type='button'
													onClick={() => handleRejectModal(item)}
													className='int-no'
												>
													No longer interested
												</button>
												<div className='flex items-center'>
													<button
														disabled={
															item?.cnsAvailability === 'REJECT' ||
															item?.cnsAvailability === 'ACCEPT' ||
															item?.cnsAvailability === 'RESCHEDULE'
																? true
																: false
														}
														onClick={() => handleReschedule(item)}
														className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[210px] p-2'
														type='button'
													>
														Reschedule
													</button>

													<button
														disabled={
															item?.cnsAvailability === 'REJECT' ||
															item?.cnsAvailability === 'ACCEPT' ||
															item?.cnsAvailability === 'RESCHEDULE'
																? true
																: false
														}
														onClick={() => handleConfirmAvailability(item)}
														className='bg-darkBlue rounded-[35px] text-white w-[210px] p-2 ml-4'
														type='button'
													>
														Confirm availability
													</button>
												</div>
											</div>
										</div>
									)}
								</>
							);
						})
					) : (
						<p className='text-2xl '>You have no upcoming session</p>
					)}
				</div>
			</div>
			<br />
			<br />

			<Modal
				title={'Reschedule'}
				// centered
				style={{
					top: 20,
				}}
				footer={null}
				open={open}
				okText={'Next'}
				onCancel={() => setopen(false)}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<div className='flex flex-col mt-5'>
						<p className='sure'>Request reschedule for the following event?</p>

						<div className='flex flex-col'>
							<div className='mb-5 mt-7'>
								<Form
									layout='vertical'
									ref={formRef}
									name='control-ref'
									onFinish={onFinish}
									style={{
										maxWidth: '100%',
									}}
									form={form}
								>
									<Form.Item
										label={
											<span className='interview mb-3'>
												Guidance and counseling session
											</span>
										}
										className='border border-[#B7B7B7] w-full h-auto p-7'
									>
										<div className='grid grid-cols-4 gap-5 py-2'>
											<div className='flex items-center'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='28'
													height='28'
													viewBox='0 0 28 28'
													fill='none'
												>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H22.75C23.7165 3.5 24.5 4.2835 24.5 5.25V22.75C24.5 23.7165 23.7165 24.5 22.75 24.5H5.25C4.2835 24.5 3.5 23.7165 3.5 22.75V5.25ZM22.75 5.25H5.25V22.75H22.75V5.25Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M19.25 1.75C19.7332 1.75 20.125 2.14175 20.125 2.625V6.125C20.125 6.60825 19.7332 7 19.25 7C18.7668 7 18.375 6.60825 18.375 6.125V2.625C18.375 2.14175 18.7668 1.75 19.25 1.75Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M8.75 1.75C9.23325 1.75 9.625 2.14175 9.625 2.625V6.125C9.625 6.60825 9.23325 7 8.75 7C8.26675 7 7.875 6.60825 7.875 6.125V2.625C7.875 2.14175 8.26675 1.75 8.75 1.75Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M3.5 9.625C3.5 9.14175 3.89175 8.75 4.375 8.75H23.625C24.1082 8.75 24.5 9.14175 24.5 9.625C24.5 10.1082 24.1082 10.5 23.625 10.5H4.375C3.89175 10.5 3.5 10.1082 3.5 9.625Z'
														fill='#888888'
													/>
												</svg>
												<span className='app-intv-gray ml-1'>Date</span>
											</div>

											<span className='app-intv-black ml-1 col-span-3'>
												{moment(activeSchedule?.cnsDate).format('Do MMMM YYYY')}
											</span>
										</div>

										<div className='grid grid-cols-4 gap-5 py-2'>
											<div className='flex items-center'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='28'
													height='28'
													viewBox='0 0 28 28'
													fill='none'
												>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M5.25 25.375C5.25 24.8918 5.64175 24.5 6.125 24.5H21.875C22.3582 24.5 22.75 24.8918 22.75 25.375C22.75 25.8582 22.3582 26.25 21.875 26.25H6.125C5.64175 26.25 5.25 25.8582 5.25 25.375Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M14 8.75C12.5503 8.75 11.375 9.92525 11.375 11.375C11.375 12.8247 12.5503 14 14 14C15.4497 14 16.625 12.8247 16.625 11.375C16.625 9.92525 15.4497 8.75 14 8.75ZM9.625 11.375C9.625 8.95875 11.5838 7 14 7C16.4162 7 18.375 8.95875 18.375 11.375C18.375 13.7912 16.4162 15.75 14 15.75C11.5838 15.75 9.625 13.7912 9.625 11.375Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M14 3.5C11.9114 3.5 9.90838 4.32968 8.43153 5.80653C6.95468 7.28338 6.125 9.28642 6.125 11.375C6.125 14.9975 8.14787 18.2959 10.2853 20.754C11.3433 21.9707 12.4036 22.9517 13.1995 23.6282C13.5143 23.8958 13.7867 24.1149 14 24.2812C14.2133 24.1149 14.4857 23.8958 14.8005 23.6282C15.5964 22.9517 16.6567 21.9707 17.7147 20.754C19.8521 18.2959 21.875 14.9975 21.875 11.375C21.875 9.28642 21.0453 7.28338 19.5685 5.80653C18.0916 4.32968 16.0886 3.5 14 3.5ZM14 25.375C13.4982 26.0918 13.498 26.0917 13.4977 26.0915L13.497 26.091L13.4949 26.0895L13.4885 26.085L13.4662 26.0691C13.4473 26.0556 13.4203 26.0362 13.3858 26.011C13.3168 25.9607 13.2177 25.8872 13.0927 25.7916C12.8428 25.6006 12.4891 25.3211 12.0661 24.9616C11.2214 24.2436 10.0942 23.2012 8.96472 21.9023C6.72713 19.3291 4.375 15.6275 4.375 11.375C4.375 8.82229 5.38906 6.37414 7.1941 4.5691C8.99914 2.76406 11.4473 1.75 14 1.75C16.5527 1.75 19.0009 2.76406 20.8059 4.5691C22.6109 6.37414 23.625 8.82229 23.625 11.375C23.625 15.6275 21.2729 19.3291 19.0353 21.9023C17.9058 23.2012 16.7786 24.2436 15.9339 24.9616C15.5109 25.3211 15.1572 25.6006 14.9073 25.7916C14.7823 25.8872 14.6832 25.9607 14.6142 26.011C14.5797 26.0362 14.5527 26.0556 14.5338 26.0691L14.5115 26.085L14.5051 26.0895L14.503 26.091L14.5023 26.0915C14.502 26.0917 14.5018 26.0918 14 25.375ZM14 25.375L14.5018 26.0918C14.2005 26.3027 13.7995 26.3027 13.4982 26.0918L14 25.375Z'
														fill='#888888'
													/>
												</svg>
												<span className='app-intv-gray ml-1'>Venue</span>
											</div>

											<span className='app-intv-black ml-1 col-span-3'>
												{activeSchedule?.cnsVenue}
											</span>
										</div>

										<div className='grid grid-cols-4 gap-5 py-2'>
											<div className='flex items-center'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='28'
													height='28'
													viewBox='0 0 28 28'
													fill='none'
												>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M14 4.375C8.68426 4.375 4.375 8.68426 4.375 14C4.375 19.3157 8.68426 23.625 14 23.625C19.3157 23.625 23.625 19.3157 23.625 14C23.625 8.68426 19.3157 4.375 14 4.375ZM2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14Z'
														fill='#888888'
													/>
													<path
														fillRule='evenodd'
														clip-rule='evenodd'
														d='M14 7C14.4832 7 14.875 7.39175 14.875 7.875V13.125H20.125C20.6082 13.125 21 13.5168 21 14C21 14.4832 20.6082 14.875 20.125 14.875H14C13.5168 14.875 13.125 14.4832 13.125 14V7.875C13.125 7.39175 13.5168 7 14 7Z'
														fill='#888888'
													/>
												</svg>
												<span className='app-intv-gray ml-1'>Time</span>
											</div>

											<span className='app-intv-black ml-1 col-span-3'>
												{moment(activeSchedule?.cnsTime).format('hh:mm a')}
											</span>
										</div>
									</Form.Item>

									<div className='flex flex-col items-center justify-center mt-10'>
										<button
											className='bg-darkBlue rounded-[35px] text-white w-[130px] p-2'
											type='submit'
										>
											Reschedule
										</button>
										<button
											className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[130px] p-2 mt-3'
											type='button'
											onClick={handleClose}
										>
											Cancel
										</button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</>
			</Modal>

			<Modal
				title={'Cancel Session'}
				// centered
				style={{
					top: 20,
				}}
				footer={null}
				open={open2}
				okText={'Next'}
				onCancel={handleClose}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#D3D3D9',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#10101E',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
			>
				<>
					<div className='flex flex-col h-auto'>
						<p className='text-center sure'>Are you sure?</p>
						<p className='text-center my-4 sure-text'>
							You are about to decline the following session.{' '}
						</p>

						<div className='flex border-y border-[#D8D8D88A] py-3'>
							<div className='flex-1 ml-5'>
								<div className='flex items-center w-full'>
									<div className='flex items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											viewBox='0 0 28 28'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H22.75C23.7165 3.5 24.5 4.2835 24.5 5.25V22.75C24.5 23.7165 23.7165 24.5 22.75 24.5H5.25C4.2835 24.5 3.5 23.7165 3.5 22.75V5.25ZM22.75 5.25H5.25V22.75H22.75V5.25Z'
												fill='#888888'
											/>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M19.25 1.75C19.7332 1.75 20.125 2.14175 20.125 2.625V6.125C20.125 6.60825 19.7332 7 19.25 7C18.7668 7 18.375 6.60825 18.375 6.125V2.625C18.375 2.14175 18.7668 1.75 19.25 1.75Z'
												fill='#888888'
											/>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M8.75 1.75C9.23325 1.75 9.625 2.14175 9.625 2.625V6.125C9.625 6.60825 9.23325 7 8.75 7C8.26675 7 7.875 6.60825 7.875 6.125V2.625C7.875 2.14175 8.26675 1.75 8.75 1.75Z'
												fill='#888888'
											/>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M3.5 9.625C3.5 9.14175 3.89175 8.75 4.375 8.75H23.625C24.1082 8.75 24.5 9.14175 24.5 9.625C24.5 10.1082 24.1082 10.5 23.625 10.5H4.375C3.89175 10.5 3.5 10.1082 3.5 9.625Z'
												fill='#888888'
											/>
										</svg>
										<span className='ml-1'>
											{moment(activeSchedule?.cnsDate).format('Do MMMM YYYY')}
										</span>
									</div>

									<div className='flex items-center ml-10'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='20'
											height='20'
											viewBox='0 0 28 28'
											fill='none'
										>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M14 4.375C8.68426 4.375 4.375 8.68426 4.375 14C4.375 19.3157 8.68426 23.625 14 23.625C19.3157 23.625 23.625 19.3157 23.625 14C23.625 8.68426 19.3157 4.375 14 4.375ZM2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14Z'
												fill='#888888'
											/>
											<path
												fillRule='evenodd'
												clip-rule='evenodd'
												d='M14 7C14.4832 7 14.875 7.39175 14.875 7.875V13.125H20.125C20.6082 13.125 21 13.5168 21 14C21 14.4832 20.6082 14.875 20.125 14.875H14C13.5168 14.875 13.125 14.4832 13.125 14V7.875C13.125 7.39175 13.5168 7 14 7Z'
												fill='#888888'
											/>
										</svg>
										<span className='ml-1'>
											{moment(activeSchedule?.cnsTime).format('hh:mm a')}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className='flex flex-col'>
							<div className='mb-5 mt-7'>
								<Form
									layout='vertical'
									ref={formRef}
									name='control-ref'
									onFinish={handleRejectInterview}
									style={{
										maxWidth: '100%',
									}}
									form={form}
								>
									<Form.Item
										name='cnsDesc'
										label='Reason for cancelling'
										rules={[
											{
												required: true,
												message: 'Please state a reason for declining ',
											},
										]}
									>
										<TextArea rows={6} />
									</Form.Item>

									<div className='flex flex-col items-center justify-center mt-5'>
										<button
											className='bg-darkBlue rounded-[35px] text-white w-[130px] p-2'
											type='submit'
										>
											Submit
										</button>
										<button
											className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[130px] p-2 mt-3'
											type='button'
											onClick={handleClose}
										>
											Close
										</button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</>
			</Modal>
		</>
	);
}
