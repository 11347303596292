import React, { useEffect, useRef, useState } from "react";
import "./signin.css";
import { Card, Form, Input, Select, InputNumber, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import neaLogo from "../../assets/NEA-logo.png";
import flag from "../../assets/Flag.svg";
import { handleLoginSession, login } from "../../features/auth/authSlice";
import Logo from "./Logo";

function Login() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authLoading } = useSelector((state) => state.auth);

  const [data, setdata] = useState({});

  function closeModal() {
    toast.dismiss();
  }

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const handleResetPassword = () => {
    navigate("/reset-password");
  };

  const onFinish = async (data) => {
    const res = await dispatch(login({
      usrEncryptedPassword: data?.objPassword,
      usrNationalId: data?.docId,
      usrRoleId: 7
    }))
    if (res?.payload?.success) {
      showToastSuccess("Login successfull", closeModal);
      dispatch(handleLoginSession(true));
      navigate("/home")
      form.resetFields();
    } else {
      showToastError("Check your credentials", closeModal);
    }

  };

  const options = [
    {
      label: "National ID",
      value: "National ID"
    },
    {
      label: "Passport",
      value: "Passport",
    },

  ];

  return (
    <div className="slanted-background">
      <div
        className="flex justify-center items-center z-10 lg:z-10"
        style={{ zIndex: "10" }}
      >
        <div
          className="mt-[50px] mb-[50px] w-[100%] lg:w-[40%] h-auto lg:h-[840px] bg-white shadow bg-opacity-95"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <Logo />

          <div className="text-center ">
            <span className="text-black1 text-4xl font-bold font-dmSans leading-[50.40px]">
              Welcome. <br />
              Log in and find your{" "}
            </span>
            <span className="text-darkBlue text-4xl font-bold font-dmSans leading-[50.40px]">
              dream
            </span>
            <span className="text-black1 text-4xl font-bold font-dmSans leading-[50.40px] relative">
              {" "}
              job.
              <img
                className="absolute top-[-84%] right-[-1%] "
                src={flag}
              />
            </span>
          </div>

          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className="lg:px-[114px] px-[10px] lg:mt-[48px] mt:[24px]"
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <div className="grid grid-cols-1 mb-[-23px]">
              {/* <Form.Item
                name="docType"
                label="Document Type"
                rules={[
                  {
                    required: true,
                    message: "Please select document type",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  options={options}
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  onDropdownVisibleChange={() => {
                    //trigger something
                  }}
                />
              </Form.Item> */}

              <Form.Item
                name="docId"
                label="National ID"
                rules={[
                  {
                    required: true,
                    message: "Please add document id",
                  },
                ]}
              >
                <Input className="rounded-[8px] h-[44px] w-full border border-black" />
              </Form.Item>

              <Form.Item
                name="objPassword"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please add password",
                  },
                ]}
              >
                <Input.Password className="rounded-[8px] h-[44px] w-full border border-black" />
              </Form.Item>
            </div>
            <div
              className="cursor-pointer mt-0 text-end text-darkBlue text-[21px] font-normal font-dmSans leading-relaxed"
              onClick={handleResetPassword}
            >
              Forgot Password?
            </div>



            <div className="text-center mt-[46px]">
              <button
                className="w-[188px] h-[54px] bg-darkBlue rounded-[38px] 
               justify-center items-center gap-2.5 inline-flex text-white text-[21px] font-bold font-dmSans"
                type="submit"
                disabled={authLoading ? true : false}
              >
                {authLoading ? <Spin /> : "Log in"}
              </button>
            </div>

            <div className="cursor-pointer text-center mt-[32px] mb-[87px]">
              <span className="text-black1 text-[21px] font-normal font-dmSans leading-relaxed">
                Don’t have an account?
              </span>
              <span className="text-darkBlue text-[21px] font-normal font-dmSans leading-relaxed"
                onClick={() => navigate('/seeker')}>
                {" "}
                Sign up
              </span>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
