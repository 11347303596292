import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Modal,
  Steps,
  theme,
  Button,
  message,
  InputNumber,
  Spin,
  Checkbox,
  Radio,
  DatePicker,
} from "antd";
import moment from "moment/moment";
import { counties } from "../../../counties";
import {
  handleNextStep,
  handlePrevStep,
} from "../../../features/profile/profileSlice";
import expImg from "../../../assets/eduImg.png";
import {
  fetchExperiences,
  saveExperience,
} from "../../../features/exp/expSlice";
import { fetchSkillCategory } from "../../../features/skill/skillSlice";

const { TextArea } = Input;

let countyOptions = [];

let eduLevelOptions = [];

counties.forEach((item) => {
  countyOptions.push({
    label: item?.name,
    value: item?.name,
  });
});

export default function ExperienceStep() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const formRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { skillLoading } = useSelector((state) => state.skill);
  const { experiences } = useSelector((state) => state.exp);

  const [data, setdata] = useState({});
  const [addEducation, setaddEducation] = useState(false);
  const [expList, setexpList] = useState(experiences);
  const [skillsList, setskillsList] = useState([]);
  const [isCurrent, setisCurrent] = useState(false);
  const [editedItem, seteditedItem] = useState({});
  const [editing, setediting] = useState(false);

  const usrObj = {
    usrId: user?.usrId,
  };

  const handleChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleJobTypeChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      expType: value,
    }));
  }

  function onMonthChange(value) {
    console.log(value?._d);
    seteditedItem((prevData) => ({
      ...prevData,
      expStartDate: value,
    }));
  }

  function onYearChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      expStartYear: moment(value?._d).format("YYYY"),
    }));
  }

  function onEndMonthChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      endEndDate: value,
    }));
  }

  function onEndYearChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      expEndMonth: moment(value?._d).format("YYYY"),
    }));
  }

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const onChange = (e) => {
    data.expCurrent = e.target.checked;
    setisCurrent(e.target.checked);
  };

  function handleGoNext() {
    dispatch(handleNextStep());
  }

  function handleGoBack() {
    form.resetFields();
    seteditedItem({});
    setaddEducation(false);
    dispatch(handlePrevStep());
  }

  async function handleCancel() {
    await seteditedItem({});
    // await form2.resetFields()
    await setediting(false);
    await setaddEducation(false);
  }

  async function handleaddNew() {
    await seteditedItem({});
    await setediting(false);
    await setaddEducation(true);
  }

  async function handleEditItem(e, item) {
    await e.preventDefault();
    await seteditedItem(item);
    await setediting(true);
    await setaddEducation(true);
  }

  const onFinish = async (values) => {
    values.expStartMonth = moment(values?.month?.$d).format("MMMM");
    values.expStartYear = values?.year?.$y;

    values.expEndMonth = moment(values?.end_month?.$d).format("MMMM");
    values.expEndYear = values?.end_year?.$y;

    values.expCurrent = data.expCurrent;
    values.expUsrId = user?.usrId;

    delete values.end_month;
    delete values.end_year;

    delete values.year;
    delete values.month;

    console.log(values);

    const res = await dispatch(saveExperience(values));
    const resp = await dispatch(fetchExperiences(usrObj));

    if (resp?.payload?.success) {
      await setexpList(resp?.payload?.data?.result);
    }

    await setaddEducation(false);
    await setisCurrent(false);
    await form.resetFields();
  };

  const onFinish2 = async () => {
    await dispatch(saveExperience(editedItem));
    const resp = await dispatch(fetchExperiences(usrObj));

    if (resp?.payload?.success) {
      await setexpList(resp?.payload?.data?.result);
    }

    await setaddEducation(false);
    await form.resetFields();
  };

  async function handleFetchExp() {
    const resp = await dispatch(fetchExperiences(usrObj));

    if (resp?.payload?.success) {
      await setexpList(resp?.payload?.data?.result);
    }
  }

  async function handleFetchSKillCategory() {
    const res = await dispatch(fetchSkillCategory());
    if (res?.payload?.success) {
      let skills = [];

      await res?.payload?.data?.result?.forEach((item) => {
        skills.push({
          label: item?.sklcatName,
          value: item?.sklcatName,
        });
      });

      await setskillsList(skills);
    }
  }

  useEffect(() => {}, [expList, editedItem, isCurrent]);

  useEffect(() => {
    setexpList(experiences);
  }, [experiences]);

  useEffect(() => {
    handleFetchExp();
  }, []);

  return (
    <>
      <div className='mt-10'>
        <h3 className='edit-step-head'>Experience</h3>
        <p className='edit-step-desc mt-2'>
          Tell us about your work experience so far. Feel free to include
          volunteer work and internships too.
        </p>
        <div className='flex justify-end w-full'>
          {addEducation ? null : (
            <button
              onClick={handleaddNew}
              className='bg-transparent flex items-center border-none'
            >
              <div className='bg-darkBlue rounded-full p-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clip-rule='evenodd'
                    d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
                    fill='white'
                  />
                  <path
                    fillRule='evenodd'
                    clip-rule='evenodd'
                    d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
                    fill='white'
                  />
                </svg>
              </div>
              <span className='text-darkBlue font-medium text-[17px] ml-3'>
                Add Experience
              </span>
            </button>
          )}
        </div>

        {expList?.length && !addEducation ? (
          <>
            {expList?.map((item) => {
              return (
                <>
                  <a onClick={(e) => handleEditItem(e, item)} href='#to'>
                    <div className='bg-white p-6 mb-4 mt-10'>
                      <div className='w-full flex flex-col'>
                        <h3 className='text-darkBlue text-[21px] font-bold'>
                          {item?.expEmployer}
                        </h3>
                        <p className='text-black333 font-medium text-[18px] my-1'>
                          {item?.expType}
                        </p>
                        <span className='text-black333 font-normal text-[19px]'>
                          {moment(item?.expStartDate).format("MMMM YYYY")} -{" "}
                          {item?.expCurrent
                            ? "Current"
                            : moment(item?.endEndDate).format("MMMM YYYY")}
                        </span>

                        <span className='text-black2 font-normal text-[18px] text-justify mt-5'>
                          {item?.expDesc}
                        </span>
                      </div>
                    </div>
                  </a>
                </>
              );
            })}

            <div className='mt-20'></div>

            <div className='absolute bottom-10 flex justify-center items-center w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : !addEducation && !expList?.length ? (
          <>
            <div className='flex items-center justify-center flex-col'>
              <img
                className='object-contain w-[550px] h-auto'
                src={expImg}
                alt='education'
              />
              <p className='edit-step-desc my-10'>
                Don’t have work experience? You may skip this part
              </p>
            </div>

            <div className='flex items-center justify-center mt-10 mb-14 w-full'>
              <button
                onClick={handleGoBack}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Back
              </button>
              <button
                onClick={handleGoNext}
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
                type='button'
              >
                Next
              </button>
            </div>
          </>
        ) : editing ? (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish2}
            style={{
              maxWidth: "100%",
              marginTop: 20,
            }}
            form={form2}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <div className='flex flex-col'>
                <label className='mb-2'>Employer Name</label>
                <Input
                  onChange={handleChange}
                  name='expEmployer'
                  value={editedItem?.expEmployer}
                  className='rounded-[8px] h-[44px] w-full border border-black'
                />
              </div>

              <div className='flex flex-col'>
                <label className='mb-2'>Job Type</label>

                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  options={skillsList}
                  loading={skillLoading}
                  onChange={(value) => {
                    handleJobTypeChange(value);
                  }}
                  onDropdownVisibleChange={() => {
                    handleFetchSKillCategory();
                  }}
                  value={editedItem?.expType}
                />
              </div>

              <div className='mt-4'>
                <p className='mb-3 font-medium'>Start of Employment</p>
                <DatePicker
                  format={"MMMM - YYYY"}
                  placeholder={
                    editedItem?.expStartDate
                      ? moment(editedItem?.expStartDate).format("MMMM - YYYY")
                      : "Select start date"
                  }
                  className='w-full'
                  onChange={onMonthChange}
                  picker='month'
                />
              </div>

              <div className='mt-4'>
                <p className='mb-3 font-medium'>End of Employment</p>
                <DatePicker
                  format={"MMMM - YYYY"}
                  placeholder={
                    editedItem?.expStartDate
                      ? moment(editedItem?.endEndDate).format("MMMM - YYYY")
                      : "Select start date"
                  }
                  className='w-full'
                  onChange={onEndMonthChange}
                  picker='month'
                />
              </div>
            </div>

            <div className='mt-4'>
              <p className='mb-1 font-medium text-[15px]'>Work Description</p>
              <p className='label-info'>
                what kind of work you did, so they can get a good understanding
                of your experience.
              </p>
              <div className='w-full mt-3'>
                <TextArea
                  value={editedItem?.expDesc}
                  name='expDesc'
                  onChange={handleChange}
                  rows={6}
                />
              </div>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        ) : (
          <Form
            layout='vertical'
            ref={formRef}
            name='control-ref'
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: 20,
            }}
            form={form}
          >
            <div className='grid grid-cols-2 gap-x-6'>
              <Form.Item
                name='expEmployer'
                label='Employer Name'
                rules={[
                  {
                    required: true,
                    message: "Education level is required",
                  },
                ]}
              >
                <Input className='rounded-[8px] h-[44px] w-full border border-black' />
              </Form.Item>

              <Form.Item
                name='expType'
                label='Job Title'
                rules={[
                  {
                    required: true,
                    message: "School name is required",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "100%",
                    height: "44px",
                  }}
                  options={skillsList}
                  loading={skillLoading}
                  onChange={(value) => {
                    handleSelectChange(value);
                  }}
                  onDropdownVisibleChange={() => {
                    handleFetchSKillCategory();
                  }}
                />
              </Form.Item>

              <Form.Item
                label='Start of Employment'
                name='expStartDate'
                rules={[
                  {
                    required: true,
                    message: "Start date is required",
                  },
                ]}
                className='mr-6 w-full'
              >
                <DatePicker
                  placeholder='Select Date'
                  className='mr-3'
                  format={"MMMM - YYYY"}
                  picker='month'
                />
              </Form.Item>

              <div className='flex flex-col mb-5'>
                <Form.Item
                  label='End of Employment'
                  name='endEndDate'
                  rules={[
                    {
                      required: !isCurrent,
                      message: "End date is required",
                    },
                  ]}
                  className='mr-6 w-full'
                >
                  <DatePicker
                    disabled={isCurrent}
                    placeholder='Month'
                    className='mr-3'
                    // onChange={onMonthChange}
                    format={"MMMM"}
                    picker='month'
                  />
                </Form.Item>

                <Checkbox onChange={onChange}>
                  {" "}
                  <span className='check-text '>This is my current job</span>
                </Checkbox>
              </div>
            </div>

            <div>
              <p className='mb-1 font-medium text-[15px]'>Work Description</p>
              <p className='label-info'>
                Tell future employers what kind of work you did, so they can get
                a good understanding of your experience.
              </p>
              <div className='w-full mt-5'>
                <Form.Item
                  name='expDesc'
                  rules={[
                    {
                      required: true,
                      message: "Please add some description about this work",
                    },
                  ]}
                  className='w-full'
                >
                  <TextArea rows={6} />
                </Form.Item>
              </div>
            </div>

            <div className='flex items-center justify-end mt-10 mb-14 w-full'>
              <button
                onClick={handleCancel}
                className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
                type='button'
              >
                Cancel
              </button>
              <button
                className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
                type='submit'
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}
