import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { fetchCurrentUser } from "./features/auth/authSlice";
import Home from "./pages/home/Home";
import Components from "./pages/components/Components";
import Seeker from "./pages/signup/Seeker";
import Step3 from "./pages/signup/Step3";
import Step2 from "./pages/signup/Step2";
import Step1 from "./pages/signup/Step1";
import JobInterests from "./pages/signup/JobInterests";
import Login from "./pages/signin/Login";
import ResetPassword from "./pages/signin/ResetPassword";
import VerifyOtp from "./pages/signin/VerifyOtp";
import NewPassword from "./pages/signin/NewPassword";
import MainProfile from "./pages/profile/MainProfile";
import BuildProfile from "./pages/profile/build_profile/BuildProfile";
import DashBoard from "./pages/dashboard/Dashboard";
import ChooseDocPage from "./pages/cv_builder/ChooseDocPage";
import JobVacancy from "./pages/job_vacancy/JobVacancy";
import JobInfo from "./pages/job_info/JobInfo";
import ApplicationStatus from "./pages/application_status/ApplicationStatus";
import ForeignJobClearance from "./pages/foreign_clearance/ForeignClearance";
import Counselor from "./pages/counselor/Counselor";
import TermsOfService from "./pages/profile/build_profile/TermsOfService";

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [isUserLoggedIn, setisUserLoggedIn] = useState(isLoggedIn);

  useEffect(() => {}, [isUserLoggedIn]);

  useEffect(() => {
    setisUserLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  if (!isUserLoggedIn) {
    return (
      <>
        <div className='wrapper overflow-x-hidden h-[100vh] overflow-y-scroll'>
          {/* <Header /> */}
          <Routes>
            <Route path='*' element={<Navigate to='/login' replace />} />
            <Route exact path='/' element={<Home />} />
            <Route exact path='/home' element={<Home />} />
            <Route exact path='/components' element={<Components />} />
            <Route exact path='/seeker' element={<Step1 />} />
            <Route exact path='/job-interests' element={<Step1 />} />
            <Route exact path='/step1' element={<Step1 />} />
            <Route exact path='/step2' element={<Step2 />} />
            <Route exact path='/step3' element={<Step3 />} />

            <Route exact path='/login' element={<Login />} />
            <Route exact path='/reset-password' element={<ResetPassword />} />
            <Route exact path='/verify-otp-signin' element={<VerifyOtp />} />
            <Route exact path='/new-password' element={<NewPassword />} />
          </Routes>
        </div>
      </>
    );
  }

  return (
    <div className='wrapper'>
      <DashboardLayout isSideBarEnabled={isUserLoggedIn}>
        <Routes>
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route exact path='/' element={<DashBoard />} />
          <Route exact path='/home' element={<DashBoard />} />
          <Route exact path='/components' element={<Components />} />
          <Route exact path='/profile' element={<MainProfile />} />
          <Route exact path='/profile-builder' element={<BuildProfile />} />
          <Route exact path='/cv-cover-letter' element={<ChooseDocPage />} />
          <Route exact path='/job-vacancies' element={<JobVacancy />} />
          <Route exact path='/job-info/:id/:position' element={<JobInfo />} />
          <Route exact path='/terms' element={<TermsOfService />} />
          <Route
            exact
            path='/application-status'
            element={<ApplicationStatus />}
          />
          <Route
            exact
            path='/foreign-job-clearance'
            element={<ForeignJobClearance />}
          />
          <Route exact path='/find-counselor' element={<Counselor />} />
        </Routes>
      </DashboardLayout>
    </div>
  );
}

export default App;
