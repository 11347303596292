import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  jobLoading: false,
  jobs: [],
  activeJob: {},
  profileApplication: false,
  jobsInfo: [],
  appliedJobs: [],
  shortListing: [],
  interviewing: [],
  offers: [],
  unsuccessful: [],
  declined: [],
  reFetch: false,
  foreignJobs: [],
  seekingInfo: {},
  savedJobs: [],
  dashCount: {},
  refJobs: [],
  recJobs: [],
  refRecJobs: [],
};

export const saveSaveJob = createAsyncThunk(
  "jobSlice/saveSaveJob",
  async (jobObj) => {
    const res = await axios.post(`${url}/nea/saveSaveJob.action`, jobObj);
    return res.data;
  }
);

export const saveJobSeekerInfo = createAsyncThunk(
  "jobSlice/saveJobSeekerInfo",
  async (jobObj) => {
    const res = await axios.post(`${url}/nea/saveJobSeekerInfo.action`, jobObj);
    return res.data;
  }
);

export const saveForeignClearance = createAsyncThunk(
  "jobSlice/saveForeignClearance",
  async (jobObj) => {
    const res = await axios.post(
      `${url}/nea/saveForeignClearance.action`,
      jobObj
    );
    return res.data;
  }
);

export const applyJob = createAsyncThunk(
  "jobSlice/applyJob",
  async (jobObj) => {
    const res = await axios.post(
      `${url}/nea/saveJobApplication.action`,
      jobObj
    );
    return res.data;
  }
);

export const fetchJobSeekerInfos = createAsyncThunk(
  "jobSlice/fetchJobSeekerInfos",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchJobSeekerInfos.action?jsiUsrId=${usrObj?.jsiUsrId}`
    );
    return res.data;
  }
);

export const fetchForeignClearances = createAsyncThunk(
  "jobSlice/fetchForeignClearances",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchForeignClearances.action?focUsrId=${usrObj?.focUsrId}`
    );
    return res.data;
  }
);

export const fetchJobs = createAsyncThunk(
  "jobSlice/fetchJobs",
  async (usrObj) => {
    const res = await axios.get(
      `https://selfservice.ruiruwater.co.ke/er/getEmployeeRequisitionList.action`
    );
    return res.data;
  }
);

export const fetchJobInfo = createAsyncThunk(
  "jobSlice/fetchJobInfo",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchJobDescriptions.action?jbdJobId=${usrObj?.jbdJobId}`
    );
    return res.data;
  }
);

export const fetchAppliedJobs = createAsyncThunk(
  "jobSlice/fetchAppliedJobs",
  async (userId) => {
    const res = await axios.get(
      `https://selfservice.ruiruwater.co.ke/er/getAppliedJobApplications.action?nationalId=${userId}`
    );
    return res.data;
  }
);

export const fetchSaveJobs = createAsyncThunk(
  "jobSlice/fetchSaveJobs",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchSaveJobs.action?josUsrId=${usrObj?.josUsrId}`
    );
    return res.data;
  }
);

export const dashboardCount = createAsyncThunk(
  "jobSlice/dashboardCount",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/dashboardCount.action?usrId=${usrObj?.usrId}`
    );
    return res.data;
  }
);

export const fetchJobRecommendations = createAsyncThunk(
  "jobSlice/fetchJobRecommendations",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchJobRecommendations.action?jbrUsrId=${usrObj?.jbrUsrId}`
    );
    return res.data;
  }
);

export const saveJobApplication = createAsyncThunk(
  "jobSlice/saveJobApplication",
  async (jobData) => {
    const res = await axios.post(
      `https://selfservice.ruiruwater.co.ke/er/createJobApplication.action`,
      jobData
    );
    return res.data;
  }
);

export const saveAcQualifications = createAsyncThunk(
  "jobSlice/saveAcQualifications",
  async (qualifications) => {
    const res = await axios.post(
      `https://selfservice.ruiruwater.co.ke/er/createAcademicQualification.action`,
      qualifications
    );
    return res.data;
  }
);

export const SaveJobHistory = createAsyncThunk(
  "jobSlice/SaveJobHistory",
  async (history) => {
    const res = await axios.post(
      `https://selfservice.ruiruwater.co.ke/er/createEmploymentHistory.action`,
      history
    );
    return res.data;
  }
);

export const SaveJobDocuments = createAsyncThunk(
  "jobSlice/SaveJobHistory",
  async (docs) => {
    const res = await axios.post(
      `https://selfservice.ruiruwater.co.ke/er/createJobApplicationDocument.action`,
      docs
    );
    return res.data;
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setActiveJob: (state, action) => {
      state.activeJob = action.payload;
    },
    clearActiveJob: (state) => {
      state.activeJob = {};
    },
    setProfileApplication: (state) => {
      state.profileApplication = true;
    },
    clearProfileApplication: (state) => {
      state.profileApplication = false;
    },
    setreFetch: (state) => {
      state.reFetch = !state.reFetch;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(saveJobApplication.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(saveJobApplication.fulfilled, (state) => {
        state.jobLoading = false;
      })
      .addCase(saveJobApplication.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(saveSaveJob.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(saveSaveJob.fulfilled, (state) => {
        state.jobLoading = false;
      })
      .addCase(saveSaveJob.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(saveJobSeekerInfo.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(saveJobSeekerInfo.fulfilled, (state) => {
        state.jobLoading = false;
      })
      .addCase(saveJobSeekerInfo.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(saveForeignClearance.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(saveForeignClearance.fulfilled, (state) => {
        state.jobLoading = false;
      })
      .addCase(saveForeignClearance.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(applyJob.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(applyJob.fulfilled, (state) => {
        state.jobLoading = false;
      })
      .addCase(applyJob.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchJobSeekerInfos.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobSeekerInfos.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.seekingInfo = action?.payload?.data?.result[0];
        } else {
          state.seekingInfo = {};
        }
      })
      .addCase(fetchJobSeekerInfos.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchForeignClearances.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchForeignClearances.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.foreignJobs = action?.payload?.data?.result;
        } else {
          state.foreignJobs = [];
        }
      })
      .addCase(fetchForeignClearances.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchAppliedJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchAppliedJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          let resp = action?.payload?.data?.result;

          if (action?.payload?.data?.result[0]["no"] === "") {
            state.appliedJobs = [];
          } else {
            state.appliedJobs = resp;
          }

          state.shortListing = resp.filter(
            (item) => item?.japProgressStatus === "SHORTLIST"
          );
          state.interviewing = resp.filter(
            (item) => item?.japProgressStatus === "INTERVIEW"
          );
          state.offers = resp.filter(
            (item) => item?.japProgressStatus === "OFFER"
          );
          state.unsuccessful = resp.filter(
            (item) => item?.japProgressStatus === "UNSUCCESSFUL"
          );
          state.declined = resp.filter(
            (item) =>
              item?.japProgressStatus === "DECLINED_OFFER" ||
              item?.japProgressStatus === "DECLINED_INTERVIEW"
          );
        } else {
          state.appliedJobs = [];
          state.shortListing = [];
          state.interviewing = [];
          state.offers = [];
        }
      })
      .addCase(fetchAppliedJobs.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchJobInfo.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobInfo.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.jobsInfo = action?.payload?.data?.result;
        } else {
          state.jobsInfo = [];
        }
      })
      .addCase(fetchJobInfo.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.jobs = action?.payload?.data?.result;
        } else {
          state.jobs = [];
        }
      })
      .addCase(fetchJobs.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchSaveJobs.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchSaveJobs.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.savedJobs = action?.payload?.data?.result;
        } else {
          state.savedJobs = [];
        }
      })
      .addCase(fetchSaveJobs.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(dashboardCount.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(dashboardCount.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          state.dashCount = action?.payload?.data;
        } else {
          state.dashCount = {};
        }
      })
      .addCase(dashboardCount.rejected, (state) => {
        state.jobLoading = false;
      })

      .addCase(fetchJobRecommendations.pending, (state) => {
        state.jobLoading = true;
      })
      .addCase(fetchJobRecommendations.fulfilled, (state, action) => {
        state.jobLoading = false;
        if (action?.payload?.success) {
          let resp = action?.payload?.data?.result;
          let ref = resp.filter((item) => item?.jbrType === "REFERRAL");
          let rec = resp.filter((item) => item?.jbrType === "RECOMMENDATION");

          state.recJobs = rec;
          state.refJobs = ref;
          state.refRecJobs = resp;
        } else {
          state.recJobs = [];
          state.refJobs = [];
          state.refRecJobs = [];
        }
      })
      .addCase(fetchJobRecommendations.rejected, (state) => {
        state.jobLoading = false;
      });
  },
});

export default jobSlice.reducer;
export const {
  setActiveJob,
  clearActiveJob,
  setProfileApplication,
  clearProfileApplication,
  setreFetch,
} = jobSlice.actions;
