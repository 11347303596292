import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import emblem from "../assets/emblem.png";
import flagDivider from "../assets/flag_divider.svg";
import neaLogo from "../assets/NEA-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import flaglessDivider from "../assets/flag_less.svg";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hide, sethide] = useState(true);
  // const [scroll, setScroll] = useState(false);

  function handleToggle() {
    sethide(!hide);
  }

  // useEffect(() => {
  // 	window.addEventListener('scroll', () => {
  // 		setScroll(window.scrollY > 50);
  // 	});
  // }, []);

  // const onLogin = ()={};
  // const onSignUp = ()={};

  const items = [
    {
      label: <Link to='/login'>For Job seeker</Link>,
      key: "1",
    },
    {
      label: <Link to='/login'>For Employers</Link>,
      key: "2",
    },
    {
      label: <Link to='/login'>For Employment agency</Link>,
      key: "3",
    },
    {
      label: <Link to='/login'>For Partners</Link>,
      key: "4",
    },
  ];

  const signUpItems = [
    {
      label: <Link to='/seeker'>For Job seeker</Link>,
      key: "1",
    },
    {
      label: <Link to='/seeker'>For Employers</Link>,
      key: "2",
    },
    {
      label: <Link to='/seeker'>For Employment agency</Link>,
      key: "3",
    },
    {
      label: <Link to='/seeker'>For Partners</Link>,
      key: "4",
    },
  ];

  const handleMenuClick = (e) => {
    // console.log('click', e);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <div className='w-full h-auto bg-white flex flex-col lg:flex-row lg:items-center lg:justify-between p-1'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <img className='h-10' src={flagDivider} alt='kenya flag colors' />
            <img
              src={neaLogo}
              className='ml-[10px] object-contain w-[160px] h-[55px]'
              alt='NEAIMIS Logo'
            />
            <img
              className='ml-4 h-9'
              src={flaglessDivider}
              alt='kenya flag colors'
            />
          </div>

          <button
            onClick={() => handleToggle()}
            className='block lg:hidden mr-[20px]'
            type='button'
          >
            <span class='sr-only'>Open main menu</span>
            <svg
              class='w-5 h-5'
              aria-hidden='true'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 17 14'
            >
              <path
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M1 1h15M1 7h15M1 13h15'
              />
            </svg>
          </button>
        </div>

        {hide && window.innerWidth >= 300 && window.innerWidth <= 991 ? null : (
          <>
            <div
              className='flex items-center flex-wrap ml-5 lg:ml-0'
              id='navbar-default'
            >
              <button
                onClick={() => navigate("/job-vacancies")}
                type='button'
                className='bg-transparent nav-items mr-[24px]'
              >
                Jobs
              </button>
              <Button
                className='bg-transparent nav-items mr-[24px]'
                type='button'
              >
                <Space>
                  The Hub
                  <DownOutlined />
                </Space>
              </Button>
              <img
                className='mr-[24px] h-9'
                src={flaglessDivider}
                alt='kenya flag colors'
              />
              <Button
                className='bg-transparent nav-items mr-[24px]'
                type='button'
              >
                <Space>
                  EN
                  <DownOutlined />
                </Space>
              </Button>

              <Button
                onClick={() => navigate("/seeker")}
                style={{
                  boxShadow:
                    "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                }}
                className='bg-darkBlue rounded-[35px] text-white w-[120px] ml-5 mr-[24px]'
                type='button'
              >
                Sign up
              </Button>
              <Button
                onClick={() => navigate("/login")}
                style={{
                  boxShadow:
                    "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                }}
                className='bg-white border-2 border-darkBlue rounded-[35px] text-darkBlue w-[120px] mr-[24px]'
                type='button'
              >
                Log in
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
