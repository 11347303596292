import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SavedJob from "./SavedJob";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import AppliedJob from "./AppliedJobs";
import { dashboardCount } from "../../features/job/jobSlice";
import { useNavigate } from "react-router-dom";
import DashNotifications from "./DashNotifications";
import RefJobs from "./RefJobs";

const profileTabs = [
  {
    label: <span className='ml-2'>Applied Jobs</span>,
    key: "1",
    children: <AppliedJob />,
  },
  {
    label: <span className='ml-2'>Saved Jobs</span>,
    key: "2",
    children: <SavedJob />,
  },
  {
    label: <span className='ml-2'>Referrals</span>,
    key: "3",
    children: <RefJobs />,
  },
];

export default function ActiveDash() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { dashCount } = useSelector((state) => state.job);

  const [selected, setselected] = useState(1);
  const [activeTab, setactiveTab] = useState("1");
  const [dashCountObj, setdashCountObj] = useState(dashCount);
  const { appliedJobs } = useSelector((state) => state.job);

  const cardInfo = [
    {
      key: 1,
      title: "Applied Jobs",
      count: appliedJobs.length,
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M20.24 12.2405C21.3658 11.1147 21.9983 9.58771 21.9983 7.99552C21.9983 6.40334 21.3658 4.87637 20.24 3.75052C19.1142 2.62468 17.5872 1.99219 15.995 1.99219C14.4028 1.99219 12.8758 2.62468 11.75 3.75052L5 10.5005V19.0005H13.5L20.24 12.2405Z'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M16 8L2 22'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M17.5 15H9'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),
    },
    {
      key: 2,
      title: "Saved Jobs",
      count: 0,
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='23'
          viewBox='0 0 16 23'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M3.33376e-05 6.07065C-0.00168971 5.7954 0.063404 5.52408 0.189379 5.28157C0.315148 5.03946 0.497557 4.83384 0.719905 4.68347L7.11344 0.281525C7.37505 0.0980892 7.68387 0 8 0C8.31612 0 8.62492 0.0980816 8.88651 0.281496L15.2801 4.68347C15.5024 4.83384 15.6848 5.03946 15.8106 5.28157C15.9366 5.52409 16.0017 5.79543 16 6.07068L16 22.1725C16 22.4776 15.8377 22.7579 15.5777 22.902C15.3177 23.046 15.0022 23.0304 14.7567 22.8613L8 18.209L1.24333 22.8613C0.997819 23.0304 0.682295 23.046 0.422304 22.902C0.162313 22.7579 3.33376e-05 22.4776 3.33376e-05 22.1725L3.33376e-05 6.07065ZM14.84 5.37449L14.3967 6.06333L8 1.65921L1.60004 6.06558L1.60006 6.06751L1.60003 20.6276L7.11339 16.8313C7.375 16.6479 7.68384 16.5498 8 16.5498C8.31615 16.5498 8.62498 16.6479 8.88659 16.8313L14.4 20.6276L14.4 6.05991C14.4 6.0612 14.4002 6.06247 14.4007 6.06366C14.4008 6.06409 14.401 6.0645 14.4012 6.0649C14.4017 6.06587 14.4024 6.06676 14.4031 6.06751C14.4036 6.06794 14.404 6.06834 14.4046 6.06868L14.84 5.37449Z'
            fill='#5FA1DD'
          />
        </svg>
      ),
    },

    {
      key: 3,
      title: "Referrals",
      count: dashCountObj?.referralsCount,
      icon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M23 21.0028V19.0028C22.9993 18.1165 22.7044 17.2556 22.1614 16.5551C21.6184 15.8547 20.8581 15.3544 20 15.1328'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M16 3.13281C16.8604 3.35311 17.623 3.85351 18.1676 4.55512C18.7122 5.25673 19.0078 6.11964 19.0078 7.00781C19.0078 7.89598 18.7122 8.75889 18.1676 9.4605C17.623 10.1621 16.8604 10.6625 16 10.8828'
            stroke='#5FA1DD'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      ),
    },
  ];

  function handleSelected(item) {
    setselected(item.key);
    setactiveTab(String(item.key));
  }

  function handleCvBuilder() {
    return navigate("/cv-cover-letter");
  }

  function currentHour() {
    const currentHour = moment().format("HH");
    if (currentHour === 0 || currentHour < 12) return "morning";
    else if (currentHour <= 19) return "afternoon";
    else return "evening";
  }

  async function handleFetchDashCount() {
    const usrObj = {
      usrId: user?.usrId,
    };
    await dispatch(dashboardCount(usrObj));
  }

  useEffect(() => {}, [dashCountObj]);

  useEffect(() => {
    setdashCountObj(dashCount);
  }, [dashCount]);

  useEffect(() => {
    handleFetchDashCount();
  }, []);

  return (
    <>
      <div className='mr-[108px] ml-[42px] mt-[15px]'>
        <div className=''>
          <h2 className='prof-name'>
            Good <span className='text-[#147CBC]'>{currentHour()}</span>{" "}
            {user?.usrFullNames?.split(" ").slice(0, -1).join(" ")}.
          </h2>
          <p className='prof-desc mt-2'>Let’s see what you’ve been up to.</p>
        </div>

        <div className='flex items-start mt-7'>
          <div className='w-[80%]'>
            <div className='grid grid-cols-4 gap-7 w-[90%]'>
              {cardInfo.map((item) => {
                return (
                  <>
                    <button
                      onClick={() => handleSelected(item)}
                      key={item?.key}
                      className={
                        selected === item.key
                          ? "btn-card-active flex flex-col px-5 py-7"
                          : "btn-card flex flex-col px-5 py-7"
                      }
                      type='button'
                    >
                      <div className='flex justify-between items-center w-full'>
                        <span>{item?.title}</span>

                        {item?.icon}
                      </div>

                      <span className='text-[28px] mt-5 text-darkBlue'>
                        {item?.count}
                      </span>
                    </button>
                  </>
                );
              })}
            </div>

            <div className='mt-5'>
              <Tabs
                activeKey={activeTab}
                className='tabless'
                defaultActiveKey='1'
                items={profileTabs}
              />
            </div>
          </div>

          <div className='hidden lg:block p-6 fixed right-5 bg-white w-[350px] min-h-[500px] h-[500px] overflow-y-scroll content'>
            <DashNotifications />
          </div>
        </div>
      </div>
    </>
  );
}
