import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Input, Tabs } from "antd";
import AppStatusCard from "./app_status_tabs/AppStatusCard";
import ShortListedCard from "./app_status_tabs/ShortListedCard";
import InterviewCard from "./app_status_tabs/InterviewCard";
import OfferCard from "./app_status_tabs/OfferCard";
import UnsuccessfullCard from "./app_status_tabs/UnsuccessfulCard";
import { setreFetch } from "../../features/job/jobSlice";
import DeclinedCard from "./app_status_tabs/DeclinedCard";

const profileTabs = [
  {
    label: <span className='ml-2'>Applied jobs</span>,
    key: "1",
    children: <AppStatusCard />,
  },
  {
    label: <span className='ml-2'>Shortlisted</span>,
    key: "2",
    children: <ShortListedCard shortlisted={true} />,
  },

  {
    label: <span className='ml-2'>Not Successful</span>,
    key: "3",
    children: <UnsuccessfullCard successful={true} />,
  },
  {
    label: <span className='ml-2'>Closed</span>,
    key: "4",
    children: <OfferCard unsuccessfull={true} />,
  },
];

export default function ApplicationStatus() {
  const dispatch = useDispatch();

  function handleReFetch() {
    dispatch(setreFetch());
  }

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <div className='mr-[108px] ml-[42px] mt-[15px]'>
        <div className='bg-white p-[53px]'>
          <h2 className='prof-name'>
            Your <span className='text-[#147CBC]'>application</span> status.
          </h2>
          <p className='prof-desc mt-2'>
            You can track your job application here.
          </p>
        </div>

        <div className=''>
          <div className='mt-7'>
            <Tabs
              onChange={handleReFetch}
              className='job-tab'
              defaultActiveKey='1'
              items={profileTabs}
            />
          </div>
        </div>
      </div>
    </>
  );
}
