import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	prsLoading: false,
	persons: [],
};

export const fetchContactPersons = createAsyncThunk(
	'prsSlice/fetchContactPersons',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchContactPersons.action?conUsrId=${usrObj?.usrId}`,
		);
		return res.data;
	},
);

export const saveContactPerson = createAsyncThunk(
	'prsSlice/saveContactPerson',
	async (prsObj) => {
		const res = await axios.post(`${url}/nea/saveContactPerson.action`, prsObj);
		return res.data;
	},
);

export const prsSlice = createSlice({
	name: 'prs',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(saveContactPerson.pending, (state) => {
				state.prsLoading = true;
			})
			.addCase(saveContactPerson.fulfilled, (state) => {
				state.prsLoading = false;
			})
			.addCase(saveContactPerson.rejected, (state) => {
				state.prsLoading = false;
			})

			.addCase(fetchContactPersons.pending, (state) => {
				state.prsLoading = true;
			})
			.addCase(fetchContactPersons.fulfilled, (state, action) => {
				state.prsLoading = false;
				if (action?.payload?.success) {
					state.persons = action?.payload?.data?.result;
				} else {
					state.persons = [];
				}
			})
			.addCase(fetchContactPersons.rejected, (state) => {
				state.prsLoading = false;
			});
	},
});

export default prsSlice.reducer;
export const {} = prsSlice.actions;
