import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';

export default function NeaTabs() {
	return (
		<>
			<div className='mb-10'>
                <h1 className='text-darkBlue font-bold text-5xl underline mb-3'>TABS</h1>
				<Tabs
					defaultActiveKey='1'
					items={[
						AppleOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
					].map((Icon, i) => {
						const id = String(i + 1);
						return {
							label: (
								<span className='flex items-center'>
									<MaterialIcon color='#147CBC' size={18} icon='library_add' />
									<span className='ml-2'>Tab {id}</span>
								</span>
							),
							key: id,
							children: `Tab ${id}`,
						};
					})}
				/>

				<Tabs
					defaultActiveKey='1'
					items={[
						AppleOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
						AndroidOutlined,
					].map((Icon, i) => {
						const id = String(i + 1);
						return {
							label: <span className='ml-2'>Tab {id}</span>,
							key: id,
							children: `Tab ${id}`,
						};
					})}
				/>
			</div>
		</>
	);
}
