import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Select, InputNumber } from 'antd';
import { counties } from '../../../counties';
import {
	handleNextStep,
	handlePrevStep,
} from '../../../features/profile/profileSlice';
import moment from 'moment/moment';
import {
	fetchContactPersons,
	saveContactPerson,
} from '../../../features/person/personSllice';

let countyOptions = [];

counties.forEach((item) => {
	countyOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

export default function ContactPersonSTep() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const { object } = useSelector((state) => state.redux);
	const [form] = Form.useForm();
	const [form2] = Form.useForm();
	const formRef = useRef(null);

	const { user } = useSelector((state) => state.auth);
	const { persons, prsLoading } = useSelector((state) => state.prs);

	const [data, setdata] = useState({});
	const [personList, setpersonList] = useState(persons);
	const [addEducation, setaddEducation] = useState(false);
	const [editedItem, seteditedItem] = useState({});
	const [editing, setediting] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
	};

	const handleChange = (e) => {
		seteditedItem((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	function handleCOuntyChange(value) {
		seteditedItem((prevData) => ({
			...prevData,
			conCounty: value,
		}));
	}

	const handleNumberChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			conMobileNumber: '254' + String(e),
		}));
	};

	const handleEditNumberChange = (e) => {
		seteditedItem((prevData) => ({
			...prevData,
			conMobileNumber: e,
		}));
	};

	function handleGoNext() {
		navigate('/profile');
	}

	function handleGoBack() {
		form.resetFields();
		seteditedItem({});
		setaddEducation(false);
		dispatch(handlePrevStep());
	}

	async function handleCancel() {
		await seteditedItem({});
		// await form2.resetFields()
		await setediting(false);
		await setaddEducation(false);
	}

	async function handleaddNew() {
		await seteditedItem({});
		await setediting(false);
		await setaddEducation(true);
	}

	async function handleEditItem(e, item) {
		await e.preventDefault();
		await seteditedItem(item);
		await setediting(true);
		await setaddEducation(true);
	}

	const onFinish2 = async () => {
		await dispatch(saveContactPerson(editedItem));
		const resp = await dispatch(fetchContactPersons(usrObj));

		if (resp?.payload?.success) {
			await setpersonList(resp?.payload?.data?.result);
		}

		await setaddEducation(false);
		await form.resetFields();
	};

	const onFinish = async (values) => {
		values.conUsrId = user?.usrId;
		values.conMobileNumber = data?.conMobileNumber;

		await dispatch(saveContactPerson(values));
		const resp = await dispatch(fetchContactPersons(usrObj));

		if (resp?.payload?.success) {
			await setpersonList(resp?.payload?.data?.result);
		}

		await setaddEducation(false);
		await form.resetFields();
	};

	async function handlePersons() {
		const resp = await dispatch(fetchContactPersons(usrObj));

		if (resp?.payload?.success) {
			await setpersonList(resp?.payload?.data?.result);
		}
	}

	useEffect(() => {}, [personList, editedItem]);

	useEffect(() => {
		setpersonList(persons);
	}, [persons]);

	useEffect(() => {
		handlePersons();
	}, []);

	return (
		<>
			<div className='mt-10'>
				<h3 className='edit-step-head'>Contact Person</h3>
				<p className='edit-step-desc mt-2'>
					Let us know who to reach when we are unable to reach you
				</p>
				<div className='flex justify-end w-full'>
					{addEducation ? null : (
						<button
							onClick={handleaddNew}
							className='bg-transparent flex items-center border-none'
						>
							<div className='bg-darkBlue rounded-full p-1'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='32'
									height='32'
									viewBox='0 0 32 32'
									fill='none'
								>
									<path
										fillRule='evenodd'
										clip-rule='evenodd'
										d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
										fill='white'
									/>
									<path
										fillRule='evenodd'
										clip-rule='evenodd'
										d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
										fill='white'
									/>
								</svg>
							</div>
							<span className='text-darkBlue font-medium text-[17px] ml-3'>
								Add Contact Person
							</span>
						</button>
					)}
				</div>

				{personList?.length && !addEducation ? (
					<>
						{personList?.map((item) => {
							return (
								<>
									<a onClick={(e) => handleEditItem(e, item)} href='#to'>
										<div className='bg-white p-6 mb-4 mt-10'>
											<div className='w-full flex flex-col'>
												<h3 className='text-darkBlue text-[21px] font-bold'>
													{item?.conName}
												</h3>
												<p className='text-black333 font-medium text-[18px] my-1'>
													{item?.conMobileNumber}
												</p>
												{item?.conLocation ? (
													<span className='text-black333 font-normal text-[19px]'>
														Location: {item?.conLocation}
													</span>
												) : null}

												{item?.conEmail ? (
													<span className='text-black2 font-normal text-[18px] text-justify mt-5'>
														Email: {item?.conEmail}
													</span>
												) : null}
											</div>
										</div>
									</a>
								</>
							);
						})}

						<div className='mt-20'></div>

						<div className='absolute bottom-10 flex justify-center items-center  w-full'>
							<button
								onClick={handleGoBack}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'
							>
								Back
							</button>
							<button
								onClick={handleGoNext}
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
								type='button'
							>
								Finish
							</button>
						</div>
					</>
				) : !addEducation && !personList?.length ? (
					<>
						<div className='flex items-center justify-center flex-col'>
							<img
								className='object-contain w-[350px] h-auto'
								src={
									'https://www.zebra.com/us/en/about-zebra/contact-zebra/contact-tech-support/_jcr_content/mainpar/shadedboxcontainer_e/par/imagetextcomponent/image.transform/image-full/image.png'
								}
								alt='education'
							/>
							<p className='edit-step-desc my-10'>
								Add a contact person for emergencies or when you are unavailable
							</p>
						</div>

						<div className='flex items-center justify-center mt-10 mb-14 w-full'>
							<button
								onClick={handleGoBack}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'
							>
								Back
							</button>
							<button
								onClick={handleGoNext}
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3 ml-4'
								type='button'
							>
								Finish
							</button>
						</div>
					</>
				) : editing ? (
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish2}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={form2}
					>
						<div className='mb-5'>
							<div className='grid grid-cols-2 gap-x-6'>
								<div className='flex flex-col'>
									<label className='mb-2'>Name</label>
									<Input
										value={editedItem?.conName}
										onChange={handleChange}
										name='conName'
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Phone Number</label>
									<InputNumber
										value={
											editedItem?.conMobileNumber?.length > 10
												? editedItem?.conMobileNumber?.slice(3)
												: editedItem?.conMobileNumber
										}
										maxLength={10}
										minLength={9}
										onChange={handleEditNumberChange}
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Email</label>
									<Input
										type='email'
										value={editedItem?.conEmail}
										onChange={handleChange}
										name='conEmail'
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Location</label>
									<Input
										value={editedItem?.conLocation}
										onChange={handleChange}
										name='conLocation'
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
									{/* <Select
										style={{
											width: '100%',
											height: '44px',
										}}
										onChange={handleCOuntyChange}
										options={countyOptions}
										onDropdownVisibleChange={() => {
											//trigger something
										}}
										value={editedItem?.conCounty}
									/> */}
								</div>
							</div>
						</div>

						<div className='flex items-center justify-end mt-10 mb-14 w-full'>
							<button
								onClick={handleCancel}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'
							>
								Cancel
							</button>
							<button
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
								type='submit'
							>
								Save
							</button>
						</div>
					</Form>
				) : (
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={form}
					>
						<div className='mb-5'>
							<div className='grid grid-cols-2 gap-x-6'>
								<Form.Item
									name='conName'
									label='Name'
									rules={[
										{
											required: true,
											message: 'Name is required',
										},
									]}
									className='w-full'
								>
									<Input className='rounded-[8px] h-[44px] w-full border border-black' />
								</Form.Item>

								<Form.Item
									label='Phone Number'
									name='conMobileNumber'
									rules={[
										{
											required: true,
											message: 'Phone number is required',
										},
									]}
									className='w-full'
								>
									<InputNumber
										maxLength={10}
										minLength={9}
										onChange={handleNumberChange}
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
								</Form.Item>

								<Form.Item name='conEmail' label='Email' className='w-full'>
									<Input
										type='email'
										className='rounded-[8px] h-[44px] w-full border border-black'
									/>
								</Form.Item>

								<Form.Item
									label='Location'
									name='conLocation'
									className='w-full'
								>
									<Input className='rounded-[8px] h-[44px] w-full border border-black' />
									{/* <Select
										style={{
											width: '100%',
											height: '44px',
										}}
										options={countyOptions}
										onDropdownVisibleChange={() => {
											//trigger something
										}}
									/> */}
								</Form.Item>
							</div>
						</div>

						<div className='flex items-center justify-end mt-10 mb-14 w-full'>
							<button
								onClick={handleCancel}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'
							>
								Cancel
							</button>
							<button
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
								type='submit'
							>
								Save
							</button>
						</div>
					</Form>
				)}
			</div>
		</>
	);
}
