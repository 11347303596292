import React from 'react'
import neaLogo from "../../../assets/NEA-logo.png";
import flagDivider from '../../../assets/flag_divider.svg';
import emblem from '../../../assets/emblem.png';

function Header() {
  return (
    
      <div className="flex items-center bg-white lg:bg-transparent w-full mb-[30px]">
        <img
          src={emblem}
          className='ml-[20px] mr-[14px] object-contain w-[97px] h-[86px]'
          alt='kenya Emplem'
        />
        <img src={flagDivider} alt='kenya flag colors' />
        <img
          src={neaLogo}
          className="ml-[25.84px] object-contain w-[196px] h-[70px]"
          alt="NEAIMIS Logo"
        />
      </div>
 
  )
}

export default Header