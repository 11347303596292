import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';

export const showToastSuccess = (message, action) => {
	const success = toast.custom(
		<>
			<div
				style={{
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
				}}

				className='max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#02A548] rounded-[14px] p-2 bg-white mt-10'
			>
				<div className='flex flex-col'>
					<div className='flex justify-between items-center'>
						<span className='text-black14 text-[16px] font-bold'>
							{message}
						</span>
						<button
							onClick={() => toast.dismiss(success)}
							type='button'
							className='bg-transparent flex items-center'
						>
							<MaterialIcon color='#141414' icon='close' />
						</button>
					</div>
					<div className='mt-7 flex items-center justify-center'>
						<button
							onClick={() => action()}
							className='bg-white rounded-[35px] text-darkBlue hover:border-2 hover:border-darkBlue w-[170px] p-2 my-3 m l-5'
							type='submit'
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</>,
		{
			id: 'success',
		},
	);
};
export const showToastError =(message,action)=>{
	const error = toast.custom(
		<>
			<div
				style={{
					boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
				}}
				className='max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#C8001B] rounded-[14px] p-2 bg-white mt-10'
			>
				<div className='flex flex-col'>
					<div className='flex justify-between items-center'>
						<span className='text-black14 text-[16px] font-bold'>
							{message}
						</span>
						<button
							onClick={() => toast.dismiss(error)}
							type='button'
							className='bg-transparent flex items-center'
						>
							<MaterialIcon color='#141414' icon='close' />
						</button>
					</div>
					<div className='mt-7 flex items-center justify-center'>
						<button
							onClick={() => action()}
							type='button'
							className='bg-darkBlue rounded-[35px] text-white w-[200px] p-2'
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</>,
		{
			id: 'error',
		},
	);
}
