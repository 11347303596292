import { useSelector } from 'react-redux';
import InactiveDash from './InactiveDash';
import ActiveDash from './ActiveDash';

export default function DashBoard() {
	const { user } = useSelector((state) => state.auth);

	if (user?.usrJbrnId === 0) {
		return <InactiveDash />;
		
	} else {
		return <ActiveDash />;
	}
}
