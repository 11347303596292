import React, { useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Card, Form, Input, Select, InputNumber, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/Back.svg";
import circleSvg from "../../assets/CheckCircle.svg";
import PasswordChecklist from "react-password-checklist";
import Header from "../components/signup/Header";
import { createUserDetails } from "../../features/auth/authSlice";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";

function Step3() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authLoading, userDetails } = useSelector((state) => state.auth);

  const [data, setdata] = useState({});
  const [checkValue, setcheckValue] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      objectNumber: "254" + String(e),
    }));
  };

  const onCheck = (e) => {
    setcheckValue(e.target.checked);
  };
  function closeModal() {
    toast.dismiss();
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      createUserDetails({
        usrId: userDetails?.usrId,
        usrEncryptedPassword: password,
        usrNationalId: userDetails?.usrNationalId
      })
    );
    if (res?.payload) {
      showToastSuccess("Saved successfully", closeModal);
      form.resetFields();
      navigate("/login")
    } else {
      showToastError(
        "Errror occured while saving, please try again",
        closeModal
      );
    }
  };
  const handleNext = () => {
    navigate("/components");
  };

  const handleBack = () => {
    navigate("/step2");
  };

  const options = [
    {
      label: "Select 1",
      value: 1,
    },
    {
      label: "Select 2",
      value: 2,
    },
    {
      label: "Select 3",
      value: 3,
    },
  ];
  return (
    <>
      <div className="sticky bg-white top-0 w-full z-[1000]">
        <Header />
        <div className="lg:w-[1718px] w-auto h-[15px] bg-darkBlue"></div>
        <div
          className="cursor-pointer mt-[30px] lg:pl-[190px] pl-12 text-neutral-800 text-[22px] font-bold font-dmSans leading-relaxed 
        flex items-center"
        >
          <div
            onClick={handleBack}
            style={{
              height: "40px",
              width: "40px",
              background: "#fff",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={backArrow} style={{ height: "40px", width: "40px" }} />
          </div>
          <span className="ml-3">Previous</span>
        </div>
      </div>

      <div className="text-center text-black1 text-4xl font-bold font-dmSans">
        Lastly, let’s keep your account secure.
      </div>
      <div className="text-center text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed">
        Create a password to your account
      </div>


      <div
        className="lg:p-0 p-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="lg:w-[670px] w-auto grid grid-cols-1">
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please add password",
                },
              ]}
            >
              <Input.Password
                className="rounded-[8px] h-[44px] w-full border border-black"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Please add confirm password",
                },
              ]}
            >
              <Input.Password
                className="rounded-[8px] h-[44px] w-full border border-black"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Item>

            <PasswordChecklist
              className="text-darkBlue"
              rules={[
                "minLength",
                "specialChar",
                "number",
                "capital",
                "match",
              ]}
              minLength={8}
              value={password}
              valueAgain={confirmPassword}
              messages={{
                minLength: "A minimum of 8 characters",
                specialChar: "At least one symbol (~!@$&)",
                number: "At least one number (0-9)",
                capital: "At least one upper case letter (A-Z)",
                match: "Confirm password to match with the password",
              }}
              iconComponents={{
                ValidIcon: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-darkBlue"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ),
                InvalidIcon: <img src={circleSvg} />,
              }}
              validColor="#147CBC"
              iconSize={2}
            />

            <div className="lg:w-[638px] w-auto mt-[54px]">

            </div>
          </div>

          <div className="text-center mt-[40px]">
            <button
              type="submit"
              disabled={authLoading ? true : false}
              className="text-white text-[18px] font-bold font-dmSans
                w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
                justify-center items-center gap-2.5 inline-flex"
            >
              {authLoading ? <Spin /> : "Create account"}
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Step3;
