import React, { useEffect, useRef, useState } from "react";
import "./signin.css";
import {
  Card,
  Form,
  Input,
  Select,
  InputNumber,
  Radio,
  Spin,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import neaLogo from "../../assets/NEA-logo.png";
import flag from "../../assets/Flag.svg";
import { login, resendSignupOtp } from "../../features/auth/authSlice";
import Logo from "./Logo";

function VerifyOtp() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authLoading, resetUser } = useSelector((state) => state.auth);
  const [data, setdata] = useState({});

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = value;
    setVerificationCode(updatedVerificationCode);
  };

  const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
  const [activeResend, setActiveResend] = useState(false);

  const resendOTP = async () => {
    const res = await dispatch(
      resendSignupOtp({
        email: resetUser?.usrEmail,
        phoneNumber: resetUser?.usrMobileNumber,
      })
    );
    if (res?.type === "authSlice/resendOtp/fulfilled") {
      setActiveResend(true);
      setCounter({ minutes: 0, seconds: 59 });
    } else {
    }
  };


  const cancatenatedString = verificationCode?.join("");
  function closeModal() {
    toast.dismiss();
  }


  const handleOtp = () => {
    console.log("trest resendpart");
    if (cancatenatedString !== resetUser?.usrSalt) {
      showToastError("OTP not matched, please try again", closeModal);
    } else {
      showToastSuccess("OTP matched successfully", closeModal);
      setVerificationCode(["", "", "", ""]);
      navigate("/new-password");
    }
  };

  const handleVerifyOtp = async () => {
    const res = await dispatch(
      login({
        usrUsername: resetUser?.usrUsername,
        usrEncryptedPassword: cancatenatedString,
        usrRoleId: 7,
        usrNationalId: resetUser?.usrNationalId
      })
    );
    if (res?.payload?.usrId) {
      showToastSuccess("OTP matched successfully", closeModal);
      setVerificationCode(["", "", "", ""]);
      navigate("/new-password");
    } else {
      showToastError("OTP not matched, please try again", closeModal);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter.minutes === 0 && counter.seconds === 0) {
        clearInterval(interval);
      } else {
        setCounter((prevCounter) => {
          if (prevCounter.seconds === 0) {
            return { minutes: prevCounter.minutes - 1, seconds: 59 };
          } else {
            return { ...prevCounter, seconds: prevCounter.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  const onFinish = async (data) => {
    navigate("/new-password");
    // const res =  await dispatch(verifyEmail({
    //   usrEncryptedPassword:cancatenatedString,
    //   usrUsername:
    // }))
  };

  return (
    <div className="slanted-background">
      <div
        className="flex justify-center items-center z-10 lg:z-10"
        style={{ zIndex: "10" }}
      >
        <div
          className="mt-[50px] mb-[50px] w-[100%] lg:w-[40%] h-auto lg:h-[840px] bg-white shadow bg-opacity-95"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <Logo />

          <div className="text-center">
            <span className="text-black1 text-4xl font-bold font-dmSans">
              Let’s{" "}
            </span>
            <span className="text-darkBlue text-4xl font-bold font-dmSans">
              verify
            </span>
            <span className="text-black1 text-4xl font-bold font-dmSans">
              {" "}
              that it’s you.
            </span>
          </div>

          <div className="mt[-35px]  text-center text-black2 text-[22px] font-normal font-dmSans leading-[26.4px]">
            Enter the four digit code we have sent <br />
            to both your email and phone number.
          </div>
          <Form
            onFinish={activeResend ? handleOtp : handleVerifyOtp}
            layout="vertical"
            ref={formRef}
            name="control-ref"
            form={form}
          >
            {/* start point otp */}
            <div className="mt-[40px] mb-[65px]">
              <div className="flex flex-col space-y-16">
                <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                  {inputRefs?.map((ref, index) => (
                    <div key={index} className="w-16 h-16">
                      <input
                        ref={ref}
                        className="lg:w-[78.14px] w-[100%] lg:h-[96px] h-auto text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        maxLength="1"
                        value={verificationCode[index]}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="text-center text-[22px] font-normal font-dmSans leading-relaxed">
              Didn’t receive code?
              {counter.minutes === 0 && counter.seconds === 0 ? (
                <Button
                  className="text-white bg-darkBlue ml-3"
                  onClick={resendOTP}
                  disabled={counter.minutes > 0 && counter.seconds > 0}
                  type="primary"
                >
                  Resend
                </Button>
              ) : (
                <span className="text-darkBlue text-[22px] font-normal font-dmSans leading-relaxed">
                  {" "}
                  Resend in {counter.minutes.toString().padStart(2, "0")}:
                  {counter.seconds.toString().padStart(2, "0")}
                </span>
              )}
            </div>
            {/* end point otp */}

            <div className="text-center mt-[122px]">
              <button
                className="text-white text-[21px] font-bold font-dmSans
              w-[264px] h-[54px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
              justify-center items-center gap-2.5 inline-flex
              "
                type="submit"
              >
                Next
              </button>
            </div>

            <div className="mt-[65px] text-center text-darkBlue text-[21px] font-normal font-dmSans leading-relaxed">Back to Login</div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default VerifyOtp;
