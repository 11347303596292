import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, InputNumber, Select, Tabs } from 'antd';
import { updateUser } from '../../../features/auth/authSlice';
import moment from 'moment/moment';
import { countries } from '../../../countries';

let countryOptions = [];

countries.forEach((item) => {
	countryOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

export default function EmployerTab({ handleCurrentTab, form }) {
	function handleNext() {
		form
			.validateFields()
			.then(() => {
				handleCurrentTab('3');
			})
			.catch((errorfields) => {
				//form not valid logic
                // console.log(errorfields);
			});
	}

	function handlePrevious() {
		handleCurrentTab('1');
	}

	return (
		<>
			<div className='mt-5'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focEmployerName'
						label='Foreign Employer Name'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focEmployerCountry'
						label='Foreign Employer Country'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Select
							style={{
								width: '100%',
								height: '44px',
							}}
							// defaultValue='Afghanistan'
							options={countryOptions}
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
					<Form.Item
						name='focEmployerAddress'
						label='Foreign Employer Address'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focEmployerTown'
						label='Foreign Employer Town'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focEmployerPostalCode'
						label='Foreign Employer Postal Code'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focTelPhone'
						label='Office Telephone No.'
						rules={[
							{
								required: true,
								message: 'Please add number',
							},
						]}
					>
						<InputNumber
							maxLength={10}
							minLength={9}
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>

					<Form.Item
						name='focOfficeMobile'
						label='Office Phone No.'
						rules={[
							{
								required: true,
								message: 'Please add number',
							},
						]}
					>
						<InputNumber
							maxLength={10}
							minLength={9}
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focEmployerEmail'
						label='Email'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input
							type='email'
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>

					<Form.Item
						name='focEmployerWebsite'
						label='Website'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input
							type='url'
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>
				</div>

				<div className='mt-10 flex items-center justify-center'>
					<div className='flex items-center justify-center mt-5'>
						<button
							onClick={handlePrevious}
							className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[196px] p-2 mr-3'
							type='button'
						>
							Previous
						</button>
						<button
							onClick={handleNext}
							className='bg-darkBlue rounded-[35px] text-white w-[196px] p-2'
							type='button'
						>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
