import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "../features/auth/authSlice"
import globalReducer from "../features/global/globalSlice"
import profileReducer from "../features/profile/profileSlice"
import skillReducer from "../features/skill/skillSlice"
import eduReducer from "../features/edu/eduSlice"
import expReducer from "../features/exp/expSlice"
import prsReducer from "../features/person/personSllice"
import docReducer from "../features/doc/docSlice"
import jobReducer from "../features/job/jobSlice"
import sessReducer from "../features/sess/sessSlice"
// import Reducer from "../features"

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
	global: globalReducer,
	profile: profileReducer,
	skill: skillReducer,
	edu: eduReducer,
	exp: expReducer,
	prs: prsReducer,
	doc: docReducer,
	job: jobReducer,
	sess: sessReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export const persistor = persistStore(store);
