import React, { useEffect, useRef, useState } from "react";
import "./signin.css";
import {
  Card,
  Form,
  Input,
  Select,
  InputNumber,
  Radio,
  Spin,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import neaLogo from "../../assets/NEA-logo.png";
import flag from "../../assets/Flag.svg";
import { resendSignupOtp, updateUserPassword } from "../../features/auth/authSlice";
import circleSvg from "../../assets/CheckCircle.svg";
import PasswordChecklist from "react-password-checklist";
import Logo from "./Logo";

function NewPassword() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authLoading, resetUser } = useSelector((state) => state.auth);

  function closeModal() {
    toast.dismiss();
  }
  const onFinish = async (data) => {
    const res = await dispatch(
      updateUserPassword({
        usrId: resetUser?.usrId,
        usrEncryptedPassword: password,
      })
    ); 
    if (res?.payload?.messages?.message === "Password updated successfully") {
      setPassword("");
      setConfirmPassword("");
      showToastSuccess(
        "Password created successfully. You may log in with your new password.",
        closeModal
      );
      navigate("/login");
    } else {
      showToastError(
        "Errror occured while updating password, please try again",
        closeModal
      );
    }
  };

  return (
    <div className="slanted-background">
      <div
        className="flex justify-center items-center z-10 lg:z-10"
        style={{ zIndex: "10" }}
      >
        <div
          className="mt-[50px] mb-[50px] w-[100%] lg:w-[40%] h-auto lg:h-[840px] bg-white shadow bg-opacity-95"
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <Logo/>

          <div className="text-center">
            <span className="text-black1 text-4xl font-bold font-dmSans">
              Let’s{" "}
            </span>
            <span className="text-darkBlue text-4xl font-bold font-dmSans">
              verify
            </span>
            <span className="text-black1 text-4xl font-bold font-dmSans">
              {" "}
              that it’s you.
            </span>
          </div>

          <div className="mt[-35px]  text-center text-black2 text-[22px] font-normal font-dmSans leading-[26.4px]">
            Enter the four digit code we have sent <br />
            to both your email and phone number.
          </div>
          <Form
              layout="vertical"
              ref={formRef}
              name="control-ref"
              onFinish={onFinish}
              style={{
                maxWidth: "100%",
                padding: "30px",
              }}
              form={form}
            >
              <div className="lg:mx-20 grid grid-cols-1">
                <form>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please add password",
                      },
                    ]}
                  >
                    <Input.Password
                      className="rounded-[8px] h-[44px] w-full border border-black"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[
                      {
                        required: true,
                        message: "Please add confirm password",
                      },
                    ]}
                  >
                    <Input.Password
                      className="rounded-[8px] h-[44px] w-full border border-black"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Item>

                  <PasswordChecklist
                    className="text-darkBlue mt[-23px]"
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={8}
                    value={password}
                    valueAgain={confirmPassword}
                    messages={{
                      minLength: "A minimum of 8 characters",
                      specialChar: "At least one symbol (~!@$&)",
                      number: "At least one number (0-9)",
                      capital: "At least one upper case letter (A-Z)",
                      match: "Confirm password to match with the password",
                    }}
                    iconComponents={{
                      ValidIcon: (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-darkBlue"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      ),
                      InvalidIcon: <img src={circleSvg} />,
                    }}
                    validColor="#147CBC"
                    iconSize={2}
                  />
                </form>
              </div>

              <div className="cursor-pointer text-center mt-[103px] ">
                <button
                  type="submit"
                  disabled={authLoading ? true : false}
                  className="text-white text-[21px] font-bold font-dmSans w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex"
                >
                  {authLoading ? <Spin /> : "Submit"}
                </button>
              </div>
            </Form>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
