import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Input } from 'antd';
import { fetchAppliedJobs } from '../../../features/job/jobSlice';
import jobImg from '../../../assets/jobimg.png';

export default function UnsuccessfullCard({ props }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { unsuccessful, reFetch } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const [showSearch, setshowSearch] = useState(true);
	
	const { appliedJobs } = useSelector((state) => state.job);
  const jobList = appliedJobs.filter((job) => job.status === "Unsuccessful");

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
		// japProgressStatus: 'UNSUCCESSFUL',
	};

	async function handleFetchJobs() {
		await dispatch(fetchAppliedJobs(usrObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		
	}, [unsuccessful]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	useEffect(() => {
		handleFetchJobs();
	}, [reFetch]);

	return (
		<>
			<div className='bg-white p-6 w-full h-fit pb-16 '>
				{jobList?.length ? (
					<div className='mt-3 mb-10'>
						<h3 className='app-status-info'>Unsuccessful applications</h3>
						<p className='app-status-text mt-2 mb-5'>
							Not to worry. There are many other opportunities awaiting
						</p>
					</div>
				) : null}

				{jobList?.map((item) => {
					return (
						<>
							<div
								style={{
									border: '1px solid rgba(20, 20, 20, 0.05)',
								}}
								className='flex rounded-[8px] p-[24px] space-x-4 mb-4'
							>
								<div className='flex-shrink-0'>
									<img
										className='w-[70px] h-[75px] rounded-[4px]'
										src={jobImg}
										alt='N'
									/>
								</div>
								<div className='flex-1 min-w-0'>
									<p className='text-black14 font-bold text-[18px]'>
										{item?.jobPosition}
									</p>

									<div className='flex items-center'>
										<p className='text-black14 font-[500] text-[15px] my-2'>
											{item?.jobName}
										</p>
										<span className='p-1 bg-lightPurple text-darkPurple w-fit rounded-[5px] ml-4 text-[13px] font-medium'>
											{item?.jobExperienceLevel}
										</span>
									</div>

									<div className='flex justify-between items-center'>
										<div className='flex items-center flex-wrap text-blackrgb mt-3'>
											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={21}
													color='rgba(20, 20, 20, 0.70)'
													icon='pin_drop'
												/>
												<span className='font-normal text-[14px] ml-1'>
													{item?.jobCity}, {item?.jobCountry}
												</span>
											</div>

											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={21}
													color='rgba(20, 20, 20, 0.70)'
													icon='schedule'
												/>
												<span className='font-normal text-[14px] ml-1'>
													Full Time, {item?.jobWorkStyle}
												</span>
											</div>

											<div className='flex items-center mr-7'>
												<span className='font-normal text-[15px] ml-1'>
													{item?.jobGrossPay
														? 'KES ' + item?.jobGrossPay
														: 'Confidential'}
												</span>
											</div>

											<div className='flex items-center mr-7'>
												<MaterialIcon
													size={20}
													color='rgba(20, 20, 20, 0.70)'
													icon='calendar_today'
												/>
												<span className='font-normal text-[14px] ml-1'>
													Closing date:{' '}
													{moment(item?.jobCloseDate).format('Do MMMM YYYY')}
												</span>
											</div>
										</div>

										<div className='flex items-center'></div>
									</div>
								</div>
								<div className='inline-flex items-center'>
									<div className='flex flex-col'>
										<span className='p-2 bg-[#FBC099] text-black333 font-bold w-fit rounded-[5px] text-[15px]'>
											Not successful
										</span>
									</div>
								</div>
							</div>
						</>
					);
				})}
			</div>
		</>
	);
}
