import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Card, Form, Input, Select, InputNumber, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/Back.svg";
import Header from "../components/signup/Header";
import {
  createUserDetails,
  fetchNeaOffices,
} from "../../features/auth/authSlice";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";

function Step1() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authLoading, neaOffices, user } = useSelector((state) => state.auth);
  const [data, setdata] = useState({});
  const [checkValue, setcheckValue] = useState(false);
  const [neaLabel, setNeaLabel] = useState();
  const [openNeaOffices, setopenNeaOffices] = useState(false);
  const [docHolder, setDocHolder] = useState();

  const handleChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      objectNumber: "254" + String(e),
    }));
  };

  const onCheck = (e) => {
    setcheckValue(e.target.checked);
  };
  function closeModal() {
    toast.dismiss();
  }

  const options = [
    {
      label: "Passport",
      value: "Passport",
    },
    {
      label: "National ID",
      value: "National ID",
    },
    {
      label: "Alien Number",
      value: "Alien Number",
    },
    {
      label: "Refugeee Number",
      value: "Refugeee Number",
    },
    {
      label: "Huduma Number",
      value: "Huduma Number",
    },
  ];

  const onFinish = async (data) => {
    // data.usrNearNeaOffice = Number(data?.usrNearNeaOffice);
    const res = await dispatch(
      createUserDetails({
        usrFullNames: data?.usrFullNames,
        usrId: null,
        usrDocType: data?.usrDocType,
        usrNationalId: Number(data?.usrNationalIdProvided),
        usrEmail: data?.usrEmail,
        usrNearNeaOffice: "HQ",
        usrNeaId: 1,
        usrEncryptedPassword: 1234,
        usrUsername: data?.usrNationalIdProvided,
        usrFirstName: data?.usrFirstName,
        usrLastName: data?.usrLastName,
        usrMobileNumber: data?.usrMobileNumber,
        usrRoleId: 7

      })
    );
    if (res?.payload?.usrId) {
      await form.resetFields();
      await navigate("/step2");
      await showToastSuccess("User details saved successfully", closeModal);
    } else {
      showToastError(
        "Errror occured while saving, please try again",
        closeModal
      );
    }
  };
  const handleNext = () => {
    navigate("/step2");
  };
  const handleBack = () => {
    navigate("/job-interests");
  };

  async function fetchNea() {
    const res = await dispatch(fetchNeaOffices());
  }

  useEffect(() => {
    fetchNea();
  }, []);

  return (
    <>
      <div className="sticky bg-white top-0 w-full z-[1000]">
        <Header />
        <div className="lg:w-[1143px] w-auto h-[15px] bg-darkBlue">
        </div>

        <div>
          <div
            className="cursor-pointer mt-[30px] lg:pl-[190px] pl-12 text-neutral-800 text-[22px] font-bold font-dmSans leading-relaxed 
        flex items-center"
          >
            <div
              onClick={handleBack}
              style={{
                height: "40px",
                width: "40px",
                background: "#fff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

            </div>

          </div>
        </div>

        <div className="text-center text-black1 text-4xl font-bold font-dmSans">
          Please enter your details below
        </div>

        <div className="text-center   text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed">
          Make sure you give accurate details so that future employers can
          reach you.
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
          }}
          className="lg:w-[800px] w-[400px] lg:p-0 p-2"
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-5 w-full">

            <Form.Item
              name="usrFirstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please add full name",
                },
              ]}
            >
              <Input className="rounded-[8px] h-[44px] w-full border border-black" />
            </Form.Item>

            <Form.Item
              name="usrLastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please add full name",
                },
              ]}
            >
              <Input className="rounded-[8px] h-[44px] w-full border border-black" />
            </Form.Item>
          </div>

          <Form.Item
            name="usrMobileNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please add office phone number",
              },
            ]}
          >
            <InputNumber
              maxLength={10}
              minLength={9}
              onChange={handleNumberChange}
              className="rounded-[8px] h-[44px] w-full border border-black"
            />
          </Form.Item>
          {/* <Form.Item
            name="usrDocType"
            label="Choose document type"
            rules={[
              {
                required: true,
                message: "Please select document type",
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
                height: "44px",
              }}
              options={options}
              onChange={(value) => {
                handleSelectChange(value);
                setDocHolder(value);
              }}
              onDropdownVisibleChange={() => {
                //trigger something
              }}
            />
          </Form.Item> */}
          {(
            <Form.Item
              name="usrNationalIdProvided"
              label={
                "National ID Number"}
              rules={[
                {
                  required: true,
                  message: "Please add document ID number",
                },
              ]}
            >
              <Input className="rounded-[8px] h-[44px] w-full border border-black" />
            </Form.Item>
          )}

          <Form.Item
            name="usrEmail"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please add email",
              },
            ]}
          >
            <Input
              type="email"
              className="rounded-[8px] h-[44px] w-full border border-black"
            />
          </Form.Item>

          {/* <Form.Item
              name="usrNearNeaOffice"
              label="Which NEA Office is closest to you?"
              rules={[
                {
                  required: true,
                  message: "Please select NEA office close to you",
                },
              ]}
            > */}
          {/* <Select
            // showSearch
            mode="multiple"
            style={{
              width: "100%",
              height: "44px",
            }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label?.toLocaleLowerCase() ?? "").includes(
                input?.toLocaleLowerCase()
              )
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={(value, label) => {
              if (value?.length > 1) {
                value.shift();
                label.shift();
                let obj = value?.length && Number(value[0]);
                handleSelectChange(obj);
                setNeaLabel(label.length && label[0].label);
              } else {
                let obj = value?.length && Number(value[0]);
                handleSelectChange(obj);
                setNeaLabel(label.length && label[0].label);
              }
              setopenNeaOffices(false);
              console.log(
                "label-------",
                typeof value?.length && Number(value[0])
              );
            }}
            options={
              neaOffices?.length &&
              neaOffices?.map((office) => ({
                value: office.instId,
                label: `${office.instName} ${office.instCounty}`,
              }))
            }
            open={openNeaOffices}
            onDropdownVisibleChange={() => {
              fetchNea();
              setopenNeaOffices(true);
            }}
          />
        </Form.Item> */}

          <div className="text-center mt-[20px]">
            <button
              disabled={authLoading ? true : false}
              className="text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex"
            >
              {authLoading ? <Spin className="text-white" /> : "Next"}
            </button>
          </div>

          <div className="text-center mt-[20px]">
            <div className="cursor-pointer text-darkBlue text-xl font-normal font-dmSans leading-normal
				w-[299px] h-[53px] pl-[18px] pr-[17.20px] pt-2 pb-[7.50px] bg-white rounded-[35px] 
				shadow border-2 border-darkBlue justify-center items-center inline-flex"
              onClick={() => navigate("/login")}>
              Back to login
            </div>
          </div>
        </Form>
      </div >

    </>
  );
}

export default Step1;
