import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	expLoading: false,
    experiences: []
};

export const fetchExperiences = createAsyncThunk(
	'expSlice/fetchExperiences',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchExperiences.action?expUsrId=${usrObj?.usrId}`,
		);
		return res.data;
	},
);

export const saveExperience = createAsyncThunk(
	'expSlice/saveExperience',
	async (eduObj) => {
		const res = await axios.post(`${url}/nea/saveExperience.action`, eduObj);
		return res.data;
	},
);

export const expSlice = createSlice({
	name: 'exp',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(saveExperience.pending, (state) => {
				state.expLoading = true;
			})
			.addCase(saveExperience.fulfilled, (state) => {
				state.expLoading = false;
			})
			.addCase(saveExperience.rejected, (state) => {
				state.expLoading = false;
			})

            .addCase(fetchExperiences.pending, (state) => {
				state.expLoading = true;
			})
			.addCase(fetchExperiences.fulfilled, (state, action) => {
				state.expLoading = false;
                if (action?.payload?.success) {
					state.experiences = action?.payload?.data?.result;
				} else {
					state.experiences = [];
				}
			})
			.addCase(fetchExperiences.rejected, (state) => {
				state.expLoading = false;
			});
	},
});

export default expSlice.reducer;
export const {} = expSlice.actions;
