import React, { useEffect, useRef, useState } from "react";
import "./signin.css";
import { Card, Form, Input, Select, InputNumber, Radio, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToastError, showToastSuccess } from "../../utils";
import toast from "react-hot-toast";
import neaLogo from "../../assets/NEA-logo.png";
import flag from "../../assets/Flag.svg";
import { resetPassword } from "../../features/auth/authSlice";
import Logo from "./Logo";

function ResetPassword() {
    const [form] = Form.useForm();
    const formRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authLoading } = useSelector((state) => state.auth);

    const [data, setdata] = useState({});

    function closeModal() {
        toast.dismiss();
    }

    const onFinish = async (data) => {
        const res = await dispatch(
            resetPassword({
                nationalId: data?.nationalId,
            })
        );
        if (
            res?.payload
        ) {
            showToastSuccess("Password sent to you email and phone number", closeModal)
            navigate("/login");
        } else {
            showToastError("Errror occured, please try again", closeModal)
        }
    };



    return (
        <div className="slanted-background">
            <div
                className="flex justify-center items-center z-10 lg:z-10"
                style={{ zIndex: "10" }}
            >
                <div
                    className=" mt-[50px] mb-[50px] w-[100%] lg:w-[40%] h-auto lg:h-[840px] bg-white shadow bg-opacity-95"
                    style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                    <Logo />

                    <div className="text-center text-black1 text-4xl font-bold font-dmSans leading-[50.40px]">
                        Reset Password
                    </div>

                    <div className="text-center mt-[33px]">
                        <span className="text-black2 text-[22px] font-medium font-dmSans leading-relaxed">
                            Enter the{" "}
                        </span>
                        <span className="text-darkBlue text-[22px] font-medium font-dmSans leading-relaxed">
                            National Id
                        </span>
                        <span className="text-neutral-800 text-[22px] font-medium font-dmSans leading-relaxed">
                            {" "}
                            you used to{" "}
                        </span>
                        <span className="text-black2 text-[22px] font-medium font-dmSans leading-relaxed">
                            register
                        </span>
                    </div>

                    <Form
                        layout="vertical"
                        ref={formRef}
                        name="control-ref"
                        onFinish={onFinish}
                        className="px-[110px] mt-[92px]"
                        style={{
                            maxWidth: "100%",
                        }}
                        form={form}
                    >
                        <div className="grid grid-cols-1 mb-[-23px]">
                            <Form.Item
                                name="nationalId"
                                label="National Id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please add National Id",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="rounded-[8px] h-[44px] w-full border border-black"
                                />
                            </Form.Item>
                        </div>

                        <div className="text-center mt-[95px]">
                            <button
                                className="w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
               justify-center items-center gap-2.5 inline-flex text-white text-[21px] font-bold font-dmSans"
                                type="submit"
                                disabled={authLoading ? true : false}
                            >
                                {authLoading ? <Spin /> : "Next"}
                            </button>
                        </div>

                        <div className="text-center mt-[92px]">
                            <span className="text-black1 text-[21px] font-normal font-dmSans leading-relaxed">
                                Don’t have an account?
                            </span>
                            <span
                                className="cursor-pointer text-darkBlue text-[21px] font-normal font-dmSans leading-relaxed"
                                onClick={() => navigate("/seeker")}
                            >
                                {" "}
                                Sign up
                            </span>
                        </div>

                        <div
                            className="cursor-pointer text-center mt-[30px] text-darkBlue text-[21px] font-normal font-dmSans leading-relaxed"
                            onClick={() => navigate("/login")}
                        >
                            Back to Login
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
