import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import {
	Checkbox,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Select,
	Spin,
	Upload,
	message,
} from 'antd';
import { updateUser } from '../../../features/auth/authSlice';
import moment from 'moment/moment';
import { countries } from '../../../countries';

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

let staticArray = [
	'Architectural Association of Kenya',
	'Association of Chartered Certified Accountants',
	'Association of Private Universities in Kenya',
	'Association of Professional Societies of East Africa',
	'Association of Public Universities in Kenya',
	'Board of Registration of Architects and Quantity Surveyors, Kenya',
	'Chartered Institute of Arbitrators',
	'Chartered Institute of Management',
	'Clinical Officers Council',
	'Council for Legal Education',
	'Engineers Board of Kenya',
	'Institute of Certified Public Accountants',
	'Institute of Certified Public Secretaries',
	'Institute of Human Resource Management',
	'Institution of Surveyors of Kenya',
	'Kenya Association of Technical Training Institutes',
	'Kenya Medical laboratory Technicians and Technologists',
	'Kenya National Association of Agricultural Professionals',
	'Kenya National Association of Private Colleges',
	'Kenya Nutritionists and Dieticians Institute',
	'Kenya Engineering Technology Registration Board',
	'Kenya Veterinary Board',
	'Media Council of Kenya',
	'Medical Practitioners and Dentists Board',
	'Nursing Council of Kenya',
	'Pharmacy and Poisons Board',
	'The Marketing Society of Kenya',
];

let proBodyOptions = [];

staticArray.forEach((item) => {
	proBodyOptions.push({
		label: item,
		value: item,
	});
});

export default function JobDetailsTab({
	handleCurrentTab,
	handleUrlChange,
	handleUrl2Change,
	handleUrl3Change,
	handleUrl4Change,
}) {
    const { jobLoading } = useSelector((state) => state.job);

	const [registered, setregistered] = useState(true);

	const props = {
		name: 'file',
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					handleUrlChange(info?.file?.response?.jsonData);
				} else {
				}
				console.log(info.file.response);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const props2 = {
		name: 'file',
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					handleUrl2Change(info?.file?.response?.jsonData);
				} else {
				}
				console.log(info.file.response);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const props3 = {
		name: 'file',
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					handleUrl3Change(info?.file?.response?.jsonData);
				} else {
				}
				console.log(info.file.response);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const props4 = {
		name: 'file',
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					handleUrl4Change(info?.file?.response?.jsonData);
				} else {
				}
				console.log(info.file.response);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	function handlePrevious() {
		handleCurrentTab('2');
	}

	return (
		<>
			<div className='mt-5'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focOfferLetterNo'
						label='Employment Offer Letter Ref No.'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focOfferLetter'
						label='Employment Offer Letter'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Dragger className='upload-input' {...props}></Dragger>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focJobTitle'
						label='Job Title'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focServiceTerms'
						label='Terms of Service'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Dragger className='upload-input' {...props2}></Dragger>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focContractPeriod'
						label='Contract Period (yrs)'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<InputNumber className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focDepartureDate'
						label='Departure Date'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<DatePicker placeholder='' format={'Do MMMM YYYY'} />
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focContractRefNo'
						label='Contract Agreement Ref No.'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input className='rounded-[8px] h-[44px] w-full border border-black' />
					</Form.Item>

					<Form.Item
						name='focContractFile'
						label='Attach Contract Agreement'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Dragger className='upload-input' {...props3}></Dragger>
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
					<Form.Item
						name='focIsEmployerRegistered'
						label='Registered with professional body'
						rules={[
							{
								required: false,
							},
						]}
					>
						<div className='flex items-center mt-3'>
							<>
								<Checkbox
                                    value={registered}
									checked={registered}
									onChange={() => setregistered(!registered)}
								>
									Yes
								</Checkbox>
								<Checkbox
									className='!ml-7'
									checked={!registered}
                                    value={!registered}
									onChange={() => setregistered(!registered)}
								>
									No
								</Checkbox>
							</>
						</div>
					</Form.Item>

					{registered ? (
						<>
							<Form.Item
								name='focProfessionalBodyReg'
								label='Professional Body Reg No.'
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<Input
									className='rounded-[8px] h-[44px] w-full border border-black'
								/>
							</Form.Item>

							<Form.Item
								name='focProfessionalBodyName'
								label='Choose Professional Body'
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}
							>
								<Select
									style={{
										width: '100%',
										height: '44px',
									}}
									// defaultValue='Afghanistan'
									options={proBodyOptions}
								/>
							</Form.Item>
						</>
					) : null}
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
					<Form.Item
						name='focValidityStartDate'
						label='Validity Period Start Date'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<DatePicker placeholder='' format={'Do MMMM YYYY'} />
					</Form.Item>

					<Form.Item
						name='focValidityEndDate'
						label='Validity Period End Date'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<DatePicker placeholder='' format={'Do MMMM YYYY'} />
					</Form.Item>
				</div>

				<div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
					<Form.Item
						name='focPreDepartureCertNo'
						label='Pre-Departure Training Certificate No.'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Input
							className='rounded-[8px] h-[44px] w-full border border-black'
						/>
					</Form.Item>

					<Form.Item
						name='focPreDepartureGraduationDate'
						label='Pre-Departure Training Graduation Date'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<DatePicker placeholder='' format={'Do MMMM YYYY'} />
					</Form.Item>

					<Form.Item
						name='focPreDepartureCertFile'
						label='Pre-Departure Training Certificate'
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
					>
						<Dragger className='upload-input' {...props4}></Dragger>
					</Form.Item>
				</div>

				<div className='mt-10 flex items-center justify-center'>
					<div className='flex items-center justify-center mt-5'>
						<button
							onClick={handlePrevious}
							className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[196px] p-2 mr-3'
							type='button'
						>
							Previous
						</button>
						<button
							className='bg-darkBlue rounded-[35px] text-white w-[196px] p-2'
							type='submit'
						>
                            {
                                jobLoading ? <Spin /> : "Save"
                            }
							
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
