import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

export default function NeaModals() {
	const [open, setopen] = useState(false);
	return (
		<>
			<div className='mb-10'>
				<h1 className='text-darkBlue font-bold text-5xl underline mb-3'>
					MODALS
				</h1>
				<button
					onClick={() => setopen(true)}
					className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2'
					type='button'
				>
					Open Modal
				</button>
				<Modal
					title={'Modal Title'}
					centered
					footer={null}
					open={open}
					onOk={() => console.log('Yes')}
					okText={'Next'}
					onCancel={() => setopen(false)}
					width={'639px'}
					cancelButtonProps={{
						style: {
							background: '#D3D3D9',
							color: '#212143',
							fontWeight: 500,
							fontSize: '14px',
						},
					}}
					okButtonProps={{
						style: {
							background: '#10101E',
							color: '#fff',
							fontWeight: 500,
							fontSize: '14px',
						},
					}}
				>
					<>
						<h1>Hello Wordl</h1>

						<div className='absolute bottom-3 right-5'>
							<div className='flex items-center'>
								<button
									className='bg-white rounded-[35px] text-darkBlue hover:border-2 hover:border-darkBlue w-[170px] p-2 my-3'
									type='submit'
								>
									Cancel
								</button>
								<button
									className='bg-darkBlue rounded-[35px] text-white w-[170px] p-2 ml-5'
									type='submit'
								>
									Next
								</button>
							</div>
						</div>
					</>
				</Modal>
			</div>
		</>
	);
}
