import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import moment from 'moment/moment';
import { counties } from '../../../counties';
import {
	handleNextStep,
	handlePrevStep,
} from '../../../features/profile/profileSlice';
import expImg from '../../../assets/eduImg.png';
import qs from 'qs';
import {
	deleteSkill,
	fetchGlobalSkills,
	fetchSkillCategory,
	fetchSkills,
	saveSkill,
} from '../../../features/skill/skillSlice';

const url = process.env.REACT_APP_API_BASE_URL;

let countyOptions = [];

counties.forEach((item) => {
	countyOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

let timeout;
let currentValue;
const fetchFunc = (value, callback) => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
	currentValue = value;

	const fake = () => {
		fetch(`${url}/nea/fetchGlobalSkills.action?gsklName=${value}`)
			.then((response) => response.json())
			.then((d) => {
				console.log(d);
				if (currentValue === value) {
					const data = d?.data?.result?.map((item) => ({
						value: item?.gsklId,
						text: item?.gsklName,
					}));
					callback(data);
				}
			});
	};
	if (value) {
		timeout = setTimeout(fake, 300);
	} else {
		callback([]);
	}
};

export default function SkillStep() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { user } = useSelector((state) => state.auth);
	const { mySkills, skillLoading, globalSkills } = useSelector(
		(state) => state.skill,
	);

	const [data, setdata] = useState({});
	const [showSearch, setshowSearch] = useState(true);

	const [skillData, setskillData] = useState([]);
	const [value, setValue] = useState();
	const [skillsList, setskillsList] = useState(mySkills);
	const [skillCategoryList, setskillCategoryList] = useState([]);
	const [globalSkillsList, setglobalSkillsList] = useState(globalSkills);

	const usrObj = {
		usrId: user?.usrId,
	};

	const handleSearch = (newValue) => {
		fetchFunc(newValue, setskillData);
	};

	const handleSkillChange = async (newValue) => {
		await setValue(newValue);
		let newArr = await [...newValue];
		let val = await newArr?.pop();
		const skillObj = {
			sklUsrId: user?.usrId,
			sklGsklId: val,
		};
		await console.log(val);

		await dispatch(saveSkill(skillObj));
		const res = await dispatch(fetchSkills(usrObj));
		if (res?.payload?.success) {
		}
	};

	const handleChange = (e) => {
		setdata((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	async function handleSelectChange(value) {
		const resp = await dispatch(fetchGlobalSkills(value));

		let skills = [];

		if (resp?.payload?.success) {
			await resp?.payload?.data?.result?.forEach((item) => {
				skills.push({
					label: item?.gsklName,
					value: item?.gsklId,
				});
			});

			await setglobalSkillsList(skills);
		}
	}

	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	function handleGoNext() {
		dispatch(handleNextStep());
	}

	function handleGoBack() {
		dispatch(handlePrevStep());
	}

	const onFinish = async (data) => {
		console.log(data);
		dispatch(handleNextStep());
	};

	async function handleFetchSkills() {
		const resp = await dispatch(fetchSkills(usrObj));

		if (resp?.payload?.success) {
			await setskillsList(resp?.payload?.data?.result);
		}
	}

	async function handleDeleteSkill(id) {
		await dispatch(deleteSkill(id));
		await handleFetchSkills();
	}

	async function handleFetchSKillCategory() {
		const res = await dispatch(fetchSkillCategory());
		if (res?.payload?.success) {
			let skills = [];

			await res?.payload?.data?.result?.forEach((item) => {
				skills.push({
					label: item?.sklcatName,
					value: item?.sklcatId,
				});
			});

			await setskillCategoryList(skills);
		}
	}

	useEffect(() => {}, [skillsList, globalSkillsList]);

	useEffect(() => {
		setskillsList(mySkills);
	}, [mySkills]);

	useEffect(() => {
		setglobalSkillsList(globalSkills);
	}, [globalSkills]);

	useEffect(() => {
		handleFetchSkills();
	}, []);

	return (
		<>
			<div className='mt-10'>
				<h3 className='edit-step-head'>Skills</h3>
				<p className='edit-step-desc mt-2'>
					Tell us a little more about your skills
				</p>

				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
						marginTop: 20,
					}}
					form={form}
				>
					<div
						style={{
							borderRadius: '40px',
							border: '1px solid #BCBCBC',
							background: '#FFF',
						}}
						className='px-3 py-1 bg-transparent flex items-center justify-between max-w-full min-w-[30%] mb-10'
					>
						<div className='flex items-center w-full'>
							{showSearch ? (
								<MaterialIcon color='#333' size={25} icon='search' />
							) : null}
							{/* <Input
								className='!placeholder-black333 h-[44px] w-full focus:border-none focus:outline-none focus:ring-0 active:ring-0'
								placeholder={'Search Skills'}
								bordered={false}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => setshowSearch(true)}
							/> */}

							<Select
								id='search-select'
								mode='multiple'
								style={{
									width: '100%',
									height: '44px',
									maxHeight: '100% !important',
									border: 'none !important',
									outline: 'none',
								}}
								value={value}
								className='!placeholder-black333 max-h-auto h-[44px] w-full focus:border-none focus:outline-none focus:ring-0 active:ring-0 search-select'
								placeholder={'Search Skills'}
								defaultActiveFirstOption={false}
								suffixIcon={null}
								filterOption={false}
								onSearch={handleSearch}
								onChange={handleSkillChange}
								notFoundContent={null}
								options={(skillData || []).map((d) => ({
									value: d.value,
									label: d.text,
								}))}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => {
									setshowSearch(true);
									setskillData([]);
									setValue();
								}}
							/>
						</div>
					</div>
					{/* <SearchInput
							placeholder='input search text'
							style={{
								width: 200,
							}}
						/> */}
					<div className='grid grid-cols-2 gap-x-6'>
						<Form.Item name='sklJobCategory' label='Job Category'>
							<Select
								style={{
									width: '100%',
									height: '44px',
								}}
								options={skillCategoryList}
								loading={skillLoading}
								onChange={(value) => {
									handleSelectChange(value);
								}}
								onDropdownVisibleChange={() => {
									handleFetchSKillCategory();
								}}
							/>
						</Form.Item>

						<Form.Item name='skills' label='Skills'>
							<Select
								value={value}
								mode='multiple'
								style={{
									width: '100%',
									height: '44px',
								}}
								options={globalSkillsList}
								onChange={handleSkillChange}
								onDropdownVisibleChange={() => {
									//trigger something
								}}
								onBlur={() => {
									setshowSearch(true);
									setskillData([]);
									setValue();
								}}
							/>
						</Form.Item>
					</div>

					<div>
						<p className='mb-5 mt-7 font-medium text-[15px]'>Chosen skills:</p>
						<div className='flex items-center flex-wrap mt-2'>
							{skillsList?.map((item) => {
								return (
									<>
										<div className='bg-lightPurple rounded-[63px] text-center px-4 py-1 mr-2 flex items-center justify-between mb-3'>
											<span className='prof-card-badge'>{item?.skillName}</span>
											<button
												onClick={() => handleDeleteSkill(item?.sklId)}
												type='button'
												className='ml-3'
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='19'
													height='19'
													viewBox='0 0 19 19'
													fill='none'
												>
													<path
														d='M14.25 4.75L4.75 14.25'
														stroke='#262168'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
													<path
														d='M4.75 4.75L14.25 14.25'
														stroke='#262168'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
													/>
												</svg>
											</button>
										</div>
									</>
								);
							})}
						</div>
					</div>

					<div className='flex items-center justify-end mt-10 mb-14 w-full'>
						<button
							onClick={handleGoBack}
							className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
							type='button'
						>
							Back
						</button>
						<button
							className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
							type='submit'
						>
							Next
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}
