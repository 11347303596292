import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from 'antd';

export default function SearchFilter({
	text = 'What would you like to learn?',
	searchText = 'Search Skills',
}) {
	const [showSearch, setshowSearch] = useState(true);
	return (
		<>
		<div className='mb-10'>
				<h1 className='text-darkBlue font-bold text-5xl underline mb-3'>
					SEARCH & FILTER
				</h1>
			<div className='px-14 py-10 bg-white flex items-center justify-between w-full'>
				<div className='flex items-center w-[70%]'>
					{showSearch ? (
						<MaterialIcon color='#333' size={20} icon='search' />
					) : null}
					<Input
						className='!placeholder-black333 h-[44px]'
						placeholder={text}
						bordered={false}
						onFocus={() => setshowSearch(!showSearch)}
						onBlur={() => setshowSearch(true)}
					/>
				</div>

				<button
					className='bg-darkBlue w-[226.503px] p-2 text-white'
					type='button'
				>
					{searchText}
				</button>
			</div>

			<div className='p-4 bg-[#F0F0F0] w-full mt-10'>
				<div className='px-10 py-5 bg-white flex flex-col'>
					<div className='flex items-center w-[100%] mb-5'>
						<MaterialIcon color='#147CBC' size={20} icon='search' />
						<span className='text-darkBlue font-[500] ml-2'>
							What kind of job are you looking for?
						</span>
					</div>

					<div className='flex items-center justify-center'>
						<div className='flex items-center w-full'>
							<Input
								suffix={
									<div className='flex flex-col'>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_up'
										/>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_down'
										/>
									</div>
								}
								className='!placeholder-[#9B9B9E] h-[44px]'
								placeholder='Jobs by Company'
								bordered={false}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => setshowSearch(true)}
							/>
						</div>

						<div className='flex items-center w-full'>
							<Input
								suffix={
									<div className='flex flex-col'>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_up'
										/>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_down'
										/>
									</div>
								}
								className='!placeholder-[#9B9B9E] h-[44px] mx-5'
								placeholder='Jobs by Type'
								bordered={false}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => setshowSearch(true)}
							/>
						</div>

						<div className='flex items-center w-full'>
							<Input
								suffix={
									<div className='flex flex-col'>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_up'
										/>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_down'
										/>
									</div>
								}
								className='!placeholder-[#9B9B9E] h-[44px]'
								placeholder='Jobs by Sector'
								bordered={false}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => setshowSearch(true)}
							/>
						</div>

						<div className='flex items-center w-full'>
							<Input
								type='text'
								suffix={
									<div className='flex flex-col'>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_up'
										/>
										<MaterialIcon
											color='#C4C9D3'
											size={20}
											icon='arrow_drop_down'
										/>
									</div>
								}
								className='!placeholder-[#9B9B9E] h-[44px] ml-5'
								placeholder='Jobs by Location'
								bordered={false}
								onFocus={() => setshowSearch(!showSearch)}
								onBlur={() => setshowSearch(true)}
							/>
						</div>
					</div>
				</div>
			</div>
			</div>
		</>
	);
}
